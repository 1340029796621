import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";
import { createDynamicPath } from "src/utils/dataConverter";

class MatchManageService {
  /*
   * 매칭 정보 목록 조회
   */
  selectMatchConditionList() {
    return axiosGet("admin-manage/match-condition")
      .then((response) => {
        return response.map((item) => {
          return {
            ...item,
            id: item.matchConditionId
          };
        });
      })
      .catch((error) => {
        return error.response;
      });
  }

  /*
   * 매칭 정보 1건 신규 추가
   * conditionCd 조건 코드
   * conditionText 조건
   * useYn 사용 유무
   * mustYn 필수 유무
   * matchGb 구분
   * matchOrder 비교 순서
   */
  insertMatchConditionItem(payload) {
    return axiosPost("admin-manage/match-condition", payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /*
   * 매칭 정보 1건 수정
   * matchConditionId 매칭 정보 ID
   * conditionCd 조건 코드
   * conditionText 조건
   * useYn 사용 유무
   * mustYn 필수 유무
   * matchGb 구분
   * matchOrder 비교 순서
   */
  updateMatchConditionItem(payload) {
    return axiosPut("admin-manage/match-condition", payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /*
   * 매칭 정보 1건 삭제
   * matchConditionId 매칭 정보 ID
   */
  deleteMatchConditionItem(params) {
    // Creating path
    const basePath = "admin-manage/match-condition";
    const dynamicPath = createDynamicPath(basePath, params);
    return axiosDelete(dynamicPath)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /*
   * 매칭 나이 정보 목록 조회
   */
  selectUserAgeSetList() {
    return axiosGet("admin-manage/user-age-set")
      .then((response) => {
        return response.map((item) => {
          return {
            ...item,
            id: item.userAgeSetId
          };
        });
      })
      .catch((error) => {
        return error.response;
      });
  }

  /*
   * 매칭 나이 정보 1건 신규 추가
   * preferAgeCd 선호 나이 코드
   * ageFirst 우선순위 1
   * ageSecond 우선순위 2
   * ageMin 나이 최소 값
   * ageMax 나이 최대 값
   */
  insertUserAgeSetItem(payload) {
    return axiosPost("admin-manage/user-age-set", payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /*
   * 매칭 나이 정보 1건 수정
   * userAgeSetId 매칭 나이 정보 ID
   * preferAgeCd 선호 나이 코드
   * ageFirst 우선순위 1
   * ageSecond 우선순위 2
   * ageMin 나이 최소 값
   * ageMax 나이 최대 값
   */
  updateUserAgeSetItem(payload) {
    return axiosPut("admin-manage/user-age-set", payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /*
   * 매칭 나이 정보 1건 삭제
   * userAgeSetId 매칭 나이 정보 ID
   */
  deleteUserAgeSetItem(params) {
    // Creating path
    const basePath = "admin-manage/user-age-set";
    const dynamicPath = createDynamicPath(basePath, params);
    return axiosDelete(dynamicPath)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default new MatchManageService();
