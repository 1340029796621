import React from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Button, Paper, Stack, TextField } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const options = [
  { label: "11번가", value: "10" },
  { label: "21gram", value: "20" }
];

const GroupList = () => {
  const navigate = useNavigate();
  return (
    <Paper elevation={0}>
      <Appbar title="회사 선택" back />
      <Stack height="92vh" justifyContent="space-between">
        <Autocomplete
          disablePortal
          id="combo-box"
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="회사를 선택 해주세요"
            />
          )}
          noOptionsText={
            <Button onMouseDown={() => navigate("/app/email/new")}>
              회사 이메일 입력하러 가기
            </Button>
          }
        />
        <Button fullWidth size="large" variant="outlined" href="/app/email/add">
          다음
        </Button>
      </Stack>
    </Paper>
  );
};

export default GroupList;
