import React from "react";
import { Button, Link, Paper, Stack, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const EmailAuthentication = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="인증하기" back />
      <Stack spacing={2}>
        <Typography variant="h6">인증 할 요소를 선택해 주세요.</Typography>
        <Button
          fullWidth
          component={Link}
          href="/app/email/list"
          variant="outlined"
        >
          직장 이메일
        </Button>
        <Button
          fullWidth
          component={Link}
          href="/app/email/list"
          variant="outlined"
        >
          학교 이메일
        </Button>
      </Stack>
    </Paper>
  );
};

export default EmailAuthentication;
