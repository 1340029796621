// ** React Imports
import * as React from "react";

// ** Other Utils
import { DataGrid } from "@mui/x-data-grid";

export default function GridContainer(props) {
  // ** Props
  const { columns, rowData, onRowClick, height = 631 } = props;

  const handleRowClick = (params, event, details) => {
    onRowClick && onRowClick(params);
  };

  return (
    <div style={{ height: height, width: "100%" }}>
      <DataGrid
        rows={rowData}
        columns={columns}
        onRowClick={handleRowClick}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 }
          }
        }}
        pageSizeOptions={[10, 50]}
      />
    </div>
  );
}
