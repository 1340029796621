const API_RESULT = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  ERROR: "ERROR"
};

const API_RESULT_ACTION = {
  NO_ITEM: "선택된 아이템이 없습니다.",
  MORE_THAN_2: "검색 조건을 2개 이상 선택해 주세요.",
  ADD: "추가 되었습니다.",
  EDIT: "수정 되었습니다.",
  DELETE: "삭제 되었습니다.",
  CONFIRM: "승인 되었습니다.",
  FAILURE: "실패 하였습니다."
};

const CAREER_TYPE = {
  COMPANY: "company-info",
  UNIVERSITY: "university-info"
};

const CONTENT_TYPE = {
  NOTICE: "01",
  EVENT: "02",
  NOTI: "03",
  FAQ: "04",
  TOS: "05",
  PRIVACY: "06",
  BUSINESS_INFO: "07"
};

const CONTENT_MODE = {
  VIEW: "View",
  NEW: "New",
  EDIT: "Edit"
};

const MEETING_TYPE = {
  WEEKDAY_COFFEE: "01", // 평일커피
  WEEKEND_COFFEE: "02", // 주말커피
  WEEKDAY_BEER: "03", // 평일맥주
  WEEKEND_BEER: "04" // 주말맥주
};

const USER_STATUS = {
  NORMAL: "01", // 정상 계정
  DORMANT: "02", // 휴면 계정
  GRACE_PERIOD: "03", // 탈퇴 유예 계정
  LEAVE: "04", // 탈퇴 계정
  BLOCK: "05" // 정지 계정
};

const CHAT_STATUS = {
  NULL: null, // 기본 값
  FIRST: "01", // 첫째 날
  SECOND: "02", // 둘째 날
  THIRD: "03", // 셋째 날
  CLOSE: "04" // 종료
};

export {
  API_RESULT,
  API_RESULT_ACTION,
  CAREER_TYPE,
  CONTENT_TYPE,
  CONTENT_MODE,
  MEETING_TYPE,
  USER_STATUS,
  CHAT_STATUS
};
