import { v4 as uuid } from "uuid";

export default [
  {
    id: uuid(),
    userData: {
      avatar: "01",
      meetingTypeCode: "04",
      meetingTypeName: "주말맥주",
      nickName: "대전강아지",
      age: "32",
      location: "서울",
      job: "경영",
      company: "네이버",
      degree: "4년제",
      school: "한양대학교"
    },
    content:
      "만나서 반가워요. 평일에 커피 한잔 어때요? 내일 비가 온대요. 점심은 어디서 드세요?",
    createdAt: 1555016400000
  },
  {
    id: uuid(),
    userData: {
      avatar: "02",
      phone: "010-2222-3333",
      email: "tankova@devias.io",
      meetingTypeCode: "04",
      meetingTypeName: "주말맥주",
      nickName: "영등포사자",
      gender: "남",
      birthYear: "1990",
      age: "33",
      height: 187,
      location: "서울",
      job: "경영",
      company: "네이버",
      degree: "4년제",
      school: "한양대학교"
    },
    content: "대화 수락해주셔서 감사합니다. 퇴근 하셨나요?",
    createdAt: 1555016400000
  },
  {
    id: uuid(),
    userData: {
      socialType: "구글",
      avatar: "03",
      phone: "010-2222-3333",
      email: "tankova@devias.io",
      meetingTypeCode: "04",
      meetingTypeName: "주말맥주",
      nickName: "목동참새",
      gender: "남",
      birthYear: "1987",
      age: "36",
      height: 187,
      location: "서울",
      job: "경영",
      company: "네이버",
      degree: "4년제",
      school: "한양대학교"
    },
    content: "안녕하세요",
    createdAt: 1555016400000
  }
];
