import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Card, Typography } from "@mui/material";

export default function DisplayCard({ title, subtitle, link }) {
  return (
    <Card>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {title}
      </Typography>
      <Typography component="p" variant="h4">
        {subtitle}
      </Typography>
      <Button component={NavLink} color="primary" to={link}>
        바로가기
      </Button>
    </Card>
  );
}
