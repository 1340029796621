// ** React Imports
import React, { useState } from "react";

// ** Redux Imports
import { useQuery, useMutation, useQueryClient } from "react-query";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Redux Imports
import MatchManageController from "src/controller/MatchManageController";

// ** Widget Imports
import { Box, Button, TextField } from "@mui/material";
import { GridContainer } from "src/components";

// Utils
import { API_RESULT, API_RESULT_ACTION } from "src/constant/commonCode";

// 수정 가능한 cell bg color
const editableCellClassName = (params) => {
  const cellClassName =
    params.isEditable == true || params.cellMode == "edit"
      ? "editable--column"
      : null;

  return cellClassName;
};

export default function UserAgeSetList() {
  // ** Hook
  const toast = useToast();
  const queryClient = useQueryClient();

  // ** State
  // Search conditions
  const [formValues, setFormValues] = useState({
    preferAgeCd: "",
    ageFirst: "",
    ageSecond: "",
    ageMin: "",
    ageMax: ""
  });
  const { preferAgeCd, ageFirst, ageSecond, ageMin, ageMax } = formValues;

  // ** Fetch
  // 아이템 목록 조회
  const {
    isLoading,
    refetch,
    data: rowData
  } = useQuery("userAgeSetList", MatchManageController.getUserAgeSetList);

  // 신규 아이템 추가
  const { mutate: AddItem } = useMutation({
    mutationFn: MatchManageController.addUserAgeSetItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.ADD);
      queryClient.invalidateQueries({ queryKey: "userAgeSetList" });
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 수정
  const { mutate: EditItem } = useMutation({
    mutationFn: MatchManageController.editUserAgeSetItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.EDIT);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 삭제
  const { mutate: DeleteItem } = useMutation({
    mutationFn: MatchManageController.deleteUserAgeSetItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.DELETE);
      queryClient.invalidateQueries({ queryKey: "userAgeSetList" });
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 검색 조건 옵션 변경
  const handleChangeSearchConditions = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // 신규 아이템 추가 버튼 클릭
  const handleClickNew = () => {
    AddItem(formValues);
  };

  // 아이템 수정 후 저장
  const handleClickEdit = (row) => {
    EditItem(row);
  };

  // 아이템 삭제
  const handleClickDelete = (id) => {
    DeleteItem({
      userAgeSetId: id
    });
  };

  const columns = [
    { field: "id", type: "number", headerName: "ID", sortable: true },
    { field: "preferAgeCd", headerName: "선호 나이", sortable: true },
    {
      field: "ageFirst",
      type: "number",
      headerName: "증가 시작 값",
      sortable: false,
      editable: true,
      cellClassName: editableCellClassName
    },
    {
      field: "ageMin",
      type: "number",
      headerName: "증가 종료 값",
      sortable: false,
      editable: true,
      cellClassName: editableCellClassName
    },
    {
      field: "ageSecond",
      type: "number",
      headerName: "증감 시작 값",
      sortable: false,
      editable: true,
      cellClassName: editableCellClassName
    },
    {
      field: "ageMax",
      type: "number",
      headerName: "증감 종료 값",
      sortable: false,
      editable: true,
      cellClassName: editableCellClassName
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      width: 160,
      getActions: (params) => {
        return [
          <Button
            key="action-confirm"
            variant="contained"
            onClick={() => handleClickEdit(params.row)}
          >
            저장
          </Button>,
          <Button
            key="action-confirm"
            variant="outlined"
            color="error"
            onClick={() => handleClickDelete(params.row.id)}
          >
            삭제
          </Button>
        ];
      }
    }
  ];

  return (
    <>
      {/* 신규 추가 */}
      <span>나이 옵션</span>
      <Box display="flex" justifyContent="end" sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            backgroundColor: "#F6F5FF"
          }}
        >
          <TextField
            fullWidth
            type="number"
            label="선호 나이"
            name="preferAgeCd"
            value={preferAgeCd}
            onChange={handleChangeSearchConditions}
            required
          />
          <TextField
            fullWidth
            type="number"
            label="증가 시작 값"
            name="ageFirst"
            value={ageFirst}
            onChange={handleChangeSearchConditions}
            required
          />
          <TextField
            fullWidth
            type="number"
            label="증가 종료 값"
            name="ageMin"
            value={ageMin}
            onChange={handleChangeSearchConditions}
            required
          />
          <TextField
            fullWidth
            type="number"
            label="증감 시작 값"
            name="ageSecond"
            value={ageSecond}
            onChange={handleChangeSearchConditions}
            required
          />
          <TextField
            fullWidth
            type="number"
            label="증감 종료 값"
            name="ageMax"
            value={ageMax}
            onChange={handleChangeSearchConditions}
            required
          />
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={handleClickNew}
          >
            추가
          </Button>
        </Box>
      </Box>
      {/* 목록 */}
      <Box sx={{ pt: 3 }}>
        <GridContainer height={300} columns={columns} rowData={rowData || []} />
      </Box>
    </>
  );
}
