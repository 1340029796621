// ** React Imports
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { clearContent, contentSelector } from "src/store/contentSlice";

// ** Widget Imports
import { Box, Grid, Button } from "@mui/material";
import ContentController from "src/controller/ContentController";
import { NoticeForm } from "src/components/Notice/NoticeForm";
import { TitledPaper } from "src/widgets";

// ** Util Imports
import dayjs from "dayjs";
import {
  API_RESULT,
  API_RESULT_ACTION,
  CONTENT_MODE,
  CONTENT_TYPE
} from "src/constant/commonCode";

function NoticeDetail() {
  // ** Hook
  const toast = useToast();
  const navigate = useNavigate();
  const { adminNotiId } = useParams();

  // ** Store
  const dispatch = useDispatch();
  const currentContent = useSelector(contentSelector).currentContent;

  // ** State
  const [mode, setMode] = useState(CONTENT_MODE.NEW);

  const [formValues, setFormValues] = useState({
    categoryCd: CONTENT_TYPE.NOTICE,
    categorySubCd: "01",
    adminNotiId: null,
    notiTitle: "",
    notiContent: "",
    imageName01: null, // 미사용
    imageName02: null, // 미사용
    imageName03: null, // 미사용
    viewDate: dayjs(new Date()),
    startDate: null, // 미사용
    endDate: null, // 미사용
    closeYn: "N",
    useYn: "Y"
  });

  // Input field handler
  const handleChange = (event) => {
    // 스마트 에디터인 경우 확인. 스마트 에디터는 event data가 없어서 name 지정 필요
    const isReactQuill = !event.target?.name ?? false;
    const name = isReactQuill ? "notiContent" : event.target.name;
    const value = isReactQuill ? event : event.target.value;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // 노출일자
  const handleChangeViewDate = (newValue) => {
    setFormValues((prev) => ({ ...prev, viewDate: newValue }));
  };

  // Save form data
  const handleSubmit = (event) => {
    event.preventDefault();
    if (mode == CONTENT_MODE.NEW) {
      // 신규 추가
      ContentController.addContent(formValues).then((res) => {
        if (res == API_RESULT.SUCCESS) {
          toast.success(API_RESULT_ACTION.ADD);
          navigate("/notice");
        }
      });
    } else {
      // 수정 모드
      ContentController.editContent(formValues).then((res) => {
        if (res == API_RESULT.SUCCESS) {
          toast.success(API_RESULT_ACTION.EDIT);
          navigate("/notice");
        }
      });
    }
  };

  // Delete a data
  const handleDelete = (event) => {
    event.preventDefault();
    ContentController.deleteContent(adminNotiId).then((res) => {
      if (res == API_RESULT.SUCCESS) {
        toast.success(API_RESULT_ACTION.DELETE);
        navigate("/notice");
      }
    });
  };

  // Redux에 currentContent가 있다면 수정모드
  const handleUpdateContent = () => {
    // 읽기 모드 셋팅
    setMode(CONTENT_MODE.EDIT);

    // 컨텐츠 데이터 업데이트
    const {
      adminNotiId,
      categorySubCd,
      notiTitle,
      notiContent,
      viewDate,
      closeYn,
      useYn
    } = currentContent;

    setFormValues((prev) => ({
      ...prev,
      adminNotiId: adminNotiId,
      categoryCd: "01",
      categorySubCd: categorySubCd,
      notiTitle: notiTitle,
      notiContent: notiContent,
      viewDate: viewDate,
      closeYn: closeYn,
      useYn: useYn
    }));
  };

  useEffect(() => {
    Object.keys(currentContent).length > 0 && handleUpdateContent();
  }, [currentContent]);

  useEffect(() => {
    return () => {
      // 상세 페이지 떠나기 전 store 비우기
      dispatch(clearContent());
    };
  }, []);

  return (
    <TitledPaper title="공지사항 상세">
      <form method="post" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <NoticeForm
              values={formValues}
              onChange={handleChange}
              onChangeViewDate={handleChangeViewDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px"
              }}
            >
              <Button color="primary" variant="contained" type="submit">
                저장
              </Button>
              {mode == CONTENT_MODE.EDIT && (
                <Button color="error" variant="outlined" onClick={handleDelete}>
                  삭제
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
    </TitledPaper>
  );
}

export default NoticeDetail;
