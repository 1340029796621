import React from "react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { ProfileTagCard } from "src/components/widgets/Card";

const renderNewBadge = ({ title, newItem }) => {
  return (
    <>
      <Typography
        sx={{ mr: 1 }}
        component="span"
        variant="body1"
        color="text.primary"
      >
        {title}
      </Typography>
      {newItem && (
        <Typography component="span" variant="caption" color="primary">
          NEW
        </Typography>
      )}
    </>
  );
};

const DormantAccount = (props) => {
  const { myData } = props;
  return (
    <Paper elevation={0}>
      <Appbar title="MY" back />
      <Stack spacing={2}>
        <Box p={2} bgcolor="lightGray">
          <Typography variant="subtitle1">
            현재는 계정 휴면 상태입니다. 휴면 해제시 이성 소개가 진행됩니다.
          </Typography>
        </Box>
        <Button size="large" variant="outlined">
          휴면 해제 하기
        </Button>
        <ProfileTagCard user={myData} read={true} my />
        <Box>
          <nav aria-label="main mypage">
            <List>
              <ListItem disablePadding>
                <ListItemButton href="/app/store/package">
                  <ListItemText primary="내 풍선 50개" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="/app/notice">
                  <ListItemText
                    primary={renderNewBadge({ title: "공지사항", newItem: 1 })}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="/app/event">
                  <ListItemText
                    primary={renderNewBadge({ title: "이벤트", newItem: null })}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
          <Divider />
          <nav aria-label="secondary mypage">
            <List>
              <ListItem disablePadding>
                <ListItemButton href="/app/onboard">
                  <ListItemText primary="로그아웃" />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </Box>
      </Stack>
    </Paper>
  );
};

export default DormantAccount;
