import React from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";

const packageOptions = [
  { amount: 1000, bonus: 260, price: 168500 },
  { amount: 500, bonus: 90, price: 88500 },
  { amount: 300, bonus: 45, price: 47500 },
  { amount: 150, bonus: 30, price: 25500 },
  { amount: 70, bonus: 15, price: 16500 },
  { amount: 30, bonus: null, price: 7500 }
];

const renderBonusBadge = ({ title, subTitle }) => {
  return (
    <>
      <Typography
        sx={{ mr: 1 }}
        component="span"
        variant="body1"
        color="text.primary"
      >
        {title}
      </Typography>
      {subTitle && <Chip size="small" label={`+${subTitle}`} />}
    </>
  );
};

const PackageList = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="스토어" back />
      <Stack spacing={2}>
        <Box>
          <List>
            <ListItem disablePadding>
              <ListItemButton href="/app/store/history/1">
                <ListItemText primary="내 풍선 150개" />
              </ListItemButton>
              <IconButton href="/app/store/history/1">
                <ChevronRightIcon />
              </IconButton>
            </ListItem>
          </List>
          <List>
            <ListSubheader>일반 패키지</ListSubheader>
            {packageOptions.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary={renderBonusBadge({
                      title: item.amount,
                      subTitle: item.bonus
                    })}
                  />
                </ListItemButton>
                <Typography variant="body2" sx={{ mr: 2 }}>
                  {item.price}원
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box p={2} bgcolor="lightGray">
          <Typography variant="caption">EVENT</Typography>
          <Typography variant="subtitle1">
            베타기간 동안 접속시, 매일매일 풍선 50개 지급
          </Typography>
        </Box>
        <Box>
          <List
            dense
            sx={{
              listStyleType: "disc",
              pl: 2,
              "& .MuiListItem-root": {
                display: "list-item"
              }
            }}
          >
            <ListSubheader>알림</ListSubheader>
            <ListItem disablePadding>
              <ListItemText primary="모든 상품은 부가세 별도 가격입니다." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary="풍선은 무료 풍선부터 유효기간이 임박한 순으로 먼저 사용됩니다." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary="무료 풍선은 구매 취소 및 환불 대상이 아닙니다." />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary="결제에 문제가 있나요?" />
              <Button size="small" variant="outlined">
                이메일 문의하기
              </Button>
            </ListItem>
          </List>
        </Box>
      </Stack>
    </Paper>
  );
};

export default PackageList;
