import React from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";

const LikeMessageCard = () => {
  return (
    <Card variant="outlined" sx={{ m: 2 }}>
      <CardContent>
        <Stack justifyContent="space-between" height={120}>
          <Typography variant="caption">22시간 후 사라집니다.</Typography>
          <Typography variant="h6">
            BB님으로부터 좋아요가 도착 하였습니다.
          </Typography>
          <Typography variant="caption">삼성전자 / 개발자 / 33</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default LikeMessageCard;
