import React, { useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from "@mui/icons-material";

const NavItem = ({ path: href, icon: Icon, title, child, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClickNav = () => {
    if (child != null) {
      setOpen(!open);
    } else if (href) {
      navigate(href);
    }
  };

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false
        },
        location.pathname
      )
    : false;

  return (
    <>
      <ListItemButton disableGutters onClick={handleClickNav} {...rest}>
        {Icon && (
          <ListItemIcon>
            <Icon size="20" />
          </ListItemIcon>
        )}
        <ListItemText primary={title} />
        {child != null && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItemButton>
      {child != null && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ marginLeft: 4 }}>
            {child
              .filter(({ isMenu }) => isMenu != null && isMenu == true)
              .map((item, index) => (
                <NavItem
                  sx={{ pl: 3 }}
                  key={`sub-${index}`}
                  path={item.path}
                  icon={item.icon}
                  title={item.title}
                  child={item.children}
                />
              ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
