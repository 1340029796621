// ** React Imports
import React from "react";
import { Navigate } from "react-router-dom";

// Layout
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from "./layouts/MainLayout";

// Common page
import NotFound from "./pages/NotFound";

// APP page
// Onboard
import Splash from "./app/Onboard/Splash";
import Onboard from "./app/Onboard";
import Permission from "./app/Onboard/Permission";

// Email Authentication
import EmailAuthentication from "./app/EmailAuth";
import GroupList from "./app/EmailAuth/GroupList";
import EnterEmail from "./app/EmailAuth/EnterEmail";
import RegistGroup from "./app/EmailAuth/RegistGroup";
import AuthenticationCode from "./app/EmailAuth/AuthCode";

// Create Profile
import StepMettingType from "./app/Join/StepMeetingType";
import StepNickName from "./app/Join/StepNickName";
import StepBirthYear from "./app/Join/StepBirthYear";
import StepHeight from "./app/Join/StepHeight";
import StepLocation from "./app/Join/StepLocation";

// Main
import Today from "./app/Today";

// Message
import MessageRecieve from "./app/Message/ReceivedMessageList";
import MessageSend from "./app/Message/SendedMessageList";

// Lounge
import RecievedCard from "./app/Lounge/RevicedCard";
import WaitingRoom from "./app/Lounge/WaitingRoom";

// Chat
import ChatRoabie from "./app/Chat/ChatLoabie";
import ChatRoom from "./app/Chat/ChatRoom";
import ChatWaiting from "./app/Chat/ChatWaiting";

// Profile
import Profile from "./app/Profile";

// MY
import MyPage from "./app/My/MyPage";
import BadgeList from "./app/My/Badge/BadgeList";
import IdealTypeSetting from "./app/My/IdealType/IdealTypeSetting";
import BlockContact from "./app/My/Contact/BlockContact";
import NotificationList from "./app/My/Notification/NotificationList";

// Store
import PackageList from "./app/My/Store/PackageList";
import CoinHistory from "./app/My/Store/CoinHistory";

// Info
import NoticeList from "./app/Information/Notice/NoticeList";
import EventList from "./app/Information/Event/EventList";
import EventDetail from "./app/Information/Event/EventDetail";

// Setting
import MySetting from "./app/Setting/MySetting";
import DormancyGuide from "./app/Setting/AccountManage/DormancyGuide";
import CancelGuide from "./app/Setting/AccountManage/CancelGuide";
import Privacy from "./app/Setting/Privacy";
import Terms from "./app/Setting/Terms";
import BusinessInfo from "./app/Setting/BusinessInfo";

//  Web page
// Admin
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
// 유저 관리
import {
  UserList as UserListPage,
  UserWaitingList as UserWaitingListPage,
  UserDetail as UserDetailPage
} from "./pages/User";
// 매니저
import {
  ManagerList as ManagerListPage,
  ManagerDetail as ManagerDetailPage
} from "./pages/Manager";
// 결제 관리
import { PaymentList as PaymentListPage } from "./pages/Payment";
import { CoinList as CoinListPage } from "./pages/Coin";
// 신고
import { ReportList as ReportListPage } from "./pages/Report";
// 출석체크
import { CheckInList as CheckIListPage } from "./pages/CheckIn";
// 쿠폰 발급 내역
import { CouponList as CouponListPage } from "./pages/Coupon";
// 매칭 정보 관리
import { MatchInfoList as MatchInfoListPage } from "./pages/MatchInfo";
// 앱 정보 관리
import { AppInfoList as AppInfoListPage } from "./pages/AppInfo";
// Content
import {
  NoticeList as NoticeListPage,
  NoticeDetail as NoticeDetailPage
} from "./pages/Content/Notice";
import {
  EventList as EventListPage,
  EventDetail as EventDetailPage,
  EventNew as EventNewPage,
  EventEdit as EventEditPage
} from "./pages/Content/Event";
import {
  FAQList as FAQListPage,
  FAQDetail as FAQDetailPage,
  FAQNew as FAQNewPage,
  FAQEdit as FAQEditPage
} from "./pages/Content/FAQ";
import {
  TOSList as TOSListPage,
  TOSView as TOSViewPage,
  TOSEdit as TOSEditPage
} from "./pages/Content/TOS";
import {
  PrivacyList as PrivacyListPage,
  PrivacyView as PrivacyViewPage,
  PrivacyEdit as PrivacyEditPage
} from "./pages/Content/Privacy";
import {
  BusinessInfoList as BusinessInfoListPage,
  BusinessInfoView as BusinessInfoViewPage,
  BusinessInfoEdit as BusinessInfoEditPage
} from "./pages/Content/BusinessInfo";
// Career
import { CareerList as CareerListPage } from "./pages/Career";
// Common
// Code
import {
  CodeList as CodeListPage,
  GroupCodeList as GroupCodeListPage
} from "./pages/CommonCode/Code";
// Store
import { StoreList as StoreListPage } from "./pages/CommonCode/Store";
// Avatar
import { AvatarList as AvatarListPage } from "./pages/CommonCode/Avatar";
// MBTI
import {
  MbtiList as MbtiListPage,
  MbtiInfoList as MbtiInfoListPage
} from "./pages/CommonCode/Mbti";
// Message 관리
import { MessageList as MessageListPage } from "./pages/CommonCode/Message";

// 로그인 인증이 필요한 경우
import PrivateRoute from "./utils/PrivateRouter";
import { CAREER_TYPE } from "./constant/commonCode";

import {
  Apartment as CareerIcon,
  CurrencyBitcoin as CoinIcon,
  DocumentScanner as DocumentIcon,
  Event as CheckInIcon,
  Home as HomeIcon,
  Payment as PaymentIcon,
  People as PeopleIcon,
  AirplaneTicket as CouponIcon,
  Favorite as MatchInfoIcon,
  Info as VersionInfoIcon,
  Report as ReportIcon,
  Star as CommonCodeIcon
} from "@mui/icons-material";

const appRoutes = {
  path: "app",
  children: [
    // Onboard
    { path: "splash", element: <Splash /> },
    { path: "onboard", element: <Onboard /> },
    { path: "permission", element: <Permission /> },
    // Email Authentication
    {
      path: "email",
      children: [
        { path: "", element: <EmailAuthentication /> },
        { path: "list", element: <GroupList /> },
        { path: "add", element: <EnterEmail /> },
        { path: "new", element: <RegistGroup /> },
        { path: "code", element: <AuthenticationCode /> }
      ]
    },
    // Create Profile
    {
      path: "join",
      children: [
        { path: "meeting-type", element: <StepMettingType /> },
        { path: "nick-name", element: <StepNickName /> },
        { path: "birth-year", element: <StepBirthYear /> },
        { path: "height", element: <StepHeight /> },
        { path: "location", element: <StepLocation /> }
      ]
    },
    // Today
    { path: "main", element: <Today /> },
    // Message
    {
      path: "message",
      children: [
        { path: "recieve", element: <MessageRecieve /> },
        { path: "send", element: <MessageSend /> }
      ]
    },
    // Lounge
    {
      path: "lounge",
      children: [
        { path: "card", element: <RecievedCard /> },
        { path: "wait", element: <WaitingRoom /> }
      ]
    },
    // Chat
    {
      path: "chat",
      children: [
        { path: "loabie", element: <ChatRoabie /> },
        { path: "room/:id", element: <ChatRoom /> },
        { path: "waiting", element: <ChatWaiting /> }
      ]
    },
    // Profile
    { path: "profile/:id", element: <Profile /> },
    // MY
    { path: "my", element: <MyPage /> },
    { path: "my/badge", element: <BadgeList /> },
    { path: "my/ideal-type", element: <IdealTypeSetting /> },
    { path: "my/contact", element: <BlockContact /> },
    { path: "my/notification", element: <NotificationList /> },
    // Store
    {
      path: "store",
      children: [
        { path: "package", element: <PackageList /> },
        { path: "history/:id", element: <CoinHistory /> }
      ]
    },
    // Info
    { path: "notice", element: <NoticeList /> },
    { path: "event", element: <EventList /> },
    { path: "event/:id", element: <EventDetail /> },
    // Setting
    { path: "setting", element: <MySetting /> },
    { path: "dormancy-guide", element: <DormancyGuide /> },
    { path: "cancel-guide", element: <CancelGuide /> },
    { path: "privacy", element: <Privacy /> },
    { path: "terms", element: <Terms /> },
    { path: "business-info", element: <BusinessInfo /> },
    { path: "*", element: <Navigate to="/404" /> }
  ]
};

export const adminRoutes = [
  // 대시보드
  {
    path: "dashboard",
    icon: HomeIcon,
    title: "Dashboard",
    element: <Dashboard />
  },
  // 회원 관리
  {
    path: "",
    icon: PeopleIcon,
    title: "멤버 관리",
    children: [
      {
        path: "user/all",
        title: "유저",
        element: <UserListPage />,
        isMenu: true
      },
      {
        path: "user/waiting",
        title: "가입 대기",
        element: <UserWaitingListPage />,
        isMenu: true
      },
      {
        path: "user/:uAccountId",
        title: "유저 상세",
        element: <UserDetailPage />,
        isMenu: false
      },
      // 매니저 관리
      {
        path: "manager",
        title: "매니저",
        element: <ManagerListPage />,
        isMenu: true
      },
      {
        path: "manager/:adminAccountId",
        element: <ManagerDetailPage />,
        isMenu: false
      },
      {
        path: "manager/new",
        element: <ManagerDetailPage />,
        isMenu: false
      }
    ]
  },
  // 결제 관리
  {
    path: "payment",
    icon: PaymentIcon,
    title: "결제 관리",
    element: <PaymentListPage />
  },
  // 풍선 적립 내역
  {
    path: "coin",
    icon: CoinIcon,
    title: "풍선 적립 내역",
    element: <CoinListPage />
  },
  // 신고 관리
  {
    path: "report",
    icon: ReportIcon,
    title: "신고 관리",
    element: <ReportListPage />
  },
  // 출석체크 내역
  {
    path: "check-in",
    icon: CheckInIcon,
    title: "출석체크 관리",
    element: <CheckIListPage />
  },
  // 쿠폰 발급 내역
  {
    path: "coupon",
    icon: CouponIcon,
    title: "쿠폰 관리",
    element: <CouponListPage />
  },
  // 매칭 정보 관리
  {
    path: "match-info",
    icon: MatchInfoIcon,
    title: "매칭 정보 관리",
    element: <MatchInfoListPage />
  },
  // 앱 정보 관리
  {
    path: "app-info",
    icon: VersionInfoIcon,
    title: "앱 정보 관리",
    element: <AppInfoListPage />
  },
  // 컨텐츠 관리
  {
    path: "",
    icon: DocumentIcon,
    title: "컨텐츠 관리",
    children: [
      // 공지사항
      {
        path: "notice",
        title: "공지사항",
        element: <NoticeListPage />,
        isMenu: true
      },
      {
        path: "notice:adminNotiId",
        title: "공지사항 상세",
        element: <NoticeDetailPage />,
        isMenu: false
      },
      // 이벤트
      {
        path: "event",
        title: "이벤트",
        element: <EventListPage />,
        isMenu: true
      },
      {
        path: "event/:adminNotiId",
        title: "이벤트 상세",
        element: <EventDetailPage />,
        isMenu: false
      },
      {
        path: "event/new",
        title: "이벤트 신규",
        element: <EventNewPage />,
        isMenu: false
      },
      {
        path: "event/edit/:adminNotiId",
        title: "이벤트 수정",
        element: <EventEditPage />,
        isMenu: false
      },
      // 자주 묻는 질문
      {
        path: "faq",
        title: "자주 묻는 질문",
        element: <FAQListPage />,
        isMenu: true
      },
      {
        path: "faq/:adminNotiId",
        title: "자주 묻는 질문 상세",
        element: <FAQDetailPage />,
        isMenu: false
      },
      {
        path: "faq/new",
        title: "자주 묻는 질문 신규",
        element: <FAQNewPage />,
        isMenu: false
      },
      {
        path: "faq/edit/:adminNotiId",
        title: "자주 묻는 질문 수정",
        element: <FAQEditPage />,
        isMenu: false
      },
      // 이용약관
      {
        path: "tos",
        title: "이용약관",
        element: <TOSListPage />,
        isMenu: true
      },
      {
        path: "tos/:adminNotiId",
        title: "이용약관 상세",
        element: <TOSViewPage />,
        isMenu: false
      },
      {
        path: "tos/new",
        title: "이용약관 신규",
        element: <TOSEditPage />,
        isMenu: false
      },
      {
        path: "tos/edit/:adminNotiId",
        title: "이용약관 수정",
        element: <TOSEditPage />,
        isMenu: false
      },
      // 개인정보 취급방침
      {
        path: "privacy",
        title: "개인정보 취급방침",
        element: <PrivacyListPage />,
        isMenu: true
      },
      {
        path: "privacy/:adminNotiId",
        title: "개인정보 취급방침 상세",
        element: <PrivacyViewPage />,
        isMenu: false
      },
      {
        path: "privacy/new",
        title: "개인정보 취급방침 신규",
        element: <PrivacyEditPage />,
        isMenu: false
      },
      {
        path: "privacy/edit/:adminNotiId",
        title: "개인정보 취급방침 수정",
        element: <PrivacyEditPage />,
        isMenu: false
      },
      // 사업자 정보
      {
        path: "bi",
        title: "사업자 정보",
        element: <BusinessInfoListPage />,
        isMenu: true
      },
      {
        path: "bi/:adminNotiId",
        title: "사업자 정보 상세",
        element: <BusinessInfoViewPage />,
        isMenu: false
      },
      {
        path: "bi/new",
        title: "사업자 정보 신규",
        element: <BusinessInfoEditPage />,
        isMenu: false
      },
      {
        path: "bi/edit/:adminNotiId",
        title: "사업자 정보 수정",
        element: <BusinessInfoEditPage />,
        isMenu: false
      }
    ]
  },
  // Career 관리
  {
    path: "",
    icon: CareerIcon,
    title: "Career 관리",
    children: [
      {
        path: "career/company",
        title: "직장",
        element: <CareerListPage menuId={CAREER_TYPE.COMPANY} />,
        isMenu: true
      },
      {
        path: "career/university",
        title: "학교",
        element: <CareerListPage menuId={CAREER_TYPE.UNIVERSITY} />,
        isMenu: true
      }
    ]
  },
  // 공통코드
  {
    path: "",
    icon: CommonCodeIcon,
    title: "공통 관리",
    children: [
      {
        path: "common/code",
        title: "코드",
        element: <CodeListPage />,
        isMenu: true
      },
      {
        path: "common/group-code",
        title: "그룹 코드",
        element: <GroupCodeListPage />,
        isMenu: true
      },
      {
        path: "common/store",
        title: "상점",
        element: <StoreListPage />,
        isMenu: true
      },
      {
        path: "common/avatar",
        title: "아바타",
        element: <AvatarListPage />,
        isMenu: true
      },
      {
        path: "common/mbti",
        title: "MBTI",
        element: <MbtiListPage />,
        isMenu: true
      },
      {
        path: "common/mbti/info",
        element: <MbtiInfoListPage />,
        isMenu: false
      },
      {
        path: "common/message",
        title: "메세지",
        element: <MessageListPage />,
        isMenu: true
      }
    ]
  }
];

const routes = [
  appRoutes, // Demo App routes
  {
    path: "",
    element: <PrivateRoute layout={DashboardLayout} />,
    children: adminRoutes // Admin
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "404", element: <NotFound /> },
      { path: "login", element: <Login /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
