import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { ProfileTagCard } from "src/components/widgets/Card";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";

const renderNewBadge = ({ title, newItem }) => {
  return (
    <>
      <Typography
        sx={{ mr: 1 }}
        component="span"
        variant="body1"
        color="text.primary"
      >
        {title}
      </Typography>
      {newItem && (
        <Typography component="span" variant="caption" color="primary">
          NEW
        </Typography>
      )}
    </>
  );
};

const NormalAccount = (props) => {
  const { myData } = props;
  return (
    <Paper elevation={0}>
      <Appbar title="MY" back setting notification />
      <Stack spacing={2}>
        <Box p={2} bgcolor="lightGray">
          <Typography variant="caption">NOTICE</Typography>
          <Typography variant="subtitle1">
            추석 연휴 기간 고객센터 휴무 공지
          </Typography>
        </Box>
        <ProfileTagCard user={myData} read={true} my />
        <Box>
          <nav aria-label="main mypage">
            <List>
              <ListItem disablePadding>
                <ListItemButton href="/app/store/package">
                  <ListItemText primary={`내 풍선 ${myData.coinCnt}개`} />
                </ListItemButton>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ mr: 2 }}
                  href="/app/store/package"
                >
                  충전하기
                </Button>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="/app/my/badge">
                  <ListItemText primary="인증" />
                </ListItemButton>
                <IconButton href="/app/my/badge">
                  <ChevronRightIcon />
                </IconButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="/app/my/ideal-type">
                  <ListItemText primary="이상형 설정" />
                </ListItemButton>
                <IconButton href="/app/my/ideal-type">
                  <ChevronRightIcon />
                </IconButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="/app/my/contact">
                  <ListItemText primary="지인 차단" />
                </ListItemButton>
                <IconButton href="/app/my/contact">
                  <ChevronRightIcon />
                </IconButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="/app/notice">
                  <ListItemText
                    primary={renderNewBadge({ title: "공지사항", newItem: 1 })}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton href="/app/event">
                  <ListItemText
                    primary={renderNewBadge({ title: "이벤트", newItem: null })}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
          <Divider />
          <nav aria-label="secondary mypage">
            <List>
              <ListItem disablePadding>
                <ListItemButton href="/app/onboard">
                  <ListItemText primary="로그아웃" />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </Box>
      </Stack>
    </Paper>
  );
};

export default NormalAccount;
