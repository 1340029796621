import React from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { CHAT_STATUS } from "src/constant/commonCode";
import { returnMeetingType } from "src/constant/returnData";

const ChatInfoCard = (props) => {
  const { day, meetingType, nickName } = props;

  switch (day) {
    case CHAT_STATUS.FIRST:
      return (
        <Card>
          <CardContent>
            <Typography variant="h6">남은 시간 2일</Typography>
            <Typography variant="body1">
              매칭 된 지 1일 째 입니다. 두근두근 새로운 만남!
              {returnMeetingType(meetingType)}을 선호하는 {nickName}님은 어떤 분
              이실까요? 대화방은 3일까지 유지 됩니다.
            </Typography>
          </CardContent>
        </Card>
      );
    case CHAT_STATUS.SECOND:
      return (
        <Card>
          <CardContent>
            <Typography variant="h6">남은 시간 1일</Typography>
            <Typography variant="body1">
              매칭 된 지 2일 째 입니다. {returnMeetingType(meetingType)}을
              선호하는 {nickName}님과의 대화는 어떠신가요? 공통된 관심사를
              찾아보셨나요? 대화방은 3일까지 유지 됩니다.
            </Typography>
          </CardContent>
        </Card>
      );
    case CHAT_STATUS.THIRD:
      return (
        <Card>
          <CardContent>
            <Typography variant="h6">남은 시간 16:33:33️</Typography>
            <Typography variant="body1">
              매칭 된 지 3일 째 입니다. {returnMeetingType(meetingType)}을
              선호하는 {nickName}님과 만나보시는 것은 어떠실까요? 대화방은
              3일까지 유지 됩니다.
            </Typography>
          </CardContent>
        </Card>
      );
    case CHAT_STATUS.CLOSE:
      return (
        <Card>
          <CardContent>
            <Typography variant="h6">대화가 종료되었습니다</Typography>
            <Typography variant="body1">
              아직 상대방이 회원님의 매력을 모르나 봅니다. 매력적인 이성을
              찾아볼까요?
            </Typography>
            <Button variant="outlined">라운지 바로가기</Button>
          </CardContent>
        </Card>
      );
  }
};

export default ChatInfoCard;
