import React, { useCallback } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from "@mui/material";
import moment from "moment";
import customerHistory from "src/__mocks__/customerHistory";

export const AccountHistory = () => {
  const handleSubmit = useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          subheader="The information can not be edited"
          title="Memo"
        />
        <CardContent>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column"
            }}
            mb={2}
          >
            <TextField fullWidth name="memo" multiline />
          </Box>
          <Button fullWidth variant="outlined">
            Save
          </Button>
        </CardContent>
        <Divider />
        <CardContent>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={0} orientation="vertical">
              {customerHistory.map((item, index) => (
                <Step key={index}>
                  <StepLabel>
                    {moment(item.lastLoginedAt).format("YYYY/MM/DD hh:ss:mm")}
                  </StepLabel>
                  <StepContent>
                    <Typography>{item.description}</Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};
