import { v4 as uuid } from "uuid";

export default [
  {
    id: 5,
    type: "회사",
    groupName: "남북통일소프트",
    email: "gildong@southnorthsoft.com",
    socialTypeName: "카카오",
    socialId: uuid(),
    status: "완료",
    createdAt: 1555016400000
  },
  {
    id: 4,
    type: "회사",
    groupName: "안디고소프트",
    email: "sora@andigosoft.com",
    socialTypeName: "카카오",
    socialId: uuid(),
    status: "요청",
    createdAt: 1555016400000
  },
  {
    id: 3,
    type: "학교",
    groupName: "안양대학교",
    email: "mira@anyanguni.com",
    socialTypeName: "네이버",
    socialId: uuid(),
    status: "요청",
    createdAt: 1555016400000
  },
  {
    id: 2,
    type: "회사",
    groupName: "Figma Korea",
    email: "jongmi@figmakorea.com",
    socialTypeName: "구글",
    socialId: uuid(),
    status: "요청",
    createdAt: 1555016400000
  },
  {
    id: 1,
    type: "학교",
    groupName: "Percific Univercity",
    email: "james.choi@percificuni.com",
    socialTypeName: "카카오",
    socialId: uuid(),
    status: "요청",
    createdAt: 1555016400000
  }
];
