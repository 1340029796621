import StoreService from "src/services/StoreService";
import { API_RESULT } from "src/constant/commonCode";

class StoreController {
  /*
   * 상점 목록 조회
   */
  getStoreItemList() {
    const result = StoreService.selectStoreItemList();
    return result;
  }

  /*
   * 상품 1건 신규 추가
   * productName 상품 명
   * productPrice 상품 가격
   * productDesc 상품 설명
   * productBcnt 상품 보너스
   * useYn 사용 유무
   */
  addStoreItem(params) {
    const payload = {
      productId: params.productId,
      productName: params.productName,
      productPrice: params.productPrice,
      productDesc: params.productDesc,
      productBcnt: params.productBcnt,
      useYn: params.useYn
    };

    return new Promise((resolve, reject) => {
      StoreService.insertStoreItem(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 상품 1건 수정
   * storeInfoId 상점 ID
   * productId 상품 ID
   * productName 상품 명
   * productPrice 상품 가격
   * productDesc 상품 설명
   * productBcnt 상품 보너스
   * useYn 사용 유무
   */
  editStoreItem(params) {
    const payload = {
      storeInfoId: params.storeInfoId,
      productId: params.productId,
      productName: params.productName,
      productPrice: params.productPrice,
      productDesc: params.productDesc,
      productBcnt: params.productBcnt,
      useYn: params.useYn
    };

    return new Promise((resolve, reject) => {
      StoreService.updateStoreItem(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 상품 1건 삭제
   * storeInfoId 상점 ID
   */
  deleteStoreItem(storeInfoId) {
    return new Promise((resolve, reject) => {
      StoreService.deleteStoreItem(storeInfoId).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }
}

export default new StoreController();
