import React from "react";
import { Button, Paper, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import InfoSection from "src/components/widgets/InfoSection";

const Blocked = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="계정 정지" />
      <InfoSection>
        <Typography variant="subtitle1">
          계정이 사용 중지 되었습니다.
        </Typography>
        <Typography variant="body2">
          UBlooming 관리자가 회원님의 계정을 사용 중지 했습니다. 도움이 필요한
          경우 관리자에게 문의 부탁드립니다.
        </Typography>
        <Button variant="outlined">이메일 문의하기</Button>
      </InfoSection>
    </Paper>
  );
};

export default Blocked;
