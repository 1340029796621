import React from "react";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const StepNickName = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="프로필 생성" back />
      <Stack height="92vh" justifyContent="space-between">
        <Stack>
          <Typography variant="h6">닉네임을 입력해주세요</Typography>
          <TextField
            fullWidth
            variant="standard"
            inputProps={{ minLength: 2, maxLength: 12 }}
            label="닉네임"
            placeholder="홍길동"
          />
        </Stack>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          href="/app/join/birth-year"
        >
          다음
        </Button>
      </Stack>
    </Paper>
  );
};

export default StepNickName;
