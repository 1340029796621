// ** React Imports
import React from "react";
import { useNavigate } from "react-router-dom";

// ** Redux Imports
import { useQuery } from "react-query";

// ** Redux Imports
import { useDispatch } from "react-redux";
import { clearContent, setContent } from "src/store/contentSlice";
import ManagerController from "src/controller/ManagerController";

// ** Widget Imports
import { Box, Button } from "@mui/material";
import { GridContainer } from "src/components";
import { TitledPaper } from "src/widgets";

const columns = [
  { field: "id", headerName: "ID", type: "number", sortable: true },
  { field: "adminAccount", headerName: "로그인 ID", sortable: true },
  { field: "adminStatusValue", headerName: "계정 상태", sortable: true },
  { field: "adminNickName", headerName: "한글 명", sortable: true },
  { field: "adminRoleValue", headerName: "직급", sortable: true },
  { field: "useYn", headerName: "사용 유무", sortable: true }
];

export default function ManagerList() {
  // ** Hook
  const navigate = useNavigate();

  // ** Store
  const dispatch = useDispatch();

  // ** Fetch
  // 아이템 목록 조회
  const {
    isLoading,
    refetch,
    data: rowData
  } = useQuery("managers", ManagerController.getManagerList);

  // 신규 아이템 추가 버튼 클릭
  const handleClickNew = () => {
    // 신규작성 페이지 이동 전 store 비우기
    dispatch(clearContent());
    // 신규 작성 페이지 이동
    navigate("/manager/new");
  };

  // Grid row 클릭시 상세 페이지 이동
  const handleRowClick = (params) => {
    // 현재 데이터 store에 저장
    dispatch(setContent(params.row));
    // 상세 페이지 이동
    navigate(`/manager/${params.id}`);
  };

  return (
    <TitledPaper title="매니저">
      <Box display="flex" justifyContent="end" sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <Button color="primary" variant="outlined" onClick={handleClickNew}>
            추가
          </Button>
          <Button color="primary" variant="contained" onClick={refetch}>
            검색
          </Button>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <GridContainer
          columns={columns}
          rowData={rowData || []}
          onRowClick={handleRowClick}
        />
      </Box>
    </TitledPaper>
  );
}
