// ** React Imports
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

// ** Redux Imports
import { useDispatch } from "react-redux";
import { clearContent, setContent } from "src/store/contentSlice";

// ** Widget Imports
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import ContentController from "src/controller/ContentController";
import { GridContainer } from "src/components";

// ** Util Imports
import { CONTENT_TYPE } from "src/constant/commonCode";

const columns = [
  { field: "id", headerName: "ID", type: "number", width: 70 },
  { field: "notiTitle", headerName: "버전", width: 100 },
  { field: "notiContent", headerName: "내용", width: 400 },
  {
    field: "viewDate",
    type: "date",
    headerName: "노출일자",
    sortable: true,
    width: 180,
    valueGetter: ({ value }) => new Date(value)
  },
  {
    field: "createDate",
    type: "date",
    headerName: "등록일자",
    sortable: true,
    width: 180,
    valueGetter: ({ value }) => new Date(value)
  },
  {
    field: "useYn",
    headerName: "사용 여부",
    sortable: true,
    width: 90
  }
];

export default function PrivacyList() {
  // ** Hooks
  const navigate = useNavigate();

  // ** Store
  const dispatch = useDispatch();

  // ** State
  const [searchConditions, setSearchConditions] = useState({
    categoryCd: CONTENT_TYPE.PRIVACY,
    categorySubCd: null,
    useYn: "00"
  });

  // ** Fetch
  const {
    isLoading,
    refetch,
    data: rowData
  } = useQuery("privacy", () => ContentController.getContent(searchConditions));

  // 검색 조건 옵션 변경
  const handleChangeSearchConditions = (e) => {
    const { name, value } = e.target;
    setSearchConditions({
      ...searchConditions,
      [name]: value
    });
  };

  const handleClickNew = (e) => {
    // 신규작성 페이지 이동 전 store 비우기
    dispatch(clearContent());
    // 신규 작성 페이지 이동
    navigate("/privacy/new");
  };

  // Grid row 클릭시 상세 페이지 이동
  const handleRowClick = (params) => {
    // 현재 데이터 store에 저장
    dispatch(setContent(params.row));
    // 상세 페이지 이동
    navigate(`/privacy/${params.id}`);
  };

  return (
    <>
      <Helmet>
        <title>개인정보 취급방침 | uBlooming Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">개인정보 취급방침 목록</Typography>
            </div>
            <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "8px"
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="simple-select-label">사용 여부</InputLabel>
                  <Select
                    labelId="use-select-label"
                    id="useYn"
                    name="useYn"
                    label="useYn"
                    defaultValue={"00"}
                    onChange={handleChangeSearchConditions}
                  >
                    <MenuItem value={"00"}>전체</MenuItem>
                    <MenuItem value={"Y"}>Y</MenuItem>
                    <MenuItem value={"N"}>N</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "8px"
                }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClickNew}
                >
                  추가
                </Button>
                <Button color="primary" variant="contained" onClick={refetch}>
                  검색
                </Button>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
              <GridContainer
                columns={columns}
                rowData={rowData || []}
                onRowClick={handleRowClick}
              />
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
