// ** React Imports
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Redux Imports
import { useSelector } from "react-redux";
import { contentSelector } from "src/store/contentSlice";

import { Box, Typography, Grid, Button } from "@mui/material";
import ContentController from "src/controller/ContentController";
import { TitledPaper } from "src/widgets";

// Utils
import {
  API_RESULT,
  API_RESULT_ACTION,
  CONTENT_TYPE
} from "src/constant/commonCode";

function EventDetail() {
  // ** Hook
  const toast = useToast();
  const navigate = useNavigate();
  const { adminNotiId } = useParams();

  // ** Store
  const currentContent = useSelector(contentSelector).currentContent;

  // ** State
  const [formValues, setFormValues] = useState({
    categoryCd: CONTENT_TYPE.EVENT,
    categorySubCd: null,
    adminNotiId: null,
    notiTitle: null,
    notiContent: null,
    imageName01: null,
    imageName02: null,
    imageName03: null,
    viewDate: null,
    startDate: null,
    endDate: null,
    closeYn: null,
    useYn: null
  });

  const handleUpdateContent = () => {
    // 컨텐츠 업데이트
    const {
      adminNotiId,
      category,
      categoryCd,
      categorySub,
      categorySubCd,
      notiTitle,
      notiContent,
      imageName01,
      imageName02,
      viewDate,
      startDate,
      endDate,
      closeYn,
      useYn
    } = currentContent;

    setFormValues((prev) => ({
      ...prev,
      adminNotiId: adminNotiId,
      category: category,
      categoryCd: categoryCd,
      categorySub: categorySub,
      categorySubCd: categorySubCd,
      notiTitle: notiTitle,
      notiContent: notiContent,
      imageName01: imageName01,
      imageName02: imageName02,
      viewDate: viewDate,
      startDate: startDate,
      endDate: endDate,
      closeYn: closeYn,
      useYn: useYn
    }));
  };

  // 수정 화면 이동
  const handleMovePage = () => {
    // 상세 페이지 이동
    navigate(`/event/edit/${adminNotiId}`);
  };

  // Delete a data
  const handleDelete = (event) => {
    event.preventDefault();
    ContentController.deleteContent(adminNotiId).then((res) => {
      if (res == API_RESULT.SUCCESS) {
        toast.success(API_RESULT_ACTION.DELETE);
        navigate("/event");
      }
    });
  };

  useEffect(() => {
    // currentContent 데이터가 있다면 불러온다.
    Object.keys(currentContent).length > 0 && handleUpdateContent();
  }, [currentContent]);

  return (
    <TitledPaper title="Event">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {/* ID */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">ID</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{adminNotiId}</Typography>
            </Grid>
            {/* 카테고리 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">카테고리</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {formValues.categorySub}({formValues.categorySubCd})
              </Typography>
            </Grid>
            {/* 진행 여부 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">진행 여부</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {formValues.closeYn == "Y" ? "진행중" : "종료"}
              </Typography>
            </Grid>
            {/* 노출 일자 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">노출 일자</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{formValues.viewDate}</Typography>
            </Grid>
            {/* 이벤트 기간 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">이벤트 기간</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {formValues.startDate} ~ {formValues.endDate}
              </Typography>
            </Grid>
            {/* 제목 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">제목</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{formValues.notiTitle}</Typography>
            </Grid>
            {/* 내용 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">내용</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{formValues.notiContent}</Typography>
            </Grid>
            {/* 메인 이미지 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">메인 이미지</Typography>
            </Grid>
            <Grid item xs={8}>
              {formValues.imageName01 ? (
                <img
                  src={`https://api.ublooming.co.kr/images/${formValues.imageName01}`}
                  alt="BannerImage"
                  width={200}
                  height={80}
                />
              ) : (
                "-"
              )}
            </Grid>
            {/* 배너 이미지 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">배너 이미지</Typography>
            </Grid>
            <Grid item xs={8}>
              {formValues.imageName02 ? (
                <img
                  src={`https://api.ublooming.co.kr/images/${formValues.imageName02}`}
                  alt="BannerImage"
                  width={200}
                  height={80}
                />
              ) : (
                "-"
              )}
            </Grid>
            {/* 노출 여부 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">노출 여부</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {formValues.useYn == "Y" ? "노출" : "미노출"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px"
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleMovePage}
            >
              수정
            </Button>
            <Button color="error" variant="outlined" onClick={handleDelete}>
              삭제
            </Button>
          </Box>
        </Grid>
      </Grid>
    </TitledPaper>
  );
}

export default EventDetail;
