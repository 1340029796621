import GroupCodeService from "src/services/GroupCodeService";
import { API_RESULT } from "src/constant/commonCode";

class GroupCodeController {
  /*
   * 그룹 코드 목록 조회
   * useYn 사용 여부
   */
  getGroupCodeList(params) {
    const result = GroupCodeService.selectGroupCodeList(params);
    return result;
  }

  /*
   * 그룹 코드 1건 신규 추가
   * String groupCode 그룹 코드
   * String groupName 그룹 코드 명
   * String groupCodeDesc 그룹 코드 설명
   * String useYn 사용 여부 Y/N
   */
  addGroupCode(params) {
    const payload = {
      groupCode: params.groupCode,
      groupName: params.groupName,
      groupCodeDesc: params.groupCodeDesc,
      useYn: params.useYn
    };

    return new Promise((resolve, reject) => {
      GroupCodeService.insertGroupCode(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 그룹 코드 1건 수정
   * commonGroupCodeId 그룹 코드 ID
   * String groupCode 그룹 코드
   * String groupName 그룹 코드 명
   * String groupCodeDesc 그룹 코드 설명
   * String useYn 사용 여부 Y/N
   */
  editGroupCode(params) {
    const payload = {
      commonGroupCodeId: params.commonGroupCodeId,
      groupCode: params.groupCode,
      groupName: params.groupName,
      groupCodeDesc: params.groupCodeDesc,
      useYn: params.useYn
    };

    return new Promise((resolve, reject) => {
      GroupCodeService.updateGroupCode(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 그룹 코드 1건 삭제
   * commonGroupCodeId 그룹 코드 id
   */
  deleteGroupCode(commonGroupCodeId) {
    return new Promise((resolve, reject) => {
      GroupCodeService.deleteGroupCode(commonGroupCodeId).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }
}

export default new GroupCodeController();
