// ** React Imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// ** Hook Imports
import { useToast } from "src/hooks";

import AuthController from "src/controller/AuthController";

// ** Widget Imports
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { API_RESULT } from "src/constant/commonCode";

function Login() {
  // ** Hook
  const toast = useToast();
  const navigate = useNavigate();

  // ** State
  const [formValues, setFormValues] = useState({
    adminAccount: "ublooming",
    adminPw: "admin"
  });
  const { adminAccount, adminPw } = formValues;

  // Input field handler
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // 로그인
  const handleSubmit = async (e) => {
    e.preventDefault();

    await AuthController.login(formValues)
      .then((res) => {
        // 메인 페이지로 이동
        navigate("/");
        // 로그인 성공시
        toast.success("로그인 되었습니다.");
      })
      .catch((error) => {
        toast.success(error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Login | uBlooming Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center"
        }}
      >
        <Container maxWidth="sm">
          <form method="post" onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography color="textPrimary" variant="h2">
                Sign in
              </Typography>
            </Box>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="ID"
              name="adminAccount"
              value={adminAccount}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              type="password"
              label="Password"
              name="adminPw"
              value={adminPw}
              onChange={handleChange}
              required
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign in
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
}

export default Login;
