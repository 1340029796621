import dayjs from "dayjs";

const _dateFormat = "YYYY-MM-DD";

const dateFormat = (pDate) => {
  const fDate = dayjs(pDate).format(_dateFormat);
  return fDate.toString();
};

const stringToDate = (pDate) => {
  const fDate = dayjs(pDate);
  return fDate;
};

export { dateFormat, stringToDate };
