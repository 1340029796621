import React from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { ProfileTagCard } from "src/components/widgets/Card";
import BttmNavbar from "src/components/widgets/BttmNavbar";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import customers from "src/__mocks__/customers";

const RecievedCard = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="Lounge" my />
      <Stack spacing={2}>
        <Box p={2} bgcolor="lightGray">
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography variant="subtitle1">
                정오에 카드가 도착해요
              </Typography>
              <Typography variant="subtitle2">
                나와 연결 가능한 회원 <b>0명</b>
              </Typography>
            </Stack>
            <IconButton href="/app/lounge/wait">
              <ChevronRightIcon />
            </IconButton>
          </Stack>
        </Box>
        <List>
          {customers.map((item, idx) => (
            <ListItem key={idx}>
              <ProfileTagCard user={item} read={idx == 0 ? false : true} />
            </ListItem>
          ))}
        </List>
      </Stack>
      <BttmNavbar />
    </Paper>
  );
};

export default RecievedCard;
