import React from "react";
import ReactQuill from "react-quill";

import { Box, Card, CardContent, TextField, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export const NoticeForm = ({ values, onChange, onChangeViewDate }) => {
  const { categorySubCd, notiTitle, notiContent, viewDate, closeYn, useYn } =
    values;

  const handleChange = (event) => {
    onChange && onChange(event);
  };

  // 노출일자
  const handleChangeViewDate = (newValue) => {
    onChangeViewDate && onChangeViewDate(newValue);
  };

  return (
    <Card>
      <CardContent sx={{ pt: 0 }}>
        <Box sx={{ m: 1.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                SelectProps={{ native: true }}
                label="카테고리"
                name="categorySubCd"
                value={categorySubCd}
                onChange={handleChange}
                required
              >
                <option value="10">업데이트</option>
                <option value="20">기타</option>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="제목"
                name="notiTitle"
                value={notiTitle}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ReactQuill
                style={{
                  whiteSpace: "pre-wrap",
                  height: "300px",
                  marginBottom: "33px"
                }}
                theme="snow"
                name="notiContent"
                value={notiContent}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label="노출일자"
                name="viewDate"
                value={viewDate}
                onChange={handleChangeViewDate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                SelectProps={{ native: true }}
                label="사용"
                name="useYn"
                value={useYn}
                onChange={handleChange}
                required
              >
                <option value="Y">Y</option>
                <option value="N">N</option>
              </TextField>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
