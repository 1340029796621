import * as React from "react";
import { Paper } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

export default function BttmNavbar() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation showLabels value={value} onChange={handleChange}>
        <BottomNavigationAction label="Today" href="/app/main" />
        <BottomNavigationAction label="Message" href="/app/message/recieve" />
        <BottomNavigationAction label="Lounge" href="/app/lounge/card" />
        <BottomNavigationAction label="Chat" href="/app/chat/loabie" />
      </BottomNavigation>
    </Paper>
  );
}
