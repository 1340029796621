// ** React Imports
import React from "react";
import { useRoutes } from "react-router-dom";
import GlobalStyles from "./theme/globalStyles";
import routes from "./routes";

// ** Date picker Import
import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  const content = useRoutes(routes);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GlobalStyles />
      {content}
    </LocalizationProvider>
  );
}

export default App;
