import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";
import { convertToBoolean, createDynamicPath } from "src/utils/dataConverter";

class CareerService {
  /*
   * 공통코드 목록 조회
   * searchText 검색 텍스트 조건
   */
  careerList(careerType, params) {
    // Creating path
    const basePath = `/code/${careerType}`;
    const dynamicPath = createDynamicPath(basePath, params);

    return axiosGet(dynamicPath).then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.careerInfoId,
          authYn: convertToBoolean(item.authYn),
          useYn: convertToBoolean(item.useYn)
        };
      });
    });
  }

  /*
   * 공통코드 1건 신규 추가
   * String careerEmail 회사 홈페이지 주소
   * String companyName 회사 명
   * String useYn 사용 여부 Y/N
   */
  insertCareer(careerType, payload) {
    return axiosPost(`/code/${careerType}`, payload).then((response) => {
      return response;
    });
  }

  /*
   * 공통코드 1건 수정
   * int careerInfoId career ID
   * String careerEmail 회사 홈페이지 주소
   * String companyName 회사 명
   * String useYn 사용 여부 Y/N
   */
  updateCareer(careerType, payload) {
    return axiosPut(`/code/${careerType}`, payload).then((response) => {
      return response;
    });
  }

  /*
   * 공통코드 1건 삭제
   * int careerInfoId career ID
   */
  deleteCareer(careerType, careerInfoId) {
    return axiosDelete(`/code/${careerType}/${careerInfoId}`).then(
      (response) => {
        return response;
      }
    );
  }
}

export default new CareerService();
