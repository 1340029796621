import React from "react";
import { List, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import {
  BasicMessageCard,
  ChatWaitingCard,
  LikeMessageCard
} from "src/components/widgets/Card";
import BttmNavbar from "src/components/widgets/BttmNavbar";
import rowData from "src/__mocks__/chatWaitingList";

const Today = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="Today" my />
      <Stack spacing={2}>
        <Stack width="180px" p={2}>
          <Typography variant="subtitle1">회원님</Typography>
          <Typography variant="subtitle1">
            이성분들이 대화를 기다리고 있어요!
          </Typography>
        </Stack>
        <Stack>
          <Stack pl={2} pr={2} direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">
              받은 쪽지 <b>1</b>
            </Typography>
            <Typography variant="subtitle1">ALL</Typography>
          </Stack>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label={<BasicMessageCard />} />
            <Tab label={<LikeMessageCard />} />
            <Tab label={<BasicMessageCard />} />
            <Tab label={<BasicMessageCard />} />
          </Tabs>
        </Stack>
        <Stack>
          <Stack pl={2} pr={2} direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">매칭 가능한 회원</Typography>
            <Typography variant="subtitle1">ALL</Typography>
          </Stack>
          <List>
            {rowData.map((item, index) => (
              <ChatWaitingCard key={index} user={item} />
            ))}
          </List>
        </Stack>
      </Stack>
      <BttmNavbar />
    </Paper>
  );
};

export default Today;
