import React from "react";
import { Avatar, Box, Typography, Stack } from "@mui/material";
import { BasicInfo, SelfIntro, TagGroup } from "../widgets/Profile";
import moment from "moment";
import customers from "src/__mocks__/customers";

const user = customers[0];

export const AccountProfile = ({ mode }) => {
  return (
    <Stack spacing={2}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Avatar
          src={`/static/avatar/${user.gender == "남" ? "M" : "F"}${
            user.avatar
          }.png`}
          sx={{
            width: 80,
            height: 80
          }}
        />
        <Typography gutterBottom variant="h5">
          {user.userNickName}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {moment(user.lastLoginedAt).format("YYYY/MM/DD hh:ss:mm")}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {user.socialType} / {user.userStatusName}
        </Typography>
      </Box>
      <SelfIntro contents={user?.bio} />
      <BasicInfo user={user} admin mode={mode} />
      <TagGroup title="이런 특징이 있어요" list={user.characteristic} />
      <TagGroup title="저는 이런 사람 이에요" list={user.mysellf} />
      <TagGroup title="여가 시간에는 이렇게 보내요" list={user.hobby} />
    </Stack>
  );
};
