import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import NoRowsOverlay from "./NoRowsOverlay";

export default function MuiDataGrid(props) {
  const { columns, rowData, onRowClick } = props;

  const handleRowClick = (params, event, details) => {
    onRowClick(params, event, details);
  };

  return (
    <div style={{ height: 631, width: "100%" }}>
      <DataGrid
        slots={{
          noRowsOverlay: NoRowsOverlay
        }}
        rows={rowData}
        columns={columns}
        onRowClick={handleRowClick}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 }
          }
        }}
        pageSizeOptions={[10, 50]}
      />
    </div>
  );
}
