// ** React Imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { clearContent, contentSelector } from "src/store/contentSlice";
import { useMutation } from "react-query";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Controller Imports
import ManagerController from "src/controller/ManagerController";

// ** Widget Imports
import {
  Box,
  Container,
  Stack,
  Button,
  TextField,
  Typography,
  Grid,
  InputLabel
} from "@mui/material";
import { TitledPaper } from "src/widgets";

// Util Imports
import {
  API_RESULT,
  API_RESULT_ACTION,
  CONTENT_MODE
} from "src/constant/commonCode";

export default function ManagerDetail() {
  // ** Hook
  const toast = useToast();
  const navigate = useNavigate();

  // ** Store
  const dispatch = useDispatch();
  const currentContent = useSelector(contentSelector).currentContent;

  // ** State
  const [mode, setMode] = useState(CONTENT_MODE.NEW);
  const [formValues, setFormValues] = useState({
    adminAccountId: "",
    adminAccount: "",
    adminPw: "",
    adminStatus: "",
    adminNickName: "",
    adminActivate: "",
    adminMobileNumber: "",
    adminRole: "",
    useYn: "Y"
  });
  const {
    adminAccountId,
    adminAccount,
    adminPw,
    adminStatus,
    adminNickName,
    adminActivate,
    adminMobileNumber,
    adminRole,
    useYn
  } = formValues;

  // 신규 1개 아이템 추가
  const { mutate: AddItem } = useMutation({
    mutationFn: ManagerController.addStoreItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.ADD);

      // 상세 페이지 떠나기 전 store 비우기
      dispatch(clearContent());

      // 목록으로 이동
      navigate("/manager");
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 정보 수정
  const { mutate: EditItem } = useMutation({
    mutationFn: ManagerController.editStoreItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.ADD);

      // 상세 페이지 떠나기 전 store 비우기
      dispatch(clearContent());

      // 목록으로 이동
      navigate("/manager");
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 삭제
  const { mutate: DeleteItem } = useMutation({
    mutationFn: ManagerController.deleteStoreItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.DELETE);

      // 상세 페이지 떠나기 전 store 비우기
      dispatch(clearContent());

      // 목록으로 이동
      navigate("/manager");
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // Input field handler
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // 사용 일자
  const handleChangeViewDate = (newValue) => {
    setFormValues((prev) => ({ ...prev, viewDate: newValue }));
  };

  // 아이템 삭제
  const handleClickDelete = () => {
    // 선택된 아이템 여부 확인
    DeleteItem(12);
  };

  // Save form data
  const handleSubmit = (event) => {
    event.preventDefault();

    if (mode == CONTENT_MODE.NEW) {
      // 신규 추가
      AddItem(formValues);
    } else {
      // 수정 모드
      EditItem(formValues);
    }
  };

  // Redux에 currentContent가 있다면 수정모드
  const handleUpdateContent = () => {
    // 읽기 모드 셋팅
    setMode(CONTENT_MODE.EDIT);

    // 컨텐츠 업데이트
    const {
      adminAccountId,
      adminAccount,
      adminStatus,
      adminNickName,
      adminActivate,
      adminMobileNumber,
      adminRole,
      useYn
    } = currentContent;

    setFormValues((prev) => ({
      ...prev,
      adminAccountId: adminAccountId,
      adminAccount: adminAccount,
      adminStatus: adminStatus,
      adminNickName: adminNickName,
      adminActivate: adminActivate,
      adminMobileNumber: adminMobileNumber,
      adminRole: adminRole,
      useYn: useYn
    }));
  };

  useEffect(() => {
    // currentContent가 있다면 데이터 셋팅
    Object.keys(currentContent).length > 0 && handleUpdateContent();
  }, [currentContent]);

  return (
    <TitledPaper title={`매니저 ${mode == CONTENT_MODE.NEW ? "신규" : "상세"}`}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}
      >
        <Container maxWidth="lg">
          <form method="post" onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack spacing={1}>
                <Typography variant="h5">매니저 정보</Typography>
                <Grid container spacing={1}>
                  {/* 계정 ID */}
                  <Grid item xs={2}>
                    <InputLabel required>계정 ID</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    {mode === CONTENT_MODE.VIEW ? (
                      <Typography variant="body">{adminAccountId}</Typography>
                    ) : (
                      <TextField
                        fullWidth
                        name="adminAccountId"
                        value={adminAccountId}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </Grid>
                  {/* 로그인 ID */}
                  <Grid item xs={2}>
                    <InputLabel required>로그인 ID</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    {mode === CONTENT_MODE.VIEW ? (
                      <Typography variant="body">{adminAccount}</Typography>
                    ) : (
                      <TextField
                        fullWidth
                        name="adminAccount"
                        value={adminAccount}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </Grid>
                  {/* 계정 상태 */}
                  <Grid item xs={2}>
                    <InputLabel required>계정 상태</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    {mode === CONTENT_MODE.VIEW ? (
                      <Typography variant="body">{adminStatus}</Typography>
                    ) : (
                      <TextField
                        fullWidth
                        name="adminStatus"
                        value={adminStatus}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </Grid>
                  {/* 사용자 명 */}
                  <Grid item xs={2}>
                    <InputLabel required>사용자 명</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    {mode === CONTENT_MODE.VIEW ? (
                      <Typography variant="body">{adminNickName}</Typography>
                    ) : (
                      <TextField
                        fullWidth
                        name="adminNickName"
                        value={adminNickName}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </Grid>
                  {/* adminActivate */}
                  <Grid item xs={2}>
                    <InputLabel required>adminActivate</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    {mode === CONTENT_MODE.VIEW ? (
                      <Typography variant="body">{adminActivate}</Typography>
                    ) : (
                      <TextField
                        fullWidth
                        name="adminActivate"
                        value={adminActivate}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </Grid>
                  {/* 직급 */}
                  <Grid item xs={2}>
                    <InputLabel required>직급</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    {mode === CONTENT_MODE.VIEW ? (
                      <Typography variant="body">{adminRole}</Typography>
                    ) : (
                      <TextField
                        fullWidth
                        name="adminRole"
                        value={adminRole}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </Grid>
                  {/* 휴대폰 번호 */}
                  <Grid item xs={2}>
                    <InputLabel>휴대폰 번호</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    {mode === CONTENT_MODE.VIEW ? (
                      <Typography variant="body">
                        {adminMobileNumber}
                      </Typography>
                    ) : (
                      <TextField
                        fullWidth
                        name="adminMobileNumber"
                        value={adminMobileNumber}
                        onChange={handleChange}
                      />
                    )}
                  </Grid>
                  {/* 휴대폰 번호 */}
                  <Grid item xs={2}>
                    <InputLabel required>사용 유무</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    {mode === CONTENT_MODE.VIEW ? (
                      <Typography variant="body">{useYn}</Typography>
                    ) : (
                      <TextField
                        select
                        SelectProps={{ native: true }}
                        name="useYn"
                        value={useYn}
                        onChange={handleChange}
                        required
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </TextField>
                    )}
                  </Grid>
                </Grid>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="h5">비밀번호 변경</Typography>
                <Grid container>
                  {/* 현재 비밀번호 */}
                  <Grid item xs={2}>
                    <InputLabel required>현재 비밀번호</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      name="adminPw"
                      value={adminPw}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  {/* 새 비밀번호 */}
                  <Grid item xs={2}>
                    <InputLabel required>새 비밀번호</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      name="adminPw"
                      value={adminPw}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                  {/* 새 비밀번호 확인 */}
                  <Grid item xs={2}>
                    <InputLabel required>새 비밀번호 확인</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      name="adminPw"
                      value={adminPw}
                      onChange={handleChange}
                      required
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "8px"
                }}
              >
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleClickDelete}
                >
                  삭제
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  저장
                </Button>
              </Box>
            </Stack>
          </form>
        </Container>
      </Box>
    </TitledPaper>
  );
}
