import React from "react";
import { List, Paper, Stack, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { ChatWaitingCard } from "src/components/widgets/Card";
import BttmNavbar from "src/components/widgets/BttmNavbar";
import rowData from "src/__mocks__/chatWaitingList";

const ChatWaiting = () => {
  return (
    <Paper elevation={0}>
      <Appbar tab my />
      <Stack spacing={2}>
        <Stack>
          <Typography variant="subtitle1">대화 가능</Typography>
          <List>
            {rowData.map((item, index) => (
              <ChatWaitingCard key={index} user={item} />
            ))}
          </List>
        </Stack>
        <Stack>
          <Typography variant="subtitle1">대기 중</Typography>
          <List>
            {rowData.map((item, index) => (
              <ChatWaitingCard key={index} user={item} />
            ))}
          </List>
        </Stack>
      </Stack>
      <BttmNavbar />
    </Paper>
  );
};

export default ChatWaiting;
