import React from "react";
import { Chip, IconButton, Stack, Typography } from "@mui/material";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { CONTENT_MODE } from "src/constant/commonCode";

const BasicInfo = (props) => {
  const { user, admin, mode } = props;
  const {
    email,
    phone,
    meetPreferName,
    gender,
    birthYear,
    age,
    height,
    job,
    company,
    graduation,
    university,
    regionNm,
    bloodType,
    mbti,
    religion,
    smoking,
    drinking
  } = user;
  return (
    <Stack>
      <Typography variant="subtitle1">기본 정보</Typography>
      {admin && (
        <>
          <Stack direction="row" spacing={3}>
            <Typography variant="subtitle2">이메일</Typography>
            <Typography variant="subtitle2">{email}</Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography variant="subtitle2">Phone</Typography>
            <Typography variant="subtitle2">{phone}</Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography variant="subtitle2">선호만남</Typography>
            <Typography variant="subtitle2">{meetPreferName}</Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography variant="subtitle2">성별</Typography>
            <Typography variant="subtitle2">{gender}</Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <Typography variant="subtitle2">년도</Typography>
            <Typography variant="subtitle2">
              {birthYear}({age})
            </Typography>
          </Stack>
        </>
      )}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">지역</Typography>
          <Typography variant="subtitle2">{regionNm}</Typography>
        </Stack>
        {mode === CONTENT_MODE.EDIT && (
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">키</Typography>
          <Typography variant="subtitle2">{height}cm</Typography>
        </Stack>
        {mode === CONTENT_MODE.EDIT && (
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">직장</Typography>
          <Typography variant="subtitle2">{company ?? "-"}</Typography>
        </Stack>
        {company ? (
          <Chip
            color="primary"
            size="small"
            variant="outlined"
            label="인증완료"
          />
        ) : (
          mode === CONTENT_MODE.EDIT && (
            <IconButton size="small">
              <ChevronRightIcon />
            </IconButton>
          )
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">직업</Typography>
          <Typography variant="subtitle2">{job ?? "-"}</Typography>
        </Stack>
        {mode === CONTENT_MODE.EDIT && (
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">학력</Typography>
          <Typography variant="subtitle2">{graduation ?? "-"}</Typography>
        </Stack>
        {mode === CONTENT_MODE.EDIT && (
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">학교</Typography>
          <Typography variant="subtitle2">{university ?? "-"}</Typography>
        </Stack>
        {/* 인증이 안 된 경우에만 수정 가능 */}
        {!company ? (
          <Chip
            color="primary"
            size="small"
            variant="outlined"
            label="인증완료"
          />
        ) : (
          mode === CONTENT_MODE.EDIT && (
            <IconButton size="small">
              <ChevronRightIcon />
            </IconButton>
          )
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">혈액형</Typography>
          <Typography variant="subtitle2">{bloodType ?? "-"}</Typography>
        </Stack>
        {mode === CONTENT_MODE.EDIT && (
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">MBTI</Typography>
          <Typography variant="subtitle2">{mbti ?? "-"}</Typography>
        </Stack>
        {mode === CONTENT_MODE.EDIT && (
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">종교</Typography>
          <Typography variant="subtitle2">{religion ?? "-"}</Typography>
        </Stack>
        {mode === CONTENT_MODE.EDIT && (
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">흡연</Typography>
          <Typography variant="subtitle2">{smoking ?? "-"}</Typography>
        </Stack>
        {mode === CONTENT_MODE.EDIT && (
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="subtitle2">음주</Typography>
          <Typography variant="subtitle2">{drinking ?? "-"}</Typography>
        </Stack>
        {mode === CONTENT_MODE.EDIT && (
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default BasicInfo;
