import React from "react";
import { Button, Divider, List, Paper, Stack } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { BasicMessageCard, LikeMessageCard } from "src/components/widgets/Card";
import BttmNavbar from "src/components/widgets/BttmNavbar";

const SendedMessageList = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="Message" my />
      <Stack spacing={2}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Button href="/app/message/recieve">받은 쪽지</Button>
          <Button href="/app/message/send">
            <b>보낸 쪽지</b>
          </Button>
        </Stack>
        <List>
          <LikeMessageCard />
          <BasicMessageCard />
          <BasicMessageCard />
          <BasicMessageCard />
          <BasicMessageCard />
          <BasicMessageCard />
          <BasicMessageCard />
        </List>
      </Stack>
      <BttmNavbar />
    </Paper>
  );
};

export default SendedMessageList;
