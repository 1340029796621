import { v4 as uuid } from "uuid";

export default {
  status: "01",
  partner: {
    id: uuid(),
    socialType: "카카오",
    avatar: "01",
    phone: "010-2222-3333",
    email: "tankova@devias.io",
    meetingTypeCode: "04",
    meetingTypeName: "주말맥주",
    nickName: "대전강아지",
    gender: "남",
    birthYear: "1991",
    age: "32",
    height: 187,
    location: "서울",
    job: "경영",
    company: "네이버",
    degree: "4년제",
    school: "한양대학교",
    bloodType: "A",
    mbti: "INTJ",
    religion: "무교",
    smoking: "안해요",
    drinking: "안해요",
    bio: "술, 담배 안하구요. 한사람만 바라봅니다. 결혼준비도 되었다고 생각합니다 화목한 가정 이루는게 꿈입니다",
    characteristic: ["강아지상", "깨끗한 피부", "귀여운 보조개"],
    mysellf: ["지적인", "유머있는", "신중한", "예의가 바른", "눈치가 빠른"],
    hobby: ["영화감상", "음악 감상", "책 읽기"],
    userStatusCode: "01",
    userStatusName: "정상",
    idVerification: 1555016400000,
    companyVerification: 1555016400000,
    schoolVerification: null,
    lastLoginedAt: 1555016400000,
    createdAt: 1555016400000,
    updatedAt: 1555016400000
  }
};
