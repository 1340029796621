import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";
import { createDynamicPath } from "src/utils/dataConverter";

class ContentService {
  /*
   * 컨텐츠 목록 조회
   * categoryCd 메인 카테고리
   * categorySubCd 서브 카테고리
   */
  selectContent(params) {
    // Creating path
    const basePath = "/admin-noti";
    const dynamicPath = createDynamicPath(basePath, params);
    return axiosGet(dynamicPath).then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.adminNotiId
        };
      });
    });
  }

  /*
   * 게시글 1건 신규 추가
   * payload
   * categoryCd 메인 카테고리
   * categorySubCd 서브 카테고리
   * notiTitle 제목
   * notiContent 내용
   * upfile [File, File], 데이터 없는 경우 null 필수
   * viewDate 2024-04-30, 데이터 없는 경우 null 필수
   * startDate 2024-04-30, 데이터 없는 경우 null 필수
   * endDate 2024-04-30, 데이터 없는 경우 null 필수
   * closeYn 대표 노출 사용 여부 Y/N
   * useYn 사용 여부 Y/N
   * bool isFormData true/false
   */
  insertContent(payload, isFormData) {
    return axiosPost("/admin-noti", payload, isFormData).then((response) => {
      return response; // {affectedRows: 1, insertId: '15', warningStatus: 0}
    });
  }

  /*
   * 게시글 1건 수정
   * payload
   * categoryCd 메인 카테고리
   * categorySubCd 서브 카테고리
   * notiTitle 제목
   * notiContent 내용
   * upfile [File, File], 데이터 없는 경우 null 필수
   * viewDate 2024-04-30, 데이터 없는 경우 null 필수
   * startDate 2024-04-30, 데이터 없는 경우 null 필수
   * endDate 2024-04-30, 데이터 없는 경우 null 필수
   * closeYn 대표 노출 사용 여부 Y/N
   * useYn 사용 여부 Y/N
   * bool isFormData true/false
   */
  updateContent(payload, isFormData) {
    return axiosPut("/admin-noti", payload, isFormData).then((response) => {
      return response; // {affectedRows: 1, insertId: '15', warningStatus: 0}
    });
  }

  /*
   * 게시글 1건 삭제
   * adminNotiId 게시글 id
   */
  deleteContent(adminNotiId) {
    return axiosDelete(`/admin-noti/${adminNotiId}`).then((response) => {
      return response; // {affectedRows: 1, insertId: '15', warningStatus: 0}
    });
  }
}

export default new ContentService();
