import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import moment from "moment";
import coinHistoryList from "src/__mocks__/coinHistoryList";

const renderLine = ({ createdAt, title, increase, total }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      pl={2}
      pr={2}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Typography variant="body2">{createdAt}</Typography>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body2">{increase}</Typography>
      <Typography variant="body2">{total}</Typography>
    </Stack>
  );
};

const CoinHistory = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="이용 내역" back />
      <Stack spacing={2}>
        <Stack alignItems="center">
          <Typography variant="h6">150</Typography>
          <Typography variant="caption">보유 중</Typography>
        </Stack>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemText
              primary={renderLine({
                createdAt: "날짜",
                title: "내역",
                increase: "증감",
                total: "누적"
              })}
            />
          </ListItem>
          {coinHistoryList.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemText
                primary={renderLine({
                  createdAt: moment(item.createdAt).format("YYYY/MM/DD"),
                  title: item.title,
                  increase: item.increase,
                  total: item.total
                })}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
    </Paper>
  );
};

export default CoinHistory;
