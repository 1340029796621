// ** React Imports
import React, { useState } from "react";

// ** Redux Imports
import { useQuery, useMutation, useQueryClient } from "react-query";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Redux Imports
import CareerController from "src/controller/CareerController";

// ** Widget Imports
import { Box, Button } from "@mui/material";
import { GridContainer } from "src/components";
import {
  API_RESULT,
  API_RESULT_ACTION,
  CAREER_TYPE
} from "src/constant/commonCode";

// 수정 가능한 cell bg color
const editableCellClassName = (params) => {
  const cellClassName =
    params.isEditable == true || params.cellMode == "edit"
      ? "editable--column"
      : null;

  return cellClassName;
};

export default function CareerWaitingList() {
  // ** Hook
  const toast = useToast();
  const queryClient = useQueryClient();

  // ** State
  // Search conditions
  const [searchConditions, setSearchConditions] = useState({
    authYn: "N",
    useYn: "Y",
    companyGb: null,
    companyEmail: null,
    searchTxt: null
  });

  // ** Fetch
  // 회사 목록 조회
  const { data: companyRowData } = useQuery("newCompanyList", () =>
    CareerController.getCareerList(CAREER_TYPE.COMPANY, searchConditions)
  );

  // 학교 목록 조회
  const { data: universityRowData } = useQuery("newUniversityList", () =>
    CareerController.getCareerList(CAREER_TYPE.UNIVERSITY, searchConditions)
  );

  // 1개 아이템 정보 수정
  const { mutate: EditItem } = useMutation({
    mutationFn: CareerController.editCareer,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.EDIT);
      queryClient.invalidateQueries({ queryKey: "newCompanyList" });
      queryClient.invalidateQueries({ queryKey: "newUniversityList" });
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 아이템 수정 후 저장
  const handleClickConfirm = (careerType, row) => {
    EditItem({ careerType: careerType, params: row });
  };

  const columns = [
    { field: "id", headerName: "ID", type: "number", width: 80 },
    { field: "careerCd", headerName: "Career 코드", width: 100 },
    {
      field: "careerEmail",
      headerName: "이메일/도메인",
      width: 200
    },
    {
      field: "careerName",
      headerName: "국문 명",
      editable: true,
      width: 200,
      cellClassName: editableCellClassName
    }
  ];

  const companyColumns = [
    ...columns,
    {
      field: "careerOriginalName",
      headerName: "실제 국문 명",
      editable: true,
      cellClassName: editableCellClassName
    },
    {
      field: "careerNameEn",
      headerName: "영문 명",
      editable: true,
      cellClassName: editableCellClassName
    },
    {
      field: "careerGb",
      headerName: "구분",
      width: 80,
      valueGetter: ({ value }) =>
        value != null && value == "C" ? "사기업" : "공기업"
    },
    {
      field: "authYn",
      type: "boolean",
      headerName: "확인 여부",
      width: 80
    },
    {
      field: "useYn",
      type: "boolean",
      headerName: "사용 여부",
      width: 80
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      width: 100,
      getActions: (params) => {
        return [
          <Button
            key="action-confirm"
            variant="contained"
            onClick={() => handleClickConfirm(CAREER_TYPE.COMPANY, params.row)}
          >
            승인
          </Button>
        ];
      }
    }
  ];

  const universityColumns = [
    ...columns,
    {
      field: "authYn",
      type: "boolean",
      headerName: "확인 여부",
      width: 80
    },
    {
      field: "useYn",
      type: "boolean",
      headerName: "사용 여부",
      width: 80
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      width: 100,
      getActions: (params) => {
        return [
          <Button
            key="action-confirm"
            variant="contained"
            onClick={() =>
              handleClickConfirm(CAREER_TYPE.UNIVERSITY, params.row)
            }
          >
            승인
          </Button>
        ];
      }
    }
  ];

  return (
    <Box
      sx={{
        "& .editable--column": {
          backgroundColor: "rgba(255, 7, 0, 0.55)"
        }
      }}
    >
      <Box sx={{ pt: 3 }}>
        <span>신규 회사</span>
        <GridContainer
          height={300}
          columns={companyColumns}
          rowData={companyRowData || []}
        />
      </Box>
      <Box sx={{ pt: 3 }}>
        <span>신규 학교</span>
        <GridContainer
          height={300}
          columns={universityColumns}
          rowData={universityRowData || []}
        />
      </Box>
    </Box>
  );
}
