import { API_RESULT } from "src/constant/commonCode";
import UserService from "src/services/UserService";

class UserController {
  /*
   * 유저 목록 조회
   * userStatus 계정 상태
   * snsNameId 가입한 소셜
   * careerGb 이메일 인증 타입
   * gender 성별
   * meetPreferCd 선호 만남 타입
   * regionCd 지역
   * days 가입한 날짜
   * uAccountId 유저 ID
   * userNickName 유저 닉네임
   * pageNumber 현재 페이지 숫자
   * pageSize 한 페이지당 갯수
   */
  getUserProfiles(params, pagination) {
    const payload = {
      userStatus: params.userStatus || null,
      snsNameId: params.snsNameId || null, // 가입한 소셜
      careerGb: params.careerGb || null, // 이메일 인증 타입
      gender: params.gender || null, // 성별
      meetPreferCd: params.meetPreferCd || null, // 선호 만남 타입
      regionCd: params.regionCd || null, // 지역
      days: params.days || null, // 가입한 날짜
      uAccountId: params.uAccountId || null, // 유저 ID
      userNickName: params.userNickName || null, // 유저 닉네임
      pageNumber: pagination.page || 1, // 현재 페이지 숫자
      pageSize: pagination.pageSize || 10 // 한 페이지당 갯수
    };
    return UserService.selectUserProfiles(payload).then((res) => {
      return res;
    });
  }

  /*
   * 유저 계정 정보 변경 및 push notification
   * uAccountId user id
   * userStatus 계정 상태
   * messageType Push Notification type(01, 02...)
   */
  changeUserStatus(params) {
    const payload = {
      uAccountId: params.uAccountId || null,
      userStatus: params.userStatus || null,
      messageType: params.messageType || null
    };
    return new Promise((resolve, reject) => {
      UserService.updateUserStatus(payload).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 유저 정보 1건 업데이트
   */
  editUserInfo(params) {
    const payload = {
      uAccountId: params.uAccountId || null,
      userStatus: params.userStatus || null,
      userNickName: params.userNickName || null,
      userBirthYear: params.userBirthYear || null,
      userAge: params.userAge || null,
      gender: params.gender || null,
      userActivate: params.userActivate || null,
      userMobileNumber: params.userMobileNumber || null,
      userRole: params.userRole || null,
      avatarId: params.avatarId || null,
      useYn: params.useYn || null,
      userLoginToken: params.userLoginToken || null,
      userFcmToken: params.userFcmToken || null
    };
    return new Promise((resolve, reject) => {
      UserService.updateUserInfo(payload).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }
}

export default new UserController();
