import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  TextField
} from "@mui/material";
import { TitledPaper } from "src/widgets";
import { MuiDataGrid } from "src/components/@core";
import { Search as SearchIcon } from "react-feather";

import rowData from "src/__mocks__/pointHistory";
import moment from "moment";

const columns = [
  { field: "id", headerName: "No", type: "number", width: 70 },
  {
    field: "createdAt",
    headerName: "결제일자",
    sortable: true,
    width: 180,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("YYYY/MM/DD")}`
  },
  { field: "type", headerName: "유형", width: 200 },
  {
    field: "subType",
    headerName: "항목",
    width: 300
  },
  {
    field: "nickName",
    headerName: "닉네임",
    width: 120
  },
  {
    field: "amount",
    headerName: "금액(원)",
    width: 120
  },
  {
    field: "point",
    headerName: "+/-",
    width: 120
  },
  {
    field: "status",
    headerName: "상태",
    width: 120
  }
];

export default function CoinList() {
  const navigate = useNavigate();

  const handleRowClick = (params) => {
    navigate(`/coin/${params.id}`);
  };
  return (
    <TitledPaper title="풍선 내역">
      <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="simple-select-label">유형</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              label="Status"
              defaultValue={0}
            >
              <MenuItem value={0}>전체</MenuItem>
              <MenuItem value={10}>결제</MenuItem>
              <MenuItem value={20}>포인트</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="simple-select-label">상태</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              label="Status"
              defaultValue={0}
            >
              <MenuItem value={0}>전체</MenuItem>
              <MenuItem value={10}>결제 완료</MenuItem>
              <MenuItem value={20}>환불</MenuItem>
              <MenuItem value={20}>적립</MenuItem>
              <MenuItem value={20}>환수</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="닉네임"
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <Button color="primary" variant="contained">
            검색
          </Button>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <MuiDataGrid
          columns={columns}
          rowData={rowData}
          onRowClick={handleRowClick}
        />
      </Box>
    </TitledPaper>
  );
}
