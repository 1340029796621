import React from "react";

import { Paper, Stack } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const Privacy = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="Privacy" back />
      <Stack>Body</Stack>
    </Paper>
  );
};

export default Privacy;
