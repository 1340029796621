import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Tab, Tabs, Typography } from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  Dehaze as DehazeIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon
} from "@mui/icons-material";
import { User as UserIcon } from "react-feather";

const Appbar = (props) => {
  const {
    title,
    back,
    tab,
    my,
    chat,
    setting,
    notification,
    myProfile,
    edit,
    onEdit
  } = props;
  const navigate = useNavigate();

  const handleSetEdit = () => {
    onEdit();
  };

  return (
    <Box display="flex">
      {back && (
        <IconButton onClick={() => navigate(-1)} sx={{ margin: 1 }}>
          <ChevronLeftIcon />
        </IconButton>
      )}
      {!tab ? (
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ p: 1, pb: 0 }}
        >
          {title}
        </Typography>
      ) : (
        <Tabs>
          <Tab label="대화" href="/app/chat/loabie" />
          <Tab label="대기중" href="/app/chat/waiting" />
        </Tabs>
      )}
      <Box position="fixed" top={0} right={0}>
        {/* 메인 페이지가 아닐 경우 마이페이지 이동 버튼 있음 */}
        {my && (
          <IconButton href="/app/my" sx={{ margin: 1 }}>
            <UserIcon />
          </IconButton>
        )}
        {/* My Profile일 경우, 수정 하기 기능 있음 */}
        {myProfile && (
          <Button size="small" variant="outlined" onClick={handleSetEdit}>
            {edit ? "완료" : "수정하기"}
          </Button>
        )}
        {chat && (
          <IconButton sx={{ margin: 1 }}>
            <DehazeIcon />
          </IconButton>
        )}
        {setting && (
          <IconButton href="/app/setting">
            <SettingsIcon />
          </IconButton>
        )}
        {notification && (
          <IconButton href="/app/my/notification" sx={{ margin: 1 }}>
            <NotificationsIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default Appbar;
