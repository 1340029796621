import React from "react";
import { Helmet } from "react-helmet";

import { Box, Container, Grid, Paper } from "@mui/material";
import { DisplayCard } from "src/widgets";

const Dashboard = () => (
  <>
    <Helmet>
      <title>Dashboard | uBlooming Admin</title>
    </Helmet>
    <Box
      component="main"
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {/* 가입중 유저 */}
          <Grid item xs={12} md={4} lg={4}>
            <DisplayCard
              title="가입 대기중 유저"
              subtitle="3명"
              link="/user/waiting"
            />
          </Grid>
          {/* Item 2 */}
          <Grid item xs={12} md={4} lg={4}>
            <DisplayCard
              title="오늘 가입한 유저"
              subtitle="13명"
              link="/user/all"
            />
          </Grid>
          {/* Item 3 */}
          <Grid item xs={12} md={4} lg={4}>
            <DisplayCard
              title="오늘 탈퇴한 유저"
              subtitle="6명"
              link="/user/all"
            />
          </Grid>
          {/* Item 4 */}
          <Grid item xs={12}>
            <Paper>
              <Box color="primary" />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default Dashboard;
