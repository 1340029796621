// ** React Imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { clearContent, contentSelector } from "src/store/contentSlice";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Controller Imports
import ContentController from "src/controller/ContentController";

// ** Widget Imports
import {
  Box,
  Container,
  Stack,
  Typography,
  Grid,
  Button,
  TextField,
  CardContent,
  Card
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

// Util Imports
import dayjs from "dayjs";
import {
  API_RESULT,
  CONTENT_MODE,
  CONTENT_TYPE
} from "src/constant/commonCode";
import { stringToDate } from "src/utils/dateFormatter";
import ReactQuill from "react-quill";

export default function PrivacyEdit() {
  // ** Hook
  const toast = useToast();
  const navigate = useNavigate();

  // ** Store
  const dispatch = useDispatch();
  const currentContent = useSelector(contentSelector).currentContent;

  // ** State
  const [mode, setMode] = useState(CONTENT_MODE.NEW);
  const [formValues, setFormValues] = useState({
    categoryCd: CONTENT_TYPE.PRIVACY,
    categorySubCd: "99",
    adminNotiId: null,
    notiTitle: "",
    notiContent: "",
    viewDate: dayjs(new Date()),
    useYn: "Y"
  });
  const { notiTitle, notiContent, viewDate, useYn } = formValues;

  // Input field handler
  const handleChange = (event) => {
    // 스마트 에디터인 경우 확인. 스마트 에디터는 event data가 없어서 name 지정 필요
    const isReactQuill = !event.target?.name ?? false;
    const name = isReactQuill ? "notiContent" : event.target.name;
    const value = isReactQuill ? event : event.target.value;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // 사용 일자
  const handleChangeViewDate = (newValue) => {
    setFormValues((prev) => ({ ...prev, viewDate: newValue }));
  };

  // Save form data
  // 수정 모드
  const handleSubmit = (event) => {
    event.preventDefault();

    if (mode == CONTENT_MODE.NEW) {
      // 신규 추가
      ContentController.addContent(formValues).then((res) => {
        if (res == API_RESULT.SUCCESS) {
          toast.success("추가 되었습니다.");

          // 상세 페이지 떠나기 전 store 비우기
          dispatch(clearContent());
          // 목록으로 이동
          navigate("/privacy");
        }
      });
    } else {
      // 수정 모드
      ContentController.editContent(formValues).then((res) => {
        if (res == API_RESULT.SUCCESS) {
          toast.success("수정 되었습니다.");

          // 상세 페이지 떠나기 전 store 비우기
          dispatch(clearContent());

          // 목록으로 이동
          navigate("/privacy");
        }
      });
    }
  };

  // Redux에 currentContent가 있다면 수정모드
  const handleUpdateContent = () => {
    // 읽기 모드 셋팅
    setMode(CONTENT_MODE.EDIT);

    // 컨텐츠 업데이트
    const { adminNotiId, notiTitle, notiContent, viewDate, useYn } =
      currentContent;

    setFormValues((prev) => ({
      ...prev,
      adminNotiId: adminNotiId,
      notiTitle: notiTitle,
      notiContent: notiContent,
      viewDate: stringToDate(viewDate),
      useYn: useYn
    }));
  };

  useEffect(() => {
    // currentContent 데이터가 있다면 데이터 셋팅
    Object.keys(currentContent).length > 0 && handleUpdateContent();
  }, [currentContent]);

  return (
    <>
      <Helmet>
        <title>개인정보 취급방침 | uBlooming Admin</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">
                개인정보 취급방침 {mode == CONTENT_MODE.NEW ? "신규" : "수정"}
              </Typography>
            </div>
            <div>
              <form method="post" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent sx={{ pt: 0 }}>
                        <Box sx={{ m: 1.5 }}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="제목"
                                name="notiTitle"
                                value={notiTitle}
                                onChange={handleChange}
                                required
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <ReactQuill
                                style={{
                                  whiteSpace: "pre-wrap",
                                  height: "300px",
                                  marginBottom: "33px"
                                }}
                                theme="snow"
                                name="notiContent"
                                value={notiContent}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <DatePicker
                                label="사용일자"
                                name="viewDate"
                                value={viewDate}
                                onChange={handleChangeViewDate}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                label="사용"
                                name="useYn"
                                value={useYn}
                                onChange={handleChange}
                                required
                              >
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                              </TextField>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "8px"
                      }}
                    >
                      <Button color="primary" variant="contained" type="submit">
                        저장
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
