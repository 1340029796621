import React from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const BlockContact = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="지인 차단" back />
      <Stack height="92vh" justifyContent="space-between" ml={2} mr={2}>
        <Stack spacing={2}>
          <Typography variant="h6">
            지인 차단을 위해 연락처를 업로드해주세요
          </Typography>
          <Typography variant="subtitle2">등록된 연락처가 없습니다.</Typography>
          <List
            dense
            sx={{
              listStyleType: "disc",
              pl: 2,
              "& .MuiListItem-root": {
                display: "list-item"
              }
            }}
          >
            <ListItem disablePadding>
              <ListItemText primary="연락처 정보를 갱신하시고 안전하게 지인을 차단해보세요!" />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary="연락처를 모두 삭제 하고 싶으신 경우 초기화를 눌러주세요." />
            </ListItem>
          </List>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button fullWidth size="large" color="secondary" variant="outlined">
            초기화
          </Button>
          <Button fullWidth size="large" variant="outlined">
            차단하기
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default BlockContact;
