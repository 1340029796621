import React from "react";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const StepHeight = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="프로필 생성" back />
      <Stack height="92vh" justifyContent="space-between">
        <Stack>
          <Typography variant="h6">키를 입력해주세요</Typography>
          <TextField
            fullWidth
            variant="standard"
            type="number"
            inputProps={{
              minLength: 3,
              maxLength: 3,
              form: {
                autoComplete: "off"
              }
            }}
            label="키"
            placeholder="166"
          />
        </Stack>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          href="/app/join/location"
        >
          다음
        </Button>
      </Stack>
    </Paper>
  );
};

export default StepHeight;
