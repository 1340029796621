import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";
import { createDynamicPath } from "src/utils/dataConverter";

class GroupCodeService {
  /*
   * 그룹 코드 목록 조회
   * useYn 사용 여부
   */
  selectGroupCodeList(params) {
    // Creating path
    const basePath = "/code/group-code";
    const dynamicPath = createDynamicPath(basePath, params);

    return axiosGet(dynamicPath).then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.commonGroupCodeId
        };
      });
    });
  }

  /*
   * 그룹 코드 1건 신규 추가
   * String groupCode 그룹 코드
   * String groupName 그룹 코드 명
   * String groupCodeDesc 그룹 코드 설명
   * String useYn 사용 여부 Y/N
   */
  insertGroupCode(payload) {
    return axiosPost("/group-code", payload).then((response) => {
      return response;
    });
  }

  /*
   * 그룹 코드 1건 수정
   * commonGroupCodeId 그룹 코드 ID
   * String groupCode 그룹 코드
   * String groupName 그룹 코드 명
   * String groupCodeDesc 그룹 코드 설명
   * String useYn 사용 여부 Y/N
   */
  updateGroupCode(payload) {
    return axiosPut("/group-code", payload).then((response) => {
      return response;
    });
  }

  /*
   * 그룹 코드 1건 삭제
   * commonGroupCodeId 그룹 코드 id
   */
  deleteGroupCode(commonGroupCodeId) {
    return axiosDelete(`/group-code/${commonGroupCodeId}`).then((response) => {
      return response;
    });
  }
}

export default new GroupCodeService();
