import React from "react";
import { ToastContext } from "./ToastContext";

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { SHOW_TOAST, toastSelector } from "src/store/toastSlice";
import { Toast } from "src/components";

export const ToastContextProvider = ({ children }) => {
  // ** Store
  const dispatch = useDispatch();
  const toast = useSelector(toastSelector).toast;

  const showToast = (type, message) => {
    dispatch(SHOW_TOAST({ type, message }));
  };

  const success = (message) => {
    showToast("success", message);
  };

  const warning = (message) => {
    showToast("warning", message);
  };

  const info = (message) => {
    showToast("info", message);
  };

  const error = (message) => {
    showToast("error", message);
  };

  const value = { showToast, success, warning, info, error };

  return (
    <ToastContext.Provider value={value}>
      <Toast toast={toast} />
      {children}
    </ToastContext.Provider>
  );
};
