import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";

class AvatarService {
  /*
   * 아바타 목록 조회
   * avatarGender 아바타 성별(M, F)
   */
  avatarList(avatarGender) {
    return axiosGet(`/avatar/${avatarGender}`).then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.avatarInfoId
        };
      });
    });
  }

  /*
   * 아바타 1건 신규 추가
   * avatarId 아바타 코드
   * avatarName 아바타 파일 명
   * avatarGender 아바타 성별(M, F)
   */
  insertAvatar(payload) {
    return axiosPost("/avatar", payload).then((response) => {
      return response;
    });
  }

  /*
   * 아바타 1건 수정
   * avatarInfoId 아바타 ID
   * avatarId 아바타 코드
   * avatarName 아바타 파일 명
   * avatarGender 아바타 성별(M, F)
   */
  updateAvatar(payload) {
    return axiosPut("/avatar", payload).then((response) => {
      return response;
    });
  }

  /*
   * 아바타 1건 삭제
   * avatarInfoId 아바타 ID
   */
  deleteAvatar(avatarInfoId) {
    return axiosDelete(`/avatar/${avatarInfoId}`).then((response) => {
      return response;
    });
  }
}

export default new AvatarService();
