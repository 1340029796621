import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

export default function MbtiInfoFormDialog(props) {
  const { open, data, onClose, onConfirm } = props;
  const [formData, setFormData] = useState({
    mbtiInfoId: "",
    mbtiType: "",
    mbtiTypeMatch: "",
    matchRate: ""
  });
  const { mbtiInfoId, mbtiType, mbtiTypeMatch, matchRate } = formData;

  // Input field handler
  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // 수정일 경우 Form Data 업데이트
  const handleSetFormData = (newFormData) => {
    setFormData(newFormData);
  };

  const handleClose = () => {
    // Form Data 초기화
    handleSetFormData({
      mbtiInfoId: "",
      mbtiType: "",
      mbtiTypeMatch: "",
      mbtiRate: ""
    });

    // Dialog 닫기
    onClose && onClose();
  };

  // 유입되는 데이터 확인
  useEffect(() => {
    data && handleSetFormData(data);
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          onConfirm(formData);
        }
      }}
    >
      <DialogTitle>MBTI 매칭 정보 상세</DialogTitle>
      <DialogContent>
        {mbtiInfoId != "" && (
          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            id="mbtiInfoId"
            name="mbtiInfoId"
            label="ID"
            value={mbtiInfoId}
            onChange={handleChangeFormData}
            disabled
          />
        )}
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          inputProps={{ minLength: 4, maxLength: 4 }}
          id="mbtiType"
          name="mbtiType"
          label="기준"
          placeholder="INTJ"
          value={mbtiType}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          inputProps={{ minLength: 4, maxLength: 4 }}
          id="mbtiTypeMatch"
          name="mbtiTypeMatch"
          label="비교"
          placeholder="INTJ"
          value={mbtiTypeMatch}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 2 }}
          type="number"
          id="matchRate"
          name="matchRate"
          label="매칭률"
          placeholder="80"
          value={matchRate}
          onChange={handleChangeFormData}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
