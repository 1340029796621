import { axiosPost } from "src/api/interceptors";

class AuthService {
  /*
   * login token 조회
   * adminAccount 매니저 ID
   * adminPw 매니저 PW
   */
  getLoginToken(payload) {
    return axiosPost("/manager/login", payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default new AuthService();
