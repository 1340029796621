import React from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const reasonOptions = [
  { label: "기대했던 서비스와 달라요.", value: "01" },
  { label: "연인이 생겼어요.", value: "02" },
  { label: "이용 중 불쾌한 경험을 했어요.", value: "03" },
  { label: "마음에 드는 회원이 없어요.", value: "04" },
  { label: "다른 서비스를 사용 할 거예요.", value: "05" },
  { label: "기타", value: "06" }
];

const CancelGuide = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="탈퇴 안내" back />
      <Stack height="87vh" justifyContent="space-between">
        <Stack spacing={2}>
          <Typography variant="h6">
            어떠한 사유로 탈퇴를 진행하시나요? 탈퇴 사유를 알려주시면 서비스
            개선에 더욱 힘쓰겠습니다.
          </Typography>
          <FormControl>
            <FormLabel id="cancel-radio-buttons-group-label">사유</FormLabel>
            <RadioGroup
              aria-labelledby="cancel-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {reasonOptions.map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={<Radio />}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <TextField
            placeholder="상세 내용을 작성해주세요(선택)"
            multiline
            rows={4}
          />
        </Stack>
      </Stack>
      <Button fullWidth size="large" variant="outlined">
        확인
      </Button>
    </Paper>
  );
};

export default CancelGuide;
