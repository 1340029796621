import { configureStore } from "@reduxjs/toolkit";
import content from "./contentSlice";
import toast from "./toastSlice";

export default configureStore({
  reducer: {
    content,
    toast
  }
});
