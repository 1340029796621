export default [
  {
    id: 6,
    type: "포인트",
    subType: "신규 가입",
    nickName: "곤드레사자",
    amount: null,
    point: 30,
    status: "무료 적립",
    createdAt: 1555016400000
  },
  {
    id: 5,
    type: "결제",
    subType: "50 구매",
    nickName: "가을물곰",
    amount: 25000,
    point: 50,
    status: "결제 완료",
    createdAt: 1555016400000
  },
  {
    id: 4,
    type: "포인트",
    subType: "신규 가입",
    nickName: "어쩌면",
    amount: null,
    point: 30,
    status: "무료 적립",
    createdAt: 1555016400000
  },
  {
    id: 3,
    type: "결제",
    subType: "50 구매",
    nickName: "충남뚱이",
    amount: 25000,
    point: 50,
    status: "결제 완료",
    createdAt: 1555016400000
  },
  {
    id: 2,
    type: "결제",
    subType: "50 환불",
    nickName: "대전고양이",
    amount: -25000,
    point: -50,
    status: "환불",
    createdAt: 1555016400000
  },
  {
    id: 1,
    type: "결제",
    subType: "50 환불",
    nickName: "어화둥둥",
    amount: -25000,
    point: -50,
    status: "환불",
    createdAt: 1555016400000
  }
];
