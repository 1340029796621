import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography
} from "@mui/material";
import moment from "moment";

const ProfileInfoCard = (props) => {
  const { user } = props;
  const {
    avatar,
    meetingTypeName,
    nickName,
    gender,
    age,
    idVerification,
    lastLoginedAt
  } = user;
  return (
    <Card>
      <CardContent>
        <Stack>
          <Stack direction="row" spacing={2}>
            <Avatar
              src={`/static/user/${
                gender == "남" ? "male" : "female"
              }_${avatar}.png`}
              sx={{
                height: 80,
                mb: 2,
                width: 80
              }}
            />
            <Box>
              <Chip label={meetingTypeName} />
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle1">{nickName}</Typography>,
                <Typography variant="body2" sx={{ pl: 1 }}>
                  {age}
                </Typography>
              </Box>
            </Box>
          </Stack>
          {idVerification && (
            <Typography variant="subtitle1">본인인증 완료</Typography>
          )}
          <Typography variant="subtitle2">
            마지막 접속시간:{" "}
            {moment(lastLoginedAt).format("YYYY/MM/DD hh:ss:mm")}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProfileInfoCard;
