import ManagerService from "src/services/ManagerService";
import { API_RESULT } from "src/constant/commonCode";

class ManagerController {
  /*
   * 매니저 목록 조회
   */
  getManagerList() {
    const result = ManagerService.selectManagerList();
    return result;
  }

  /*
   * 매니저 1건 조회
   * adminAccountId 매니저 ID
   */
  getManager(adminAccountId) {
    const result = ManagerService.selectManager(adminAccountId);
    const managerInfo = result[0];
    return managerInfo;
  }

  /*
   * 매니저 1건 신규 추가
   * adminAccount 로그인 ID
   * adminPw Password
   * adminStatus 계정 상태
   * adminNickName 사용자 명
   * adminActivate
   * adminMobileNumber 휴대폰 번호
   * adminRole 직급
   * useYn 사용 유무
   */
  addManager(params) {
    const payload = {
      adminAccount: params.adminAccount,
      adminPw: params.adminPw,
      adminStatus: params.adminStatus,
      adminNickName: params.adminNickName,
      adminActivate: params.adminActivate,
      adminMobileNumber: params.adminMobileNumber,
      adminRole: params.adminRole,
      useYn: params.useYn
    };

    return new Promise((resolve, reject) => {
      ManagerService.insertManager(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 매니저 1건 수정
   * adminAccountId 매니저 ID
   * adminAccount 로그인 ID
   * adminPw Password
   * adminStatus 계정 상태
   * adminNickName 사용자 명
   * adminActivate
   * adminMobileNumber 휴대폰 번호
   * adminRole 직급
   * useYn 사용 유무
   */
  editManager(params) {
    const payload = {
      adminAccountId: params.adminAccountId,
      adminAccount: params.adminAccount,
      adminPw: params.adminPw,
      adminStatus: params.adminStatus,
      adminNickName: params.adminNickName,
      adminActivate: params.adminActivate,
      adminMobileNumber: params.adminMobileNumber,
      adminRole: params.adminRole,
      useYn: params.useYn
    };

    return new Promise((resolve, reject) => {
      ManagerService.updateManager(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 매니저 1건 삭제
   * adminAccountId 매니저 ID
   */
  deleteManager(storeInfoId) {
    return new Promise((resolve, reject) => {
      ManagerService.deleteManager(storeInfoId).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }
}

export default new ManagerController();
