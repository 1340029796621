import * as React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack
} from "@mui/material";

export default function CareerFormDialog(props) {
  const { open, data, onClose, onConfirm } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          onConfirm(formJson);
        }
      }}
    >
      <DialogTitle>Career 상세</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="companyCd"
          name="companyCd"
          label="career code"
          {...(data?.companyCd && { value: data?.companyCd })}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="companyEmail"
          name="companyEmail"
          label="email(domain)"
          {...(data?.companyEmail && { value: data?.companyEmail })}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="companyName"
          name="companyName"
          label="국문 명"
          {...(data?.companyName && { value: data?.companyName })}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="companyOriginalName"
          name="companyOriginalName"
          label="국문 명(원)"
          {...(data?.companyOriginalName && {
            value: data?.companyOriginalName
          })}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="companyNameEn"
          name="companyNameEn"
          label="영문 명"
          {...(data?.companyNameEn && {
            value: data?.companyNameEn
          })}
          required
        />
        <Stack>
          <FormControl margin="dense">
            <InputLabel id="companyGb-select-label">타입 구분</InputLabel>
            <Select
              labelId="companyGb-select-label"
              id="companyGb"
              name="companyGb"
              label="companyGb"
              {...(data?.companyGb
                ? { value: data?.companyGb }
                : { defaultValue: "C" })}
            >
              <MenuItem value={"C"}>C</MenuItem>
              <MenuItem value={"K"}>K</MenuItem>
              <MenuItem value={"F"}>F</MenuItem>
            </Select>
          </FormControl>
          <FormControl margin="dense">
            <InputLabel id="authYn-select-label">확인 여부</InputLabel>
            <Select
              labelId="authYn-select-label"
              id="authYn"
              name="authYn"
              label="authYn"
              {...(data?.authYn
                ? { value: data?.authYn }
                : { defaultValue: "Y" })}
            >
              <MenuItem value={"Y"}>Y</MenuItem>
              <MenuItem value={"N"}>N</MenuItem>
            </Select>
          </FormControl>
          <FormControl margin="dense">
            <InputLabel id="useYn-select-label">사용 여부</InputLabel>
            <Select
              labelId="useYn-select-label"
              id="useYn"
              name="useYn"
              label="useYn"
              {...(data?.useYn
                ? { value: data?.useYn }
                : { defaultValue: "Y" })}
            >
              <MenuItem value={"Y"}>Y</MenuItem>
              <MenuItem value={"N"}>N</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
