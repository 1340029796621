import React from "react";
import { List, ListItem, ListItemText, Paper, Stack } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const BusinessInfo = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="사업자정보" back />
      <Stack m={2}>
        <List>
          <ListItem disablePadding>
            <ListItemText primary="상호" secondary="인하우스소프트" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary="대표" secondary="이태린" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary="사업자등록번호" secondary="731-08-02170" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary="메일" secondary="help@ublooming.co.kr" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary="고객센터"
              secondary="070-8984-3550(이메일 고객센터만 운영)"
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary="주소"
              secondary="광명시 소하로190 광명G타워, A동 1020호"
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary="홈페이지" secondary="www.ublooming.co.kr" />
          </ListItem>
        </List>
      </Stack>
    </Paper>
  );
};

export default BusinessInfo;
