import React from "react";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const EnterEmail = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="메일 인증" back />
      <Stack height="92vh" justifyContent="space-between">
        <Stack>
          <Typography variant="h6">회사 메일을 입력해 주세요.</Typography>
          <TextField fullWidth variant="standard" label="회사 아이디" />
          <TextField
            fullWidth
            variant="standard"
            value="@company.com"
            InputProps={{
              readOnly: true
            }}
          />
        </Stack>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          href="/app/email/code"
        >
          다음
        </Button>
      </Stack>
    </Paper>
  );
};

export default EnterEmail;
