import React from "react";
import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";

const TagGroup = (props) => {
  const { edit, title, list } = props;
  return (
    <Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1">{title}</Typography>
        {edit && (
          <IconButton size="small">
            <ChevronRightIcon />
          </IconButton>
        )}
      </Stack>
      <Box sx={{ m: 1.5 }}>
        {list?.map((item, idx) => (
          <Chip sx={{ m: 0.3 }} key={idx} label={item} />
        ))}
      </Box>
    </Stack>
  );
};

export default TagGroup;
