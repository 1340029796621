import React from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { ProfileBioCard } from "src/components/widgets/Card";
import customers from "src/__mocks__/customers";

const WaitingRoom = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="Lounge" back />
      <Stack spacing={2}>
        <Stack alignItems="center" spacing={2}>
          <Typography variant="h5">124명</Typography>
          <Typography variant="subtitle2">
            좋아하는 스타일 설정에 따라 연결 가능한 회원이 달라져요.
          </Typography>
          <Button variant="outlined" href="/app/my/ideal-type">
            좋아하는 스타일 수정하기
          </Button>
        </Stack>
        <List>
          {customers.map((item, index) => (
            <ListItem key={index}>
              <ProfileBioCard user={item} />
            </ListItem>
          ))}
        </List>
      </Stack>
      <Box width="100%" position="fixed" left={0} bottom={0} right={0}>
        <Button
          fullWidth
          variant="contained"
          sx={{ borderRadius: 0, padding: "12px" }}
        >
          무료로 카드 받기
        </Button>
      </Box>
    </Paper>
  );
};

export default WaitingRoom;
