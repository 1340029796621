import React from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const RegistGroup = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="회사 등록" back />
      <Stack height="92vh" justifyContent="space-between">
        <Box>
          <Typography variant="h6">회사 정보를 입력해 주세요.</Typography>
          <TextField fullWidth variant="standard" label="회사 명" />
          <TextField
            fullWidth
            variant="standard"
            label="회사 이메일"
            placeholder="gildong@company.com"
          />
          <ul>
            <li>
              <Typography variant="caption">
                회사 등록이 완료 되면, 계정 생성 후 무료 풍선 50개가 지급
                됩니다.
              </Typography>
            </li>
          </ul>
        </Box>
        <Button fullWidth size="large" variant="outlined">
          완료
        </Button>
      </Stack>
    </Paper>
  );
};

export default RegistGroup;
