import React from "react";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";

const Permission = () => {
  return (
    <Paper>
      <Box display="flex">
        <IconButton>
          <ChevronLeftIcon />
        </IconButton>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ p: 2, pb: 0 }}
        >
          권한 안내
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6">
          앱 사용을 위해 접근 권한을 허용해주세요
        </Typography>
        <Box>
          <Typography variant="subtitle1">선택 권한</Typography>
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography variant="subtitle2">알림</Typography>
            <Typography variant="body1"> 알림 메시지 발송</Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography variant="subtitle2">전화</Typography>
            <Typography variant="body1"> 연락처</Typography>
          </Box>
        </Box>
        <Typography variant="subtitle2">
          선택 권한의 경우 허용하지 않아도 서비스를 사용할 수 있으나 일부 서비스
          이용이 제한될 수 있습니다.
        </Typography>
        <Button fullWidth variant="outlined">
          확인
        </Button>
      </Box>
    </Paper>
  );
};

export default Permission;
