// ** React Imports
import React from "react";
import { useNavigate } from "react-router-dom";

// ** Widget Imports
import { Box, Tab, Tabs } from "@mui/material";
import CodeList from "src/pages/CommonCode/Code/CodeList";
import { TitledPaper } from "src/widgets";

export default function MbtiList() {
  // ** Hooks
  const navigate = useNavigate();

  // 탭 이동시 페이지 이동
  const handleChangeTabValue = () => {
    navigate("/common/mbti/info");
  };

  return (
    <TitledPaper title="MBTI 목록">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={0}
          onChange={handleChangeTabValue}
          aria-label="basic tabs example"
        >
          <Tab label="MBTI 목록" id="mbti-tab-0" />
          <Tab label="MBTI 매칭 정보" id="mbti-tab-1" />
        </Tabs>
      </Box>
      <CodeList groupCode="13" />
    </TitledPaper>
  );
}
