import { v4 as uuid } from "uuid";

export default {
  userId: uuid(),
  nickName: "대전고양이",
  totalPoint: 83,
  freePoint: 33,
  paidPoint: 50,
  totalPaidAmount: 25000,
  last7PaidAmount: 25000,
  historyList: [
    {
      id: 9,
      type: "포인트",
      subType: "채팅 보상",
      amount: null,
      point: 1,
      totalPoint: 84,
      status: "무료 적립",
      createdAt: 1555016400000
    },
    {
      id: 8,
      type: "포인트",
      subType: "카드 받기",
      amount: null,
      point: -2,
      totalPoint: 83,
      status: "사용",
      createdAt: 1555016400000
    },
    {
      id: 7,
      type: "포인트",
      subType: "출석체크",
      amount: null,
      point: 3,
      totalPoint: 85,
      status: "무료 적립",
      createdAt: 1555016400000
    },
    {
      id: 6,
      type: "포인트",
      subType: "채팅 하기",
      amount: null,
      point: -2,
      totalPoint: 82,
      status: "사용",
      createdAt: 1555016400000
    },
    {
      id: 5,
      type: "포인트",
      subType: "카드 받기",
      amount: null,
      point: -2,
      totalPoint: 84,
      status: "사용",
      createdAt: 1555016400000
    },
    {
      id: 4,
      type: "포인트",
      subType: "카드 받기",
      amount: null,
      point: -2,
      totalPoint: 86,
      status: "사용",
      createdAt: 1555016400000
    },
    {
      id: 3,
      type: "포인트",
      subType: "출석체크",
      amount: null,
      point: 3,
      totalPoint: 88,
      status: "무료 적립",
      createdAt: 1555016400000
    },
    {
      id: 2,
      type: "결제",
      subType: "50 구매",
      amount: 25000,
      point: 50,
      bonusPoint: 5,
      totalPoint: 85,
      status: "결제 완료",
      createdAt: 1555016400000
    },
    {
      id: 1,
      type: "포인트",
      subType: "신규 가입",
      amount: null,
      point: 30,
      totalPoint: 30,
      status: "무료 적립",
      createdAt: 1555016400000
    }
  ]
};
