// ** React Imports
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// ** Hook Imports
import { useToast } from "src/hooks";
import AuthController from "src/controller/AuthController";

// ** Widget Imports
import { AppBar, Badge, Box, Hidden, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import InputIcon from "@mui/icons-material/Input";
import Logo from "src/components/Logo";

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  // ** Hook
  const toast = useToast();
  const navigate = useNavigate();

  const [notifications] = useState([]);

  const logout = async (e) => {
    e.preventDefault();

    await AuthController.logout();
    toast.success("로그아웃 되었습니다.");
    navigate("/login");
  };

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <IconButton color="inherit" size="large">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" size="large" onClick={logout}>
            <InputIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
