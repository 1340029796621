// ** React Imports
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// ** Theme Imports
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";

// ** Context
import { AuthContextProvider, ToastContextProvider } from "src/context/";

// ** Redux Imports
import { Provider } from "react-redux";
import store from "src/store";

import App from "src/App";

// react-quill css
import "react-quill/dist/quill.snow.css";

// ** react-query Imports
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AuthContextProvider>
      <ToastContextProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </ToastContextProvider>
    </AuthContextProvider>
  </Provider>
);
