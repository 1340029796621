import CareerService from "src/services/CareerService";
import { API_RESULT, CAREER_TYPE } from "src/constant/commonCode";
import { convertToYN } from "src/utils/dataConverter";

class CareerController {
  /*
   * 회사 목록 조회
   * careerType 직장 company-info, 학교 university-info
   * searchText 검색 텍스트 조건
   */
  getCareerList(careerType, params) {
    const result = CareerService.careerList(careerType, params);
    return result;
  }

  /*
   * Career 1건 신규 추가
   * careerType 직장 company-info, 학교 university-info
   * String careerEmail 회사 홈페이지 주소
   * String companyName 회사 표기 명
   * String careerOriginalName 회사 명
   * String careerNameEn 회사 영문 명
   * Boolean useYn 사용 여부
   */
  addCareer({ careerType, params }) {
    const payload =
      careerType == CAREER_TYPE.COMPANY
        ? {
            companyCd: params.careerCd,
            companyEmail: params.careerEmail,
            companyName: params.careerName,
            careerOriginalName: params.careerOriginalName,
            careerNameEn: params.careerNameEn,
            authYn: "Y", // 관리자 인증 여부
            useYn: convertToYN(params.useYn)
          }
        : {
            universityCd: params.careerCd,
            universityEmail: params.careerEmail,
            universityName: params.careerName,
            authYn: "Y",
            useYn: convertToYN(params.useYn)
          };

    return new Promise((resolve, reject) => {
      CareerService.insertCareer(careerType, payload, false).then(
        (response) => {
          if (response.affectedRows > 0) {
            return resolve(API_RESULT.SUCCESS);
          } else {
            return reject(API_RESULT.ERROR);
          }
        }
      );
    });
  }

  /*
   * Career 1건 수정
   * careerType 직장 company-info, 학교 university-info
   * int careerInfoId career ID
   * String careerEmail 회사 홈페이지 주소
   * String companyName 회사 표기 명
   * String careerOriginalName 회사 명
   * String careerNameEn 회사 영문 명
   * Boolean useYn 사용 여부
   */
  editCareer({ careerType, params }) {
    const payload =
      careerType == CAREER_TYPE.COMPANY
        ? {
            companyInfoId: params.id,
            companyCd: params.careerCd,
            companyEmail: params.careerEmail,
            companyName: params.careerName,
            careerOriginalName: params.careerOriginalName,
            careerNameEn: params.careerNameEn,
            authYn: "Y", // 관리자 인증 여부
            useYn: convertToYN(params.useYn)
          }
        : {
            universityInfoId: params.id,
            universityCd: params.careerCd,
            universityEmail: params.careerEmail,
            universityName: params.careerName,
            authYn: "Y",
            useYn: convertToYN(params.useYn)
          };

    return new Promise((resolve, reject) => {
      CareerService.updateCareer(careerType, payload, false).then(
        (response) => {
          if (response.affectedRows > 0) {
            return resolve(API_RESULT.SUCCESS);
          } else {
            return reject(API_RESULT.ERROR);
          }
        }
      );
    });
  }

  /*
   * Career 1건 삭제
   * careerType 직장 company-info, 학교 university-info
   * careerInfoId career ID
   */
  deleteCareer({ careerType, careerInfoId }) {
    return new Promise((resolve, reject) => {
      CareerService.deleteCareer(careerType, careerInfoId).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }
}

export default new CareerController();
