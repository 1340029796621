import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";

class ManagerService {
  /*
   * 매니저 목록 조회
   */
  selectManagerList(adminAccountId) {
    let path = "/manager/null";

    // adminAccountId == null: 전체 조회
    // adminAccountId != null: 1건 조회
    if (adminAccountId != null) {
      path = `${path}/${adminAccountId}`;
    }

    // call api
    return axiosGet(path).then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.adminAccountId
        };
      });
    });
  }

  /*
   * 매니저 1건 조회
   */
  selectManager(adminAccountId) {
    // call api
    return axiosGet(`/manager/${adminAccountId}`).then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.adminAccountId
        };
      });
    });
  }

  /*
   * 매니저 1건 신규 추가
   * adminAccount 로그인 ID
   * adminPw Password
   * adminStatus 계정 상태
   * adminNickName 한글 명
   * adminActivate
   * adminMobileNumber 휴대폰 번호
   * adminRole 직급
   * useYn 사용 유무
   */
  insertManager(payload) {
    return axiosPost("/manager", payload).then((response) => {
      return response;
    });
  }

  /*
   * 매니저 1건 수정
   * adminAccountId 매니저 ID
   * adminAccount 로그인 ID
   * adminPw Password
   * adminStatus 계정 상태
   * adminNickName 한글 명
   * adminActivate
   * adminMobileNumber 휴대폰 번호
   * adminRole 직급
   * useYn 사용 유무
   */
  updateManager(payload) {
    return axiosPut("/manager", payload).then((response) => {
      return response;
    });
  }

  /*
   * 매니저 1건 삭제
   * adminAccountId 매니저 ID
   */
  deleteManager(adminAccountId) {
    return axiosDelete(`/manager/${adminAccountId}`).then((response) => {
      return response;
    });
  }
}

export default new ManagerService();
