import React from "react";

// ** Redux Imports
import { useDispatch } from "react-redux";

import { Alert, Snackbar } from "@mui/material";
import { CLEAR_TOAST } from "src/store/toastSlice";

const Toast = ({ toast }) => {
  // ** Store
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(CLEAR_TOAST());
  };

  return (
    <Snackbar
      key={toast.id}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={3000}
      open={toast.type != null ? true : false}
      onClose={handleClose}
    >
      <Alert severity={toast.type ?? "success"}>{toast.message}</Alert>
    </Snackbar>
  );
};

export default Toast;
