// ** React Imports
import React, { useEffect, useState } from "react";

// ** Redux Imports
import { useQuery, useMutation } from "react-query";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Redux Imports
import CodeController from "src/controller/CodeController";

// ** Widget Imports
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { GridContainer } from "src/components";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { TitledPaper } from "src/widgets";

// Utils
import { API_RESULT, API_RESULT_ACTION } from "src/constant/commonCode";
import CodeFormDialog from "src/widgets/dialog/CodeFormDialog";

const columns = [
  { field: "id", headerName: "ID", type: "number", sortable: true },
  { field: "groupCode", headerName: "그룹 코드", sortable: true },
  { field: "groupName", headerName: "그룹 명", sortable: true },
  { field: "codeId", headerName: "코드 ID" },
  { field: "codeValue", headerName: "코드 값" },
  { field: "codeDesc", headerName: "코드 설명" },
  { field: "codeSort", type: "number", headerName: "코드 정렬" },
  {
    field: "useYn",
    headerName: "사용 여부",
    sortable: true
  }
];

export default function CodeList(props) {
  // ** Props
  const { groupCode } = props;

  // ** Hook
  const toast = useToast();

  // ** State
  // 코드 상세 Form Dialog 오픈 여부
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  // Selected Row to delete with API when delete button clicked
  const [selectedRow, setSelectedRow] = useState(null);
  // Search conditions
  const [searchConditions, setSearchConditions] = useState({
    groupCode: "",
    useYn: "00",
    searchText: ""
  });

  // ** Fetch
  // 아이템 목록 조회
  const {
    isLoading,
    refetch,
    data: rowData
  } = useQuery("codes", () => CodeController.getCodeList(searchConditions));

  // 신규 아이템 추가
  const { mutate: AddItem } = useMutation({
    mutationFn: CodeController.addCode,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.ADD);
      setFormDialogOpen(false);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 수정
  const { mutate: EditItem } = useMutation({
    mutationFn: CodeController.editCode,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.EDIT);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 삭제
  const { mutate: DeleteItem } = useMutation({
    mutationFn: CodeController.deleteCode,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.DELETE);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 검색 조건 옵션 변경
  const handleChangeSearchConditions = (e) => {
    const { name, value } = e.target;
    setSearchConditions((prev) => ({ ...prev, [name]: value }));
  };

  // 검색 옵션 초기화
  const handleClickReset = () => {
    setSearchConditions({
      groupCode: groupCode ?? "",
      useYn: "00",
      searchText: ""
    });
    refetch();
  };

  // group code prop data가 있는 경우
  const handleChangeGroupCode = (newValue) => {
    setSearchConditions((prev) => ({ ...prev, groupCode: newValue }));
    refetch();
  };

  // Grid row 클릭시 선택된 데이터 저장
  const handleRowClick = (params) => {
    // 선택된 로우 저장
    setSelectedRow(params);
  };

  // 신규 아이템 Dialog Open
  const handleClickCodeFormDialogHandler = () => {
    setFormDialogOpen(!formDialogOpen);
  };

  // 신규 아이템 추가 버튼 클릭
  const handleClickNew = () => {
    // 선택된 로우 비우기
    setSelectedRow(null);
    // Dialog 열기
    handleClickCodeFormDialogHandler();
  };

  // 아이템 수정 dialog open
  const handleClickEdit = () => {
    // 선택된 아이템 여부 확인
    if (selectedRow?.id) {
      // Dialog 열기
      handleClickCodeFormDialogHandler();
    } else {
      toast.warning(API_RESULT_ACTION.NO_ITEM);
    }
  };

  // 상세 dialog에서 작업 후 저장
  const handleClickSave = (newFormData) => {
    if (newFormData?.avatarInfoId) {
      // 수정
      EditItem(newFormData);
    } else {
      // 신규
      AddItem(newFormData);
    }
  };

  // 아이템 삭제
  const handleClickDelete = () => {
    // 선택된 아이템 여부 확인
    if (selectedRow?.id) {
      DeleteItem(selectedRow?.id);
    } else {
      toast.warning(API_RESULT_ACTION.NO_ITEM);
    }
  };

  useEffect(() => {
    groupCode && handleChangeGroupCode(groupCode);
  }, [groupCode]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <FormControl fullWidth>
            <TextField
              fullWidth
              inputProps={{
                form: {
                  autocomplete: "off"
                }
              }}
              id="groupCode"
              name="groupCode"
              type="number"
              variant="outlined"
              label="그룹 코드"
              value={searchConditions.groupCode}
              onChange={handleChangeSearchConditions}
              disabled={groupCode ? true : false}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              fullWidth
              inputProps={{
                form: {
                  autocomplete: "off"
                }
              }}
              id="searchText"
              name="searchText"
              variant="outlined"
              label="검색"
              value={searchConditions.searchText}
              onChange={handleChangeSearchConditions}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="use-select-label">사용 여부</InputLabel>
            <Select
              labelId="use-select-label"
              id="useYn"
              name="useYn"
              label="useYn"
              value={searchConditions.useYn}
              onChange={handleChangeSearchConditions}
            >
              <MenuItem value={"00"}>전체</MenuItem>
              <MenuItem value={"Y"}>Y</MenuItem>
              <MenuItem value={"N"}>N</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <Button color="primary" variant="outlined" onClick={handleClickNew}>
            추가
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleClickEdit}
          >
            수정
          </Button>
          <Button color="error" variant="contained" onClick={handleClickDelete}>
            삭제
          </Button>
          <Button color="primary" variant="contained" onClick={refetch}>
            검색
          </Button>
          <IconButton aria-label="refresh" onClick={handleClickReset}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <GridContainer
          columns={columns}
          rowData={rowData || []}
          onRowClick={handleRowClick}
        />
      </Box>
      <CodeFormDialog
        open={formDialogOpen}
        data={selectedRow?.row}
        onClose={handleClickCodeFormDialogHandler}
        onConfirm={handleClickSave}
      />
    </>
  );
}

export function CodeFrame() {
  return (
    <TitledPaper title="공통 코드">
      <CodeList />
    </TitledPaper>
  );
}
