import MbtiInfoService from "src/services/MbtiInfoService";
import { API_RESULT } from "src/constant/commonCode";

class MbtiInfoController {
  /*
   * MBTI 매칭 정보 목록 조회
   */
  getMbtiInfoList() {
    const result = MbtiInfoService.mbtiInfoList();
    return result;
  }

  /*
   * MBTI 매칭 정보 1건 신규 추가
   * mbtiType 기준 MBTI
   * mbtiTypeMatch 비교 MBTI
   * matchRate MBTI 매칭률
   */
  addMbtiInfo(params) {
    const payload = {
      mbtiType: params.mbtiType,
      mbtiTypeMatch: params.mbtiTypeMatch,
      matchRate: params.matchRate
    };

    return new Promise((resolve, reject) => {
      MbtiInfoService.insertMbtiInfo(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * MBTI 매칭 정보 1건 수정
   * mbtiInfoId MBTI 매칭 정보 ID
   * mbtiType 기준 MBTI
   * mbtiTypeMatch 비교 MBTI
   * matchRate MBTI 매칭률
   */
  editMbtiInfo(params) {
    const payload = {
      mbtiInfoId: params.mbtiInfoId,
      mbtiType: params.mbtiType,
      mbtiTypeMatch: params.mbtiTypeMatch,
      matchRate: params.matchRate
    };

    return new Promise((resolve, reject) => {
      MbtiInfoService.updateMbtiInfo(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * MBTI 매칭 정보 1건 삭제
   * mbtiInfoId MBTI 매칭 정보 ID
   */
  deleteMbtiInfo(mbtiInfoId) {
    return new Promise((resolve, reject) => {
      MbtiInfoService.deleteMbtiInfo(mbtiInfoId).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }
}

export default new MbtiInfoController();
