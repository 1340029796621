import React, { useState } from "react";

import { Box, Button, Stack, Tabs, Tab } from "@mui/material";
import { AccountHistory } from "src/components/customer/AccountHistory";
import { AccountProfile } from "src/components/customer/AccountProfile";
import { TitledPaper } from "src/widgets";
import CoinHistory from "src/app/My/Store/CoinHistory";
import NotificationList from "src/app/My/Notification/NotificationList";

import { CONTENT_MODE } from "src/constant/commonCode";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function UserDetail() {
  // 상세 view mode
  const [mode, setMode] = useState(CONTENT_MODE.VIEW);
  // tab value
  const [tabValue, setTabValue] = useState(0);

  // tab value 변경
  const handleChangeTabValue = (e, newValue) => {
    setTabValue(newValue);
  };

  // 유저 정보 수정
  const handleClickEdit = () => {
    setMode(CONTENT_MODE.EDIT);
  };

  // 유저 정보 수정 취소
  const handleClickCancel = () => {
    setMode(CONTENT_MODE.VIEW);
  };

  // 유저 정보 수정 후 저장
  const handleClickSave = () => {
    setMode(CONTENT_MODE.VIEW);
  };

  return (
    <TitledPaper title="유저 상세">
      <Box display="flex" justifyContent="end" sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <Button color="warning" variant="contained">
            정상
          </Button>
          <Button color="warning" variant="contained">
            휴면
          </Button>
          <Button color="warning" variant="contained">
            정지
          </Button>
          <Button color="warning" variant="contained">
            탈퇴
          </Button>
          {mode === CONTENT_MODE.VIEW ? (
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickEdit}
            >
              수정
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickCancel}
            >
              취소
            </Button>
          )}
          {mode === CONTENT_MODE.EDIT && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickSave}
            >
              저장
            </Button>
          )}
          <Button color="error" variant="contained">
            삭제
          </Button>
        </Box>
      </Box>
      <Stack direction="row" spacing={3}>
        {/* 유저 정보 */}
        <Stack>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTabValue}
              aria-label="basic tabs example"
            >
              <Tab label="프로필" id="tab-0" />
              <Tab label="풍선 내역" id="tab-1" />
              <Tab label="신고 내역" id="tab-2" />
              <Tab label="결제 내역" id="tab-3" />
              <Tab label="출석체크" id="tab-4" />
              <Tab label="알림" id="tab-5" />
            </Tabs>
          </Box>
          {/* 프로필 */}
          <CustomTabPanel value={tabValue} index={0}>
            <AccountProfile mode={mode} />
          </CustomTabPanel>
          {/* 풍선 내역 */}
          <CustomTabPanel value={tabValue} index={1}>
            <CoinHistory />
          </CustomTabPanel>
          {/* 신고 내역 */}
          <CustomTabPanel value={tabValue} index={2}>
            신고 내역
          </CustomTabPanel>
          {/* 결제 내역 */}
          <CustomTabPanel value={tabValue} index={3}>
            결제 내역
          </CustomTabPanel>
          {/* 출석체크 */}
          <CustomTabPanel value={tabValue} index={4}>
            출석체크
          </CustomTabPanel>
          {/* 알림 */}
          <CustomTabPanel value={tabValue} index={5}>
            <NotificationList />
          </CustomTabPanel>
        </Stack>
        {/* 관리자 메모 */}
        <AccountHistory />
      </Stack>
    </TitledPaper>
  );
}
