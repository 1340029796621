import React from "react";
import { Stack, TextField, Typography } from "@mui/material";

const SelfIntro = (props) => {
  const { contents, edit } = props;

  switch (edit) {
    case true:
      return <TextField multiline rows={6} label="저는요" value={contents} />;
    default:
      return (
        <Stack>
          <Typography variant="subtitle1">저는요</Typography>
          <Typography variant="body2">{contents}</Typography>
        </Stack>
      );
  }
};

export default SelfIntro;
