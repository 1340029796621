import AppInfoService from "src/services/AppInfoService";
import { API_RESULT } from "src/constant/commonCode";

class AppInfoController {
  /*
   * 앱 버전 정보 목록 조회
   */
  getAppInfoList() {
    const result = AppInfoService.selectAppInfoList();
    return result;
  }

  /*
   * 앱 버전 정보 1건 신규 추가
   * appVersionAos Aos Version
   * appVersionIos Ios Version
   * createTime 생성시간
   */
  addAppInfoItem(params) {
    return new Promise((resolve, reject) => {
      AppInfoService.insertAppInfoItem(params, false).then((response) => {
        if (response.status != 500) {
          // http status가 500이 아닌 경우
          if (response.affectedRows > 0) {
            // 실행 결과가 있는 경우
            return resolve(API_RESULT.SUCCESS);
          } else {
            return reject(API_RESULT.ERROR);
          }
        } else {
          // http status가 500인 경우
          return reject(response.data.message);
        }
      });
    });
  }

  /*
   * 앱 버전 정보 1건 수정
   * appInfoId 앱 버전 정보 ID
   * appVersionAos Aos Version
   * appVersionIos Ios Version
   * createTime 생성시간
   */
  editAppInfoItem(params) {
    return new Promise((resolve, reject) => {
      AppInfoService.updateAppInfoItem(params, false).then((response) => {
        if (response.status != 500) {
          // http status가 500이 아닌 경우
          if (response.affectedRows > 0) {
            // 실행 결과가 있는 경우
            return resolve(API_RESULT.SUCCESS);
          } else {
            return reject(API_RESULT.ERROR);
          }
        } else {
          // http status가 500인 경우
          return reject(response.data.message);
        }
      });
    });
  }

  /*
   * 앱 버전 정보 1건 삭제
   * appInfoId 앱 버전 정보 ID
   */
  deleteAppInfoItem(params) {
    return new Promise((resolve, reject) => {
      AppInfoService.deleteAppInfoItem(params).then((response) => {
        if (response.status != 500) {
          // http status가 500이 아닌 경우
          if (response.affectedRows > 0) {
            // 실행 결과가 있는 경우
            return resolve(API_RESULT.SUCCESS);
          } else {
            return reject(API_RESULT.ERROR);
          }
        } else {
          // http status가 500인 경우
          return reject(response.data.message);
        }
      });
    });
  }
}

export default new AppInfoController();
