// ** React Imports
import React, { useState } from "react";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Store Imports
import { useQuery, useMutation, useQueryClient } from "react-query";
import AppInfoController from "src/controller/AppInfoController";
import { API_RESULT, API_RESULT_ACTION } from "src/constant/commonCode";

// ** Widget Imports
import { Box, Button, TextField } from "@mui/material";
import { GridContainer } from "src/components";
import { TitledPaper } from "src/widgets";

// 수정 가능한 cell bg color
const editableCellClassName = (params) => {
  const cellClassName =
    params.isEditable == true || params.cellMode == "edit"
      ? "editable--column"
      : null;

  return cellClassName;
};

export default function AppInfoList() {
  // ** Hook
  const toast = useToast();
  const queryClient = useQueryClient();

  // ** State
  // Search conditions
  const [formValues, setFormValues] = useState({
    appVersionAos: "",
    appVersionIos: ""
  });
  const { appVersionAos, appVersionIos } = formValues;

  // ** Fetch
  // 회사 목록 조회
  const { data: rowData } = useQuery(
    "appInfoList",
    AppInfoController.getAppInfoList
  );

  // 1개 아이템 신규 추가
  const { mutate: AddItem } = useMutation({
    mutationFn: AppInfoController.addAppInfoItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.ADD);
      queryClient.invalidateQueries({ queryKey: "appInfoList" });
      setFormValues({
        appVersionAos: "",
        appVersionIos: ""
      });
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 정보 수정
  const { mutate: EditItem } = useMutation({
    mutationFn: AppInfoController.editAppInfoItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.EDIT);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 삭제
  const { mutate: DeleteItem } = useMutation({
    mutationFn: AppInfoController.deleteAppInfoItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.DELETE);
      queryClient.invalidateQueries({ queryKey: "appInfoList" });
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 검색 조건 옵션 변경
  const handleChangeSearchConditions = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // 신규 아이템 추가 버튼 클릭
  const handleClickNew = () => {
    AddItem(formValues);
  };

  // 아이템 수정 후 저장
  const handleClickEdit = (row) => {
    EditItem(row);
  };

  // 아이템 삭제
  const handleClickDelete = (id) => {
    DeleteItem({
      appInfoId: id
    });
  };

  const columns = [
    { field: "id", headerName: "ID", type: "number", width: 80 },
    {
      field: "appVersionAos",
      headerName: "Aos 버전",
      editable: true,
      cellClassName: editableCellClassName,
      width: 100
    },
    {
      field: "appVersionIos",
      headerName: "Ios 버전",
      editable: true,
      cellClassName: editableCellClassName,
      width: 100
    },
    {
      field: "createTime",
      type: "dateTime",
      headerName: "생성 날짜",
      valueGetter: ({ value }) => new Date(value),
      width: 180
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      width: 160,
      getActions: (params) => {
        return [
          <Button
            key="action-confirm"
            variant="contained"
            onClick={() => handleClickEdit(params.row)}
          >
            저장
          </Button>,
          <Button
            key="action-confirm"
            variant="outlined"
            color="error"
            onClick={() => handleClickDelete(params.row.id)}
          >
            삭제
          </Button>
        ];
      }
    }
  ];

  return (
    <TitledPaper title="앱 버전 정보">
      <Box display="flex" justifyContent="end" sx={{ mt: 3 }}>
        {/* 신규 추가 */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            backgroundColor: "#F6F5FF"
          }}
        >
          <TextField
            fullWidth
            type="number"
            label="Aos Version"
            name="appVersionAos"
            value={appVersionAos}
            onChange={handleChangeSearchConditions}
            required
          />
          <TextField
            fullWidth
            type="number"
            label="Ios Version"
            name="appVersionIos"
            value={appVersionIos}
            onChange={handleChangeSearchConditions}
            required
          />
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={handleClickNew}
          >
            추가
          </Button>
        </Box>
      </Box>
      {/* 목록 */}
      <Box
        sx={{
          pt: 3,
          "& .editable--column": {
            backgroundColor: "rgba(255, 7, 0, 0.55)"
          }
        }}
      >
        <GridContainer columns={columns} rowData={rowData || []} />
      </Box>
    </TitledPaper>
  );
}
