import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography
} from "@mui/material";

const ProfileBioCard = (props) => {
  const { user } = props;
  const { avatar, meetingTypeName, gender, company, location, bio } = user;

  return (
    <Card
      variant="outlined"
      sx={{
        m: 2,
        border: "none",
        borderBottom: "1px solid black",
        borderRadius: 0
      }}
    >
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Avatar
            src={`/static/user/${
              gender == "남" ? "male" : "female"
            }_${avatar}.png`}
            sx={{
              height: 80,
              mb: 2,
              width: 80
            }}
          />
          <Stack>
            <Stack direction="row" alignItems="center">
              <Chip color="primary" label={meetingTypeName} />
              <Typography variant="subtitle1" sx={{ pl: 1 }}>
                {company}
              </Typography>
              /<Typography variant="subtitle1">{location}</Typography>
            </Stack>
            <Typography variant="body1">{bio}</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProfileBioCard;
