import { v4 as uuid } from "uuid";

export default [
  {
    id: uuid(),
    socialType: "google",
    avatar: "01",
    phone: "010-2222-3333",
    email: "tankova@devias.io",
    meetPreferCd: "04",
    meetPreferName: "주말맥주",
    userNickName: "대전강아지",
    gender: "남",
    birthYear: "1991",
    age: "32",
    height: 187,
    regionNm: "서울",
    job: "경영",
    company: "네이버",
    graduation: "4년제",
    university: "한양대학교",
    bloodType: "A",
    mbti: "INTJ",
    religion: "무교",
    smoking: "안해요",
    drinking: "안해요",
    bio: "술, 담배 안하구요. 한사람만 바라봅니다. 결혼준비도 되었다고 생각합니다 화목한 가정 이루는게 꿈입니다",
    characteristic: ["강아지상", "깨끗한 피부", "귀여운 보조개"],
    mysellf: ["지적인", "유머있는", "신중한", "예의가 바른", "눈치가 빠른"],
    hobby: ["영화감상", "음악 감상", "책 읽기"],
    userStatus: "05",
    userStatusName: "가입 완료",
    totalCoin: 50,
    idVerification: 1555016400000,
    companyVerification: 1555016400000,
    schoolVerification: null,
    lastLogined: 1555016400000,
    startDate: 1555016400000,
    updatedDate: 1555016400000
  },
  {
    id: uuid(),
    socialType: "naver",
    avatar: "02",
    phone: "010-2222-3333",
    email: "tankova@devias.io",
    meetPreferCd: "04",
    meetPreferName: "주말맥주",
    userNickName: "영등포사자",
    gender: "남",
    birthYear: "1990",
    age: "33",
    height: 187,
    regionNm: "서울",
    job: "경영",
    company: "네이버",
    graduation: "4년제",
    university: "한양대학교",
    bloodType: "A",
    mbti: "INTJ",
    religion: "무교",
    smoking: "안해요",
    drinking: "안해요",
    bio: "부산사는 30대 남자입니다\n취미는 드라이브 영화보기 등산\n활동적 입니다",
    characteristic: ["강아지상", "깨끗한 피부", "귀여운 보조개"],
    mysellf: ["지적인", "유머있는", "신중한", "예의가 바른", "눈치가 빠른"],
    hobby: ["영화감상", "음악 감상", "책 읽기"],
    userStatus: "05",
    userStatusName: "가입 완료",
    totalCoin: 30,
    idVerification: 1555016400000,
    companyVerification: 1555016400000,
    schoolVerification: null,
    lastLogined: 1555016400000,
    startDate: 1555016400000,
    updatedDate: 1555016400000
  },
  {
    id: uuid(),
    socialType: "kakao",
    avatar: "03",
    phone: "010-2222-3333",
    email: "tankova@devias.io",
    meetPreferCd: "04",
    meetPreferName: "주말맥주",
    userNickName: "목동참새",
    gender: "남",
    birthYear: "1987",
    age: "36",
    height: 187,
    regionNm: "서울",
    job: "경영",
    company: "네이버",
    graduation: "4년제",
    university: "한양대학교",
    bloodType: "A",
    mbti: "INTJ",
    religion: "무교",
    smoking: "안해요",
    drinking: "안해요",
    bio: "술, 담배 안하구요. 한사람만 바라봅니다. 결혼준비도 되었다고 생각합니다 화목한 가정 이루는게 꿈입니다",
    characteristic: ["강아지상", "깨끗한 피부", "귀여운 보조개"],
    mysellf: ["지적인", "유머있는", "신중한", "예의가 바른", "눈치가 빠른"],
    hobby: ["영화감상", "음악 감상", "책 읽기"],
    userStatus: "05",
    userStatusName: "가입 완료",
    totalCoin: 10,
    idVerification: 1555016400000,
    companyVerification: 1555016400000,
    schoolVerification: null,
    lastLogined: 1555016400000,
    startDate: 1555016400000,
    updatedDate: 1555016400000
  }
];
