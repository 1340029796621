import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";

export default function StoreItemFormDialog(props) {
  const { open, data, onClose, onConfirm } = props;
  const [formData, setFormData] = useState({
    storeInfoId: "",
    productName: "",
    productPrice: "",
    productBcnt: "",
    productDesc: "",
    useYn: "Y"
  });
  const {
    storeInfoId,
    productName,
    productPrice,
    productBcnt,
    productDesc,
    useYn
  } = formData;

  // Input field handler
  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // 수정일 경우 Form Data 업데이트
  const handleSetFormData = (newFormData) => {
    setFormData(newFormData);
  };

  const handleClose = () => {
    // Form Data 초기화
    handleSetFormData({
      storeInfoId: "",
      productName: "",
      productPrice: "",
      useYn: "M"
    });

    // Dialog 닫기
    onClose && onClose();
  };

  // 유입되는 데이터 확인
  useEffect(() => {
    data && handleSetFormData(data);
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          onConfirm(formData);
        }
      }}
    >
      <DialogTitle>상품 상세</DialogTitle>
      <DialogContent>
        {storeInfoId != "" && (
          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            id="storeInfoId"
            name="storeInfoId"
            label="ID"
            value={storeInfoId}
            onChange={handleChangeFormData}
            disabled
          />
        )}
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="productName"
          name="productName"
          label="상품 명"
          value={productName}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="productPrice"
          name="productPrice"
          label="가격"
          value={productPrice}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="productBcnt"
          name="productBcnt"
          label="보너스"
          value={productBcnt}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="productDesc"
          name="productDesc"
          label="설명"
          value={productDesc}
          onChange={handleChangeFormData}
          required
        />
        <FormControl margin="dense">
          <InputLabel id="useYn-select-label">사용 유무</InputLabel>
          <Select
            labelId="useYn-select-label"
            id="useYn"
            name="useYn"
            label="useYn"
            value={useYn}
            onChange={handleChangeFormData}
          >
            <MenuItem value={"Y"}>Y</MenuItem>
            <MenuItem value={"N"}>N</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
