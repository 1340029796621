import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";
import { createDynamicPath } from "src/utils/dataConverter";

class AppInfoService {
  /*
   * 앱 버전 정보 목록 조회
   */
  selectAppInfoList() {
    return axiosGet("admin-manage/app-info")
      .then((response) => {
        return response.map((item) => {
          return {
            ...item,
            id: item.appInfoId
          };
        });
      })
      .catch((error) => {
        return error.response;
      });
  }

  /*
   * 앱 버전 정보 1건 신규 추가
   * appVersionAos Aos Version
   * appVersionIos Ios Version
   * createTime 생성시간
   */
  insertAppInfoItem(payload) {
    return axiosPost("admin-manage/app-info", payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /*
   * 앱 버전 정보 1건 수정
   * appInfoId 앱 버전 정보 ID
   * appVersionAos Aos Version
   * appVersionIos Ios Version
   * createTime 생성시간
   */
  updateAppInfoItem(payload) {
    return axiosPut("admin-manage/app-info", payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /*
   * 앱 버전 정보 1건 삭제
   * appInfoId 앱 버전 정보 ID
   */
  deleteAppInfoItem(params) {
    // Creating path
    const basePath = "admin-manage/app-info";
    const dynamicPath = createDynamicPath(basePath, params);
    return axiosDelete(dynamicPath)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default new AppInfoService();
