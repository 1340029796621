import React from "react";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const StepBirthYear = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="프로필 생성" back />
      <Stack height="92vh" justifyContent="space-between">
        <Stack>
          <Typography variant="h6">년도를 입력해주세요</Typography>
          <TextField
            fullWidth
            variant="standard"
            inputProps={{
              minLength: 4,
              maxLength: 4,
              form: {
                autoComplete: "off"
              }
            }}
            type="number"
            label="년도"
            placeholder="2000"
          />
        </Stack>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          href="/app/join/height"
        >
          다음
        </Button>
      </Stack>
    </Paper>
  );
};

export default StepBirthYear;
