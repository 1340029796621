// ** React Imports
import React from "react";
import { Helmet } from "react-helmet";

// ** Widget Imports
import { Box, Container, Stack, Typography } from "@mui/material";

export default function TitledPaper(props) {
  // ** Props
  const { title = "TITLE", children } = props;
  return (
    <>
      <Helmet>
        <title>{title} | uBlooming Admin</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">{title}</Typography>
            </div>
            {children}
          </Stack>
        </Container>
      </Box>
    </>
  );
}
