import React from "react";
import { Stack } from "@mui/material";

const InfoSection = ({ children }) => {
  return (
    <Stack
      height="92vh"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      m={3}
    >
      {children}
    </Stack>
  );
};

export default InfoSection;
