// ** React Imports
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Controller Imports
import ContentController from "src/controller/ContentController";

// ** Widgets Imports
import { Box, Grid, Button } from "@mui/material";
import { EventForm } from "src/components/Event/EventForm";

// Util Imports
import dayjs from "dayjs";
import {
  API_RESULT,
  API_RESULT_ACTION,
  CONTENT_TYPE
} from "src/constant/commonCode";
import { TitledPaper } from "src/widgets";

function EventNew() {
  // ** Hook
  const toast = useToast();
  const navigate = useNavigate();

  // ** State
  const [formValues, setFormValues] = useState({
    categoryCd: CONTENT_TYPE.EVENT,
    categorySubCd: "01",
    adminNotiId: null,
    notiTitle: "",
    notiContent: "",
    imageName01: null,
    imageName02: null,
    imageName03: null,
    viewDate: dayjs(new Date()),
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
    useYn: "Y"
  });

  // Input field handler
  const handleChange = (event) => {
    // 스마트 에디터인 경우 확인. 스마트 에디터는 event data가 없어서 name 지정 필요
    const isReactQuill = !event.target?.name ?? false;
    const name = isReactQuill ? "notiContent" : event.target.name;
    const value = isReactQuill ? event : event.target.value;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // 메인 이미지 업로드
  const handleChangeMainImages = useCallback((newValue) => {
    // Form value에 데이터 담음
    setFormValues((prev) => ({ ...prev, imageName01: newValue }));
  }, []);

  // 배너 이미지 업로드
  const handleChangeBannerImages = useCallback((newValue) => {
    // Form value에 데이터 담음
    setFormValues((prev) => ({ ...prev, imageName02: newValue }));
  }, []);

  // 노출일자
  const handleChangeViewDate = (newValue) => {
    setFormValues((prev) => ({ ...prev, viewDate: newValue }));
  };

  // 시작일자
  const handleChangeStartDate = (newValue) => {
    setFormValues((prev) => ({ ...prev, startDate: newValue }));
  };

  // 종료일자
  const handleChangeEndDate = (newValue) => {
    setFormValues((prev) => ({ ...prev, endDate: newValue }));
  };

  // 신규 추가
  const handleSubmit = (event) => {
    event.preventDefault();

    ContentController.addMultiContent(formValues).then((res) => {
      if (res == API_RESULT.SUCCESS) {
        toast.success(API_RESULT_ACTION.ADD);
        navigate("/event");
      }
    });
  };

  return (
    <TitledPaper title="Event">
      <form method="post" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EventForm
              values={formValues}
              onChange={handleChange}
              onChangeMainImage={handleChangeMainImages}
              onChangeBannerImage={handleChangeBannerImages}
              onChangeViewDate={handleChangeViewDate}
              onChangeStartDate={handleChangeStartDate}
              onChangeEndDate={handleChangeEndDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px"
              }}
            >
              <Button color="primary" variant="contained" type="submit">
                저장
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </TitledPaper>
  );
}

export default EventNew;
