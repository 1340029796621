import MatchManageService from "src/services/MatchManageService";
import { API_RESULT } from "src/constant/commonCode";

class MatchManageController {
  /*
   * 매칭 정보 목록 조회
   */
  getMatchConditionList() {
    const result = MatchManageService.selectMatchConditionList();
    return result;
  }

  /*
   * 매칭 정보 1건 신규 추가
   * conditionCd 조건 코드
   * conditionText 조건
   * useYn 사용 유무
   * mustYn 필수 유무
   * matchGb 구분
   * matchOrder 비교 순서
   */
  addMatchConditionItem(params) {
    return new Promise((resolve, reject) => {
      MatchManageService.insertMatchConditionItem(params, false).then(
        (response) => {
          if (response.status != 500) {
            // http status가 500이 아닌 경우
            if (response.affectedRows > 0) {
              // 실행 결과가 있는 경우
              return resolve(API_RESULT.SUCCESS);
            } else {
              return reject(API_RESULT.ERROR);
            }
          } else {
            // http status가 500인 경우
            return reject(response.data.message);
          }
        }
      );
    });
  }

  /*
   * 매칭 정보 1건 수정
   * matchConditionId 매칭 정보 ID
   * conditionCd 조건 코드
   * conditionText 조건
   * useYn 사용 유무
   * mustYn 필수 유무
   * matchGb 구분
   * matchOrder 비교 순서
   */
  editMatchConditionItem(params) {
    return new Promise((resolve, reject) => {
      MatchManageService.updateMatchConditionItem(params, false).then(
        (response) => {
          if (response.status != 500) {
            // http status가 500이 아닌 경우
            if (response.affectedRows > 0) {
              // 실행 결과가 있는 경우
              return resolve(API_RESULT.SUCCESS);
            } else {
              return reject(API_RESULT.ERROR);
            }
          } else {
            // http status가 500인 경우
            return reject(response.data.message);
          }
        }
      );
    });
  }

  /*
   * 매칭 정보 1건 삭제
   * matchConditionId 매칭 정보 ID
   */
  deleteMatchConditionItem(params) {
    return new Promise((resolve, reject) => {
      MatchManageService.deleteMatchConditionItem(params).then((response) => {
        if (response.status != 500) {
          // http status가 500이 아닌 경우
          if (response.affectedRows > 0) {
            // 실행 결과가 있는 경우
            return resolve(API_RESULT.SUCCESS);
          } else {
            return reject(API_RESULT.ERROR);
          }
        } else {
          // http status가 500인 경우
          return reject(response.data.message);
        }
      });
    });
  }

  /*
   * 매칭 나이 정보 목록 조회
   */
  getUserAgeSetList() {
    const result = MatchManageService.selectUserAgeSetList();
    return result;
  }

  /*
   * 매칭 나이 정보 1건 신규 추가
   * preferAgeCd 선호 나이 코드
   * ageFirst 우선순위 1
   * ageSecond 우선순위 2
   * ageMin 나이 최소 값
   * ageMax 나이 최대 값
   */
  addUserAgeSetItem(params) {
    return new Promise((resolve, reject) => {
      MatchManageService.insertUserAgeSetItem(params, false).then(
        (response) => {
          if (response.status != 500) {
            // http status가 500이 아닌 경우
            if (response.affectedRows > 0) {
              // 실행 결과가 있는 경우
              return resolve(API_RESULT.SUCCESS);
            } else {
              return reject(API_RESULT.ERROR);
            }
          } else {
            // http status가 500인 경우
            return reject(response.data.message);
          }
        }
      );
    });
  }

  /*
   * 매칭 나이 정보 1건 수정
   * userAgeSetId 매칭 나이 정보 ID
   * preferAgeCd 선호 나이 코드
   * ageFirst 우선순위 1
   * ageSecond 우선순위 2
   * ageMin 나이 최소 값
   * ageMax 나이 최대 값
   */
  editUserAgeSetItem(params) {
    return new Promise((resolve, reject) => {
      MatchManageService.updateUserAgeSetItem(params, false).then(
        (response) => {
          if (response.status != 500) {
            // http status가 500이 아닌 경우
            if (response.affectedRows > 0) {
              // 실행 결과가 있는 경우
              return resolve(API_RESULT.SUCCESS);
            } else {
              return reject(API_RESULT.ERROR);
            }
          } else {
            // http status가 500인 경우
            return reject(response.data.message);
          }
        }
      );
    });
  }

  /*
   * 매칭 나이 정보 1건 삭제
   * userAgeSetId 매칭 나이 정보 ID
   */
  deleteUserAgeSetItem(params) {
    return new Promise((resolve, reject) => {
      MatchManageService.deleteUserAgeSetItem(params).then((response) => {
        if (response.status != 500) {
          // http status가 500이 아닌 경우
          if (response.affectedRows > 0) {
            // 실행 결과가 있는 경우
            return resolve(API_RESULT.SUCCESS);
          } else {
            return reject(API_RESULT.ERROR);
          }
        } else {
          // http status가 500인 경우
          return reject(response.data.message);
        }
      });
    });
  }
}

export default new MatchManageController();
