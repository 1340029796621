import React from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const AuthenticationCode = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="인증번호 입력" back />
      <Stack height="92vh" justifyContent="space-between">
        <Stack>
          <Typography variant="h6">인증 번호를 입력해 주세요.</Typography>
          <Typography variant="subtitle1">
            입력하신 이메일로 인증코드를 보냈습니다.
          </Typography>
          <TextField
            fullWidth
            inputProps={{
              form: {
                autocomplete: "off"
              }
            }}
            type="number"
            variant="outlined"
            placeholder="0000"
          />
        </Stack>
        <Button fullWidth size="large" variant="outlined">
          확인
        </Button>
      </Stack>
    </Paper>
  );
};

export default AuthenticationCode;
