import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import moment from "moment";
import rowData from "src/__mocks__/events";

const content = rowData[0];

const EventDetail = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="이벤트 상세" back />
      <Stack spacing={2} ml={2} mr={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1">{content.title}</Typography>
          <Typography variant="caption">
            {moment(content.createdAt).format("YYYY/MM/DD")}
          </Typography>
        </Stack>
        {content.mainImg && <img src={content.mainImg} alt="이벤트 이미지" />}
        <Typography variant="body1">{content.content}</Typography>
      </Stack>
    </Paper>
  );
};

export default EventDetail;
