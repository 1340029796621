import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

export const MATCH_STATUS = {
  DF: "DEFAULT",
  SM: "SEND_MESSAGE",
  MT: "MATCH",
  CL: "CLOSE"
};

const ProfileAction = (props) => {
  const { status } = props;
  switch (status) {
    case MATCH_STATUS.SM:
      return (
        <Box width="100%" bgcolor="grey" color="white" mb={2}>
          <Typography variant="caption">
            여름이조아요님에게 쪽지를 보냈습니다
          </Typography>
        </Box>
      );
    case MATCH_STATUS.MT:
      return (
        <Stack alignItems="center">
          <Box width="100%" bgcolor="grey" color="white" mb={2}>
            <Typography variant="caption">
              여름이조아요님과 매칭 되었습니다.
            </Typography>
          </Box>
          <Typography variant="caption">
            대화가 잘 통하는지 알아가봐요!
          </Typography>
          <Button fullWidth variant="outlined">
            대화 시작하기
          </Button>
        </Stack>
      );
    case MATCH_STATUS.CL:
      return (
        <Box width="100%" bgcolor="grey" color="white" mb={2}>
          <Typography variant="caption">
            여름이조아요님과 대화가 종료 되었습니다.
          </Typography>
        </Box>
      );
    default:
      return (
        <Stack alignItems="center">
          <Typography variant="caption">당신의 호감을 표현하세요!</Typography>
          <Button fullWidth variant="outlined">
            쪽지 보내기
          </Button>
        </Stack>
      );
  }
};

export default ProfileAction;
