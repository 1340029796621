import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import { MuiDataGrid } from "src/components/@core";
import moment from "moment";
import rowData from "src/__mocks__/authRequestList";
import { TitledPaper } from "src/widgets";

const columns = [
  { field: "id", headerName: "No", type: "number", width: 70 },
  { field: "type", headerName: "유형", sortable: true, width: 130 },
  { field: "groupName", headerName: "이름", width: 200 },
  {
    field: "email",
    headerName: "이메일",
    width: 300
  },
  {
    field: "socialTypeName",
    headerName: "소셜",
    width: 120
  },
  {
    field: "createdAt",
    headerName: "요청일자",
    sortable: true,
    width: 180,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("YYYY/MM/DD")}`
  }
];

const PaymentList = () => {
  const navigate = useNavigate();

  const handleRowClick = (params) => {
    // TODO
    // navigate(`/payment/${params.id}`);
  };

  return (
    <TitledPaper title="결제 관리">
      <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="type-select-label">유형</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              label="Status"
              defaultValue={0}
            >
              <MenuItem value={0}>전체</MenuItem>
              <MenuItem value={10}>회사</MenuItem>
              <MenuItem value={20}>학교</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="status-select-label">상태</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              label="Status"
              defaultValue={0}
            >
              <MenuItem value={0}>전체</MenuItem>
              <MenuItem value={10}>완료</MenuItem>
              <MenuItem value={20}>요청</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <Button color="primary" variant="contained">
            검색
          </Button>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <MuiDataGrid
          columns={columns}
          rowData={rowData}
          onRowClick={handleRowClick}
        />
      </Box>
    </TitledPaper>
  );
};

export default PaymentList;
