import { axiosGet, axiosPut } from "src/api/interceptors";
import { createDynamicPath } from "src/utils/dataConverter";

class UserService {
  /*
   * 유저 정보 목록 조회
   */
  selectUserProfiles(params) {
    // Creating path
    const basePath = "user-profile/list";
    const dynamicPath = createDynamicPath(basePath, params);

    return axiosGet(dynamicPath).then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.uAccountId
        };
      });
    });
  }

  /*
   * 유저 계정 정보 변경 및 push notification
   */
  updateUserStatus(payload) {
    return axiosPut("user/userStatus", payload).then((response) => {
      return response;
    });
  }

  /*
   * 유저 정보 업데이트
   */
  updateUserInfo(payload) {
    return axiosPut("user", payload).then((response) => {
      return response;
    });
  }
}

export default new UserService();
