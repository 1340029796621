// ** React Imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ** Redux Imports
import { useQuery } from "react-query";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Redux Imports
import { useDispatch } from "react-redux";
import { setContent } from "src/store/contentSlice";
import UserController from "src/controller/UserController";

// ** Widget Imports
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { GridContainer } from "src/components";
import { TitledPaper } from "src/widgets";

// ** Util Imports
import { API_RESULT_ACTION } from "src/constant/commonCode";

const columns = [
  {
    field: "id",
    type: "number",
    headerName: "ID",
    width: 110
  },
  { field: "userStatus", headerName: "계정 상태", sortable: true },
  { field: "snsName", headerName: "가입한 소셜", sortable: true },
  { field: "company", headerName: "회사", sortable: true },
  { field: "userAge", type: "number", headerName: "나이", sortable: true },
  {
    field: "gender",
    headerName: "성별",
    sortable: true,
    valueGetter: ({ value }) =>
      value != null && value == "F" ? "여성" : "남성"
  },
  { field: "meetPrefer", headerName: "선호 만남 타입", sortable: true },
  { field: "region", headerName: "지역", sortable: true },
  { field: "userNickName", headerName: "닉네임", sortable: true },
  {
    field: "createTime",
    type: "date",
    headerName: "가입일",
    sortable: true,
    width: 110,
    valueGetter: ({ value }) => new Date(value)
  },
  {
    field: "lastAccessTime",
    type: "date",
    headerName: "최근 로그인",
    sortable: true,
    width: 110,
    valueGetter: ({ value }) => new Date(value)
  }
];

/*
 * 유저 목록
 */
function UserList() {
  // ** Hook
  const toast = useToast();
  const navigate = useNavigate();

  // ** Store
  const dispatch = useDispatch();

  // ** State
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10
  });
  const [searchConditions, setSearchConditions] = useState({
    uAccountId: "",
    userStatus: "06", // 계정 상태, 가입 완료를 기본으로 노출
    careerGb: "00", // 이메일 인증 타입
    snsNameId: "00", // 가입한 소셜
    gender: "00", // 성별
    meetPreferCd: "00", // 선호 만남 타입
    regionCd: "00", // 지역
    userNickName: "",
    days: "00", // 가입한 날짜
    lastAccessTime: "00" // 최근 로그인
  });

  const {
    uAccountId,
    userStatus,
    careerGb,
    snsNameId,
    gender,
    meetPreferCd,
    regionCd,
    userNickName,
    days,
    lastAccessTime
  } = searchConditions;

  // ** Fetch
  const {
    isLoading,
    refetch,
    data: rowData
  } = useQuery("users", () =>
    UserController.getUserProfiles(searchConditions, paginationModel)
  );

  const handleChangeSearchConditions = (e) => {
    const { name, value } = e.target;
    setSearchConditions({
      ...searchConditions,
      [name]: value
    });
  };

  // 미사용
  // 검색 버튼 클릭시
  const handleClickSearch = () => {
    const values = Object.values(searchConditions);
    // 검색 조건이 전체(00)또는 빈값("")이 아닌 경우
    const searchConditionCount = values.filter(
      (item) => item != "00" && item != ""
    );
    // searchText 포함 검색 조건이 2개 이상 선택 된 경우
    if (searchConditionCount.length > 1) {
      console.log("PASS");
      // refetch();
    } else {
      toast.error(API_RESULT_ACTION.MORE_THAN_2);
    }
  };

  // Grid row 클릭시 상세 페이지 이동
  const handleRowClick = (params, event, details) => {
    // 현재 데이터 store에 저장
    dispatch(setContent(params.row));
    // 상세 페이지 이동
    navigate(`/user/${params.id}`);
  };

  return (
    <TitledPaper title="User">
      <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <TextField
            fullWidth
            label="UserID"
            name="uAccountId"
            value={uAccountId}
            onChange={handleChangeSearchConditions}
          />
          <FormControl fullWidth>
            <InputLabel id="userStatus-select-label">계정 상태</InputLabel>
            <Select
              labelId="userStatus-select-label"
              id="userStatus"
              name="userStatus"
              label="userStatus"
              value={userStatus}
              onChange={handleChangeSearchConditions}
            >
              <MenuItem value={"00"}>전체</MenuItem>
              <MenuItem value={"01"}>소셜 가입 완료</MenuItem>
              <MenuItem value={"02"}>이메일 인증 요청</MenuItem>
              <MenuItem value={"03"}>이메일 인증 완료</MenuItem>
              <MenuItem value={"04"}>프로필 등록 완료</MenuItem>
              {/* 회사 인증 대기 화면 별도 */}
              {/* <MenuItem value={"05"}>회사 인증 대기</MenuItem> */}
              <MenuItem value={"06"}>가입 완료</MenuItem>
              <MenuItem value={"07"}>휴면</MenuItem>
              {/* 삭제 대기 사용 안함 */}
              {/* <MenuItem value={"08"}>삭제대기</MenuItem> */}
              <MenuItem value={"09"}>정지</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="snsNameId-select-label">가입한 소셜</InputLabel>
            <Select
              labelId="snsNameId-select-label"
              id="snsNameId"
              name="snsNameId"
              label="snsNameId"
              value={snsNameId}
              onChange={handleChangeSearchConditions}
            >
              <MenuItem value={"00"}>전체</MenuItem>
              <MenuItem value={"01"}>Google</MenuItem>
              <MenuItem value={"02"}>Kakao</MenuItem>
              <MenuItem value={"03"}>Naver</MenuItem>
              <MenuItem value={"04"}>Apple</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="careerGb-select-label">이메일 인증 타입</InputLabel>
            <Select
              labelId="careerGb-select-label"
              id="careerGb"
              name="careerGb"
              label="careerGb"
              value={careerGb}
              onChange={handleChangeSearchConditions}
            >
              <MenuItem value={"00"}>전체</MenuItem>
              <MenuItem value={"01"}>Company</MenuItem>
              <MenuItem value={"02"}>University</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="gender-select-label">성별</InputLabel>
            <Select
              labelId="gender-select-label"
              id="gender"
              name="gender"
              label="gender"
              value={gender}
              onChange={handleChangeSearchConditions}
            >
              <MenuItem value={"00"}>전체</MenuItem>
              <MenuItem value={"M"}>남</MenuItem>
              <MenuItem value={"F"}>여</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="meetPreferCd-select-label">
              선호 만남 타입
            </InputLabel>
            <Select
              labelId="meetPreferCd-select-label"
              id="meetPreferCd"
              name="meetPreferCd"
              label="meetPreferCd"
              value={meetPreferCd}
              onChange={handleChangeSearchConditions}
            >
              <MenuItem value={"00"}>전체</MenuItem>
              <MenuItem value={"01"}>평일커피</MenuItem>
              <MenuItem value={"02"}>주말커피</MenuItem>
              <MenuItem value={"03"}>평일맥주</MenuItem>
              <MenuItem value={"04"}>주말맥주</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="regionCd-select-label">지역</InputLabel>
            <Select
              labelId="regionCd-select-label"
              id="regionCd"
              name="regionCd"
              label="regionCd"
              value={regionCd}
              onChange={handleChangeSearchConditions}
            >
              <MenuItem value={"00"}>전체</MenuItem>
              <MenuItem value={"01"}>서울</MenuItem>
              <MenuItem value={"02"}>인천</MenuItem>
              <MenuItem value={"03"}>경기남도</MenuItem>
              <MenuItem value={"04"}>경기북도</MenuItem>
              <MenuItem value={"05"}>세종</MenuItem>
              <MenuItem value={"06"}>대전</MenuItem>
              <MenuItem value={"07"}>충남</MenuItem>
              <MenuItem value={"08"}>충북</MenuItem>
              <MenuItem value={"09"}>강원</MenuItem>
              <MenuItem value={"10"}>부산</MenuItem>
              <MenuItem value={"11"}>울산</MenuItem>
              <MenuItem value={"12"}>대구</MenuItem>
              <MenuItem value={"13"}>경남</MenuItem>
              <MenuItem value={"15"}>경북</MenuItem>
              <MenuItem value={"14"}>광주</MenuItem>
              <MenuItem value={"16"}>전남</MenuItem>
              <MenuItem value={"17"}>전북</MenuItem>
              <MenuItem value={"18"}>제주</MenuItem>
              <MenuItem value={"19"}>해외</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="닉네임"
            name="userNickName"
            value={userNickName}
            onChange={handleChangeSearchConditions}
          />
          <FormControl fullWidth>
            <InputLabel id="days-select-label">가입일</InputLabel>
            <Select
              labelId="days-select-label"
              id="days"
              name="days"
              label="days"
              value={days}
              onChange={handleChangeSearchConditions}
            >
              <MenuItem value={"00"}>전체</MenuItem>
              <MenuItem value={"01"}>7일 이내</MenuItem>
              <MenuItem value={"02"}>14일 이내</MenuItem>
              <MenuItem value={"03"}>30일 이내</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="lastAccessTime-select-label">
              최근 로그인
            </InputLabel>
            <Select
              labelId="lastAccessTime-select-label"
              id="lastAccessTime"
              name="lastAccessTime"
              label="lastAccessTime"
              value={lastAccessTime}
              onChange={handleChangeSearchConditions}
            >
              <MenuItem value={"00"}>전체</MenuItem>
              <MenuItem value={"01"}>7일 이내</MenuItem>
              <MenuItem value={"02"}>14일 이내</MenuItem>
              <MenuItem value={"03"}>30일 이내</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <Button color="primary" variant="contained" onClick={refetch}>
            검색
          </Button>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <GridContainer
          columns={columns}
          rowData={rowData || []}
          onRowClick={handleRowClick}
        />
      </Box>
    </TitledPaper>
  );
}

export default UserList;
