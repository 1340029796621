import React from "react";
import {
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import moment from "moment";
import rowData from "src/__mocks__/events";

const renderTitle = ({ type, eventDate, title }) => {
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Chip size="small" label={type} />
        <Typography variant="caption">{eventDate}</Typography>
      </Stack>
      <Typography variant="subtitle1">{title}</Typography>
    </>
  );
};

const EventList = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="이벤트" back />
      <Stack>
        <List>
          {rowData.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton href={`/app/event/${item.id}`}>
                <ListItemText
                  primary={renderTitle({
                    type: item.type,
                    title: item.title,
                    eventDate: `${moment(item.startedAt).format(
                      "YYYY/MM/DD"
                    )} ~ ${moment(item.endedAt).format("YYYY/MM/DD")}`
                  })}
                />
              </ListItemButton>
              <IconButton href="/app/event/1">
                <ChevronRightIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Paper>
  );
};

export default EventList;
