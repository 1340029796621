import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";

export default function GroupCodeFormDialog({
  open,
  data,
  onClose,
  onConfirm
}) {
  const [formData, setFormData] = useState({
    commonGroupCodeId: "",
    groupCode: "",
    groupName: "",
    groupCodeDesc: "",
    useYn: ""
  });
  const { commonGroupCodeId, groupCode, groupName, groupCodeDesc, useYn } =
    formData;

  // Input field handler
  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // 수정일 경우 Form Data 업데이트
  const handleSetFormData = (newFormData) => {
    setFormData(newFormData);
  };

  const handleClose = () => {
    // Form Data 초기화
    handleSetFormData({
      commonGroupCodeId: "",
      groupCode: "",
      groupName: "",
      groupCodeDesc: "",
      useYn: ""
    });

    // Dialog 닫기
    onClose && onClose();
  };

  // 유입되는 데이터 확인
  useEffect(() => {
    data && handleSetFormData(data);
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          onConfirm(formData);
        }
      }}
    >
      <DialogTitle>그룹 코드 상세</DialogTitle>
      <DialogContent>
        {commonGroupCodeId != "" && (
          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            id="commonGroupCodeId"
            name="commonGroupCodeId"
            label="ID"
            value={commonGroupCodeId}
            onChange={handleChangeFormData}
            disabled
          />
        )}
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="groupCode"
          name="groupCode"
          label="그룹 코드"
          value={groupCode}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="groupName"
          name="groupName"
          label="그룹 코드 명"
          value={groupName}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="groupCodeDesc"
          name="groupCodeDesc"
          label="그룹 코드 설명"
          value={groupCodeDesc}
          onChange={handleChangeFormData}
          required
        />
        <FormControl margin="dense">
          <InputLabel id="use-select-label">사용 여부</InputLabel>
          <Select
            labelId="use-select-label"
            id="useYn"
            name="useYn"
            label="useYn"
            value={useYn}
            onChange={handleChangeFormData}
          >
            <MenuItem value={"Y"}>Y</MenuItem>
            <MenuItem value={"N"}>N</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
