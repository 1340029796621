import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";

class MessageManageService {
  /*
   * 메시지 목록 조회
   * String messageType 메시지 타입
   */
  selectMessageList() {
    return axiosGet("/message-manage").then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.messageManageId
        };
      });
    });
  }

  /*
   * 메시지 1건 신규 추가
   * String messageType 메시지 타입
   * String messageTitle 메시지 내용
   */
  insertMessage(payload) {
    return axiosPost("/message-manage", payload).then((response) => {
      return response;
    });
  }

  /*
   * 메시지 1건 수정
   * messageManageId 메시지 ID
   * String messageType 메시지 타입
   * String messageTitle 메시지 내용
   */
  updateMessage(payload) {
    return axiosPut("/message-manage", payload).then((response) => {
      return response;
    });
  }

  /*
   * 메시지 1건 삭제
   * messageManageId 메시지 id
   */
  deleteMessage(messageManageId) {
    return axiosDelete(`/message-manage/${messageManageId}`).then(
      (response) => {
        return response;
      }
    );
  }
}

export default new MessageManageService();
