import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Typography
} from "@mui/material";

const ProfileTagCard = (props) => {
  const { user, read, my } = props;
  const {
    avatar,
    meetingTypeName,
    gender,
    age,
    job,
    company,
    characteristic,
    mysellf,
    hobby
  } = user;

  switch (read) {
    case true:
      return (
        <Card variant="outlined" sx={{ m: 2 }}>
          <CardHeader
            avatar={
              <Avatar
                src={`/static/user/${
                  gender == "남" ? "male" : "female"
                }_${avatar}.png`}
                sx={{
                  height: 80,
                  mb: 2,
                  width: 80
                }}
              />
            }
            title={
              <Stack direction="row">
                <Chip color="primary" label={meetingTypeName} />
              </Stack>
            }
            subheader={
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle1">{company}</Typography>,
                <Typography variant="body1" sx={{ pl: 1 }}>
                  {job}
                </Typography>
                ,
                <Typography variant="body2" sx={{ pl: 1 }}>
                  {age}
                </Typography>
              </Stack>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Stack alignItems="center" spacing={1}>
              <Box width="85%">
                {characteristic.length > 0 &&
                  characteristic
                    .slice(0, 2)
                    .map((item, idx) => (
                      <Chip
                        size="small"
                        key={idx}
                        sx={{ m: 1 }}
                        label={`#${item}`}
                      />
                    ))}
                {mysellf.length > 0 &&
                  mysellf
                    .slice(0, 2)
                    .map((item, idx) => (
                      <Chip
                        size="small"
                        key={idx}
                        sx={{ m: 1 }}
                        label={`#${item}`}
                      />
                    ))}
              </Box>
              {!my && (
                <Box width="100%">
                  <Typography variant="subtitle2">
                    22시간 후 사라집니다
                  </Typography>
                </Box>
              )}
            </Stack>
          </CardContent>
        </Card>
      );
    case false:
      return (
        <Card variant="outlined" sx={{ m: 2 }}>
          <CardContent>
            <Stack alignItems="center" spacing={1}>
              <Box p={2}>
                <Typography variant="h6">{company}</Typography>
                <Stack direction="row">
                  <Typography variant="subtitle1">{job}</Typography>,
                  <Typography variant="body1">{age}</Typography>
                </Stack>
              </Box>
              <Box width="100%">
                <Typography variant="subtitle2">
                  22시간 후 사라집니다
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      );
  }
};

export default ProfileTagCard;
