import React from "react";
import { Box, Stack } from "@mui/material";

const Splash = () => {
  return (
    <Stack m={3} spacing={2}>
      <Box>Splash</Box>
    </Stack>
  );
};

export default Splash;
