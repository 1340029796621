/*
 * Convert Data From String to Boolean
 */
const convertToBoolean = (val) => {
  return val != null && val.toString() == "Y" ? true : false;
};

/*
 * Convert Data From String Boolean to Y/N
 */
const convertToYN = (val) => {
  return val != null && "boolean" === typeof val && val && val ? "Y" : "N";
};

/*
 * Create a dynamic URI path based on the provided payload object
 */
const createDynamicPath = (basePath, params) => {
  // Case: Specific
  // const queryParts = [];

  // if (params.authYn) {
  //   queryParts.push(`authYn=${params.authYn}`);
  // }
  // if (params.useYn) {
  //   queryParts.push(`useYn=${params.useYn}`);
  // }
  // if (params.companyGb) {
  //   queryParts.push(`companyGb=${params.companyGb}`);
  // }
  // if (params.companyEmail) {
  //   queryParts.push(`companyEmail=${params.companyEmail}`);
  // }
  // if (params.searchTxt) {
  //   queryParts.push(`searchTxt=${params.searchTxt}`);
  // }

  // Case: Global
  // value != null
  // value != undefined
  // value != ""
  // value != "00": 00은 전체를 뜻함
  const queryParts = Object.entries(params)
    .filter(
      ([key, value]) =>
        value !== null && value !== undefined && value !== "" && value !== "00"
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    );

  const queryString = queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
  return `${basePath}${queryString}`;
};

export { convertToBoolean, convertToYN, createDynamicPath };
