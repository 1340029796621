import { MEETING_TYPE } from "./commonCode";

const returnMeetingType = (meetingType) => {
  switch (meetingType) {
    case MEETING_TYPE.WEEKDAY_COFFEE:
      return "평일 커피 한잔";
    case MEETING_TYPE.WEEKEND_COFFEE:
      return "주말 커피 한잔";
    case MEETING_TYPE.WEEKDAY_BEER:
      return "평일 맥주 한잔";
    case MEETING_TYPE.WEEKEND_BEER:
      return "주말 맥주 한잔";
  }
};

export { returnMeetingType };
