export default [
  {
    id: 1,
    description: "1일 출석 보상 풍선이 지급 되었습니다.",
    createdAt: 1555016400000
  },
  {
    id: 2,
    description: "매칭 취소에 대한 풍선 환불이 되었습니다.",
    createdAt: 1555016400000
  },
  {
    id: 3,
    description: "매칭 취소에 대한 풍선 환불이 되었습니다.",
    createdAt: 1555016400000
  }
];
