import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT; // Set default baseURL for all axios requests from environment variable
axios.defaults.headers.common["Content-Type"] = "application/json";

// GET
const axiosGet = (url) => {
  // 로그인 토큰
  const AUTH_TOKEN = sessionStorage.getItem("authToken");

  // 로그인 토큰이 있는 경우
  if (AUTH_TOKEN) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
  }

  // api 호출
  return axios.get(url).then((response) => {
    return response.data;
  });
};

// POST
const axiosPost = (url, payload, isFormData) => {
  // 로그인 토큰
  const AUTH_TOKEN = sessionStorage.getItem("authToken");
  // Form Data
  const FORM_DATA = isFormData;

  // api 호출
  return axios({
    method: "post",
    url: url,
    headers: {
      // 로그인 토큰이 있는 경우
      ...(AUTH_TOKEN && { Authorization: "Bearer " + AUTH_TOKEN }),
      // Form Data로 전송하는 경우
      ...(FORM_DATA && { "Content-Type": "multipart/form-data" })
    },
    data: payload
  }).then((response) => {
    return response.data;
  });
};

// PUT
const axiosPut = (url, payload, isFormData) => {
  // 로그인 토큰
  const AUTH_TOKEN = sessionStorage.getItem("authToken");
  // Form Data
  const FORM_DATA = isFormData;

  // api 호출
  return axios({
    method: "put",
    url: url,
    headers: {
      // 로그인 토큰이 있는 경우
      ...(AUTH_TOKEN && { Authorization: "Bearer " + AUTH_TOKEN }),
      // Form Data로 전송하는 경우
      ...(FORM_DATA && { "Content-Type": "multipart/form-data" })
    },
    data: payload
  }).then((response) => {
    return response.data;
  });
};

// DELETE
const axiosDelete = (url) => {
  // 로그인 토큰
  const AUTH_TOKEN = sessionStorage.getItem("authToken");

  // 로그인 토큰이 있는 경우
  if (AUTH_TOKEN) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
  }

  // api 호출
  return axios.delete(url).then((response) => {
    return response.data;
  });
};

export { axiosGet, axiosPost, axiosPut, axiosDelete };
