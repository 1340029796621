// ** React Imports
import React, { useEffect, useState } from "react";

// ** Redux Imports
import { useQuery, useMutation } from "react-query";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Redux Imports
import CareerController from "src/controller/CareerController";

// ** Widget Imports
import { Box, Button, FormControl, TextField } from "@mui/material";
import { GridContainer } from "src/components";
import { CareerFormDialog, TitledPaper } from "src/widgets";

// Utils
import {
  API_RESULT,
  API_RESULT_ACTION,
  CAREER_TYPE
} from "src/constant/commonCode";

const columns = [
  { field: "id", headerName: "ID", type: "number", sortable: true },
  { field: "careerCd", headerName: "Career 코드", sortable: true },
  { field: "careerEmail", headerName: "이메일/도메인", sortable: true },
  { field: "careerName", headerName: "국문 명", sortable: true },
  {
    field: "careerOriginalName",
    headerName: "실제 국문 명",
    sortable: true
  },
  { field: "careerNameEn", headerName: "영문 명", sortable: true },
  { field: "careerGb", headerName: "구분", sortable: true },
  { field: "authYn", headerName: "확인 여부", sortable: true },
  {
    field: "useYn",
    headerName: "사용 여부",
    sortable: true
  }
];

export default function CareerList(props) {
  // ** Props
  const { menuId } = props;

  // ** Hook
  const toast = useToast();

  // ** State
  // 현재 메뉴
  const [currentMenuID, setCurrentMenuID] = useState({
    menuId: null,
    menuName: null
  });
  // 코드 상세 Form Dialog 오픈 여부
  const [careerFormDialogOpen, setCareerFormDialogOpen] = useState(false);
  // Selected Row to delete with API when delete button clicked
  const [selectedRow, setSelectedRow] = useState(null);
  // Search conditions
  const [searchConditions, setSearchConditions] = useState({
    searchText: ""
  });

  // ** Fetch
  // 아이템 목록 조회
  const {
    isLoading,
    refetch,
    data: rowData
  } = useQuery(
    menuId,
    () => CareerController.getCareerList(menuId, searchConditions),
    {
      enabled: menuId != null
    }
  );

  // 신규 1개 아이템 추가
  const { mutate: AddItem } = useMutation({
    mutationFn: CareerController.addCareer,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.ADD);
      setCareerFormDialogOpen(false);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 정보 수정
  const { mutate: EditItem } = useMutation({
    mutationFn: CareerController.editCareer,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.ADD);
      setCareerFormDialogOpen(false);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 삭제
  const { mutate: DeleteItem } = useMutation({
    mutationFn: CareerController.deleteCareer,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.DELETE);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // Input field handler
  const handleChangeSearchConditions = (e) => {
    const { name, value } = e.target;
    setSearchConditions((prev) => ({ ...prev, [name]: value }));
  };

  // Grid row 클릭시 선택된 데이터 저장
  const handleRowClick = (params) => {
    // 선택된 로우 저장
    setSelectedRow(params);
  };

  // 신규 아이템 Dialog Open
  const handleClickCareerFormDialogHandler = () => {
    setCareerFormDialogOpen(!careerFormDialogOpen);
  };

  // 신규 아이템 추가
  const handleClickCreate = (newFormData) => {
    AddItem(newFormData);
  };

  // 신규 아이템 추가 버튼 클릭
  const handleClickNew = () => {
    // 선택된 로우 비우기
    setSelectedRow(null);
    // Dialog 열기
    handleClickCareerFormDialogHandler();
  };

  // 아이템 수정 dialog open
  const handleClickEdit = () => {
    // 선택된 아이템 여부 확인
    if (selectedRow?.id) {
      // Dialog 열기
      handleClickCareerFormDialogHandler();
    } else {
      toast.warning(API_RESULT_ACTION.NO_ITEM);
    }
  };

  // 아이템 수정 후 저장
  const handleClickSave = (e) => {
    // TODO
  };

  // 아이템 삭제
  const handleClickDelete = () => {
    // 선택된 아이템 여부 확인
    if (selectedRow?.id) {
      DeleteItem(selectedRow?.id);
    } else {
      toast.warning(API_RESULT_ACTION.NO_ITEM);
    }
  };

  // pathname 조회하여 현재 메뉴 설정
  const handleCurrentMenuID = () => {
    const currentMenuName =
      menuId == CAREER_TYPE.COMPANY ? "직장 관리" : "학교 관리";
    setCurrentMenuID({
      menuId: menuId,
      menuName: currentMenuName
    });
  };

  useEffect(() => {
    menuId && handleCurrentMenuID();
  }, [menuId]);

  return (
    <TitledPaper title={currentMenuID?.menuName ?? "TITLE"}>
      <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <FormControl fullWidth>
            <TextField
              fullWidth
              inputProps={{
                form: {
                  autocomplete: "off"
                }
              }}
              id="searchText"
              name="searchText"
              variant="outlined"
              label="검색"
              onChange={handleChangeSearchConditions}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <Button color="primary" variant="outlined" onClick={handleClickNew}>
            추가
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleClickEdit}
          >
            수정
          </Button>
          <Button color="error" variant="contained" onClick={handleClickDelete}>
            삭제
          </Button>
          <Button color="primary" variant="contained" onClick={refetch}>
            검색
          </Button>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <GridContainer
          columns={columns}
          rowData={rowData || []}
          onRowClick={handleRowClick}
        />
      </Box>
      <CareerFormDialog
        open={careerFormDialogOpen}
        data={selectedRow?.row}
        onClose={handleClickCareerFormDialogHandler}
        onConfirm={handleClickCreate}
      />
    </TitledPaper>
  );
}
