import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";

export default function AvatarFormDialog(props) {
  const { open, data, onClose, onConfirm } = props;
  const [formData, setFormData] = useState({
    avatarInfoId: "",
    avatarId: "",
    avatarName: "",
    avatarGender: "M"
  });
  const { avatarInfoId, avatarId, avatarName, avatarGender } = formData;

  // Input field handler
  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // 수정일 경우 Form Data 업데이트
  const handleSetFormData = (newFormData) => {
    setFormData(newFormData);
  };

  const handleClose = () => {
    // Form Data 초기화
    handleSetFormData({
      avatarInfoId: "",
      avatarId: "",
      avatarName: "",
      avatarGender: "M"
    });

    // Dialog 닫기
    onClose && onClose();
  };

  // 유입되는 데이터 확인
  useEffect(() => {
    data && handleSetFormData(data);
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          onConfirm(formData);
        }
      }}
    >
      <DialogTitle>아바타 상세</DialogTitle>
      <DialogContent>
        {avatarInfoId != "" && (
          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            id="avatarInfoId"
            name="avatarInfoId"
            label="ID"
            value={avatarInfoId}
            onChange={handleChangeFormData}
            disabled
          />
        )}
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="avatarId"
          name="avatarId"
          label="코드"
          placeholder="M01"
          value={avatarId}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="avatarName"
          name="avatarName"
          label="파일 명"
          placeholder="M01.png"
          value={avatarName}
          onChange={handleChangeFormData}
          required
        />
        <FormControl margin="dense">
          <InputLabel id="avatar-gender-select-label">성별</InputLabel>
          <Select
            labelId="avatarGender-select-label"
            id="avatarGender"
            name="avatarGender"
            label="avatarGender"
            value={avatarGender}
            onChange={handleChangeFormData}
          >
            <MenuItem value={"M"}>M</MenuItem>
            <MenuItem value={"F"}>F</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
