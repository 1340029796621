// ** React Imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Store Imports
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { setContent } from "src/store/contentSlice";
import UserController from "src/controller/UserController";

// ** Widget Imports
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { GridContainer } from "src/components";
import { TitledPaper } from "src/widgets";

// ** Util Imports
import { CareerWaitingList } from "../Career";
import { API_RESULT, API_RESULT_ACTION } from "src/constant/commonCode";

/*
 * 가입 대기 유저 목록
 */
function UserWaitingList() {
  // ** Hook
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // ** Store
  const dispatch = useDispatch();

  // ** State
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10
  });
  const [searchConditions, setSearchConditions] = useState({
    userStatus: "05" // 계정 상태, 회사 인증 대기만 조회
  });

  // ** Fetch
  const {
    isLoading,
    refetch,
    data: rowData
  } = useQuery("waitingUsers", () =>
    UserController.getUserProfiles(searchConditions, paginationModel)
  );

  // 1개 아이템 정보 수정
  const { mutate: ConfirmItem } = useMutation({
    mutationFn: UserController.changeUserStatus,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.CONFIRM);
      queryClient.invalidateQueries({ queryKey: "waitingUsers" });
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // Grid row 클릭시 상세 페이지 이동
  const handleRowClick = (params) => {
    // 현재 데이터 store에 저장
    dispatch(setContent(params.row));
    // 상세 페이지 이동
    navigate(`/user/${params.id}`);
  };

  // 유저 정보 업데이트
  const handleClickConfirm = (row) => {
    ConfirmItem({
      uAccountId: row.uAccountId,
      userStatus: "06", // 가입 완료
      messageType: "01" // 가입승인완료
    });
  };

  const columns = [
    { field: "id", type: "number", headerName: "ID", width: 110 },
    { field: "careerEmail", headerName: "이메일", width: 200 },
    { field: "company", headerName: "Career", width: 100 },
    { field: "region", headerName: "지역" },
    {
      field: "gender",
      headerName: "성별",
      valueGetter: ({ value }) =>
        value != null && value == "F" ? "여성" : "남성"
    },
    { field: "userNickName", headerName: "닉네임" },
    {
      field: "createTime",
      type: "dateTime",
      headerName: "가입일",
      width: 220,
      valueGetter: (params) => new Date(params.row.createTime)
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      width: 160,
      getActions: (params) => {
        return [
          <Button
            key="action-confirm"
            variant="contained"
            onClick={() => handleClickConfirm(params.row)}
            disabled={params.row.company == "" || params.row.company == null}
          >
            승인
          </Button>
        ];
      }
    }
  ];

  return (
    <TitledPaper title="가입 대기">
      {/* 신규 Career 목록 */}
      <CareerWaitingList />
      <Stack direction="row" alignItems={"center"}>
        <Typography>가입 대기 유저</Typography>
        <IconButton onClick={refetch}>
          <RefreshIcon />
        </IconButton>
      </Stack>
      <GridContainer
        height={300}
        columns={columns}
        rowData={rowData || []}
        onRowClick={handleRowClick}
      />
    </TitledPaper>
  );
}

export default UserWaitingList;
