import React from "react";
import { AuthContext } from "src/context";

export const AuthContextProvider = ({ children }) => {
  // sessionStorage에 저장된 authToken 값을 가져옴
  const authToken = sessionStorage.getItem("authToken");

  // 추후 필요한 경우 사용
  // sessionStorage에 저장된 유저 정보를 가져옴
  // const userInfo = sessionStorage.getItem("userInfo");

  const value = {
    loggedIn: authToken ?? false
    // 추후 필요한 경우 사용
    // loggedUser: userInfo != "undefined" ? userInfo : null
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
