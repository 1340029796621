// ** React Imports
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Redux Imports
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { contentSelector } from "src/store/contentSlice";
import ContentController from "src/controller/ContentController";

// Widget Imports
import { Box, Typography, Grid, Button } from "@mui/material";

// Util Imports
import { API_RESULT, API_RESULT_ACTION } from "src/constant/commonCode";
import { TitledPaper } from "src/widgets";

export default function TOSView() {
  // ** Hook
  const toast = useToast();
  const navigate = useNavigate();
  const { adminNotiId } = useParams();

  // ** Store
  const currentContent = useSelector(contentSelector).currentContent;

  // ** State
  const [formValues, setFormValues] = useState({
    adminNotiId: null,
    notiTitle: null,
    notiContent: null,
    viewDate: null,
    useYn: null
  });

  // Delete a data
  const { mutate: DeleteItem } = useMutation({
    mutationFn: () => ContentController.deleteContent(adminNotiId),
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.DELETE);
    },
    onSettled: () => {
      navigate("/tos");
    },
    onError: (error) => {
      console.log(error);
    }
  });

  // 수정 화면 이동
  const handleMovePage = () => {
    // 상세 페이지 이동
    navigate(`/tos/edit/${adminNotiId}`);
  };

  const handleUpdateContent = () => {
    // 컨텐츠 업데이트
    const { adminNotiId, notiTitle, notiContent, viewDate, useYn } =
      currentContent;

    setFormValues((prev) => ({
      ...prev,
      adminNotiId: adminNotiId,
      notiTitle: notiTitle,
      notiContent: notiContent,
      viewDate: viewDate,
      useYn: useYn
    }));
  };

  useEffect(() => {
    // currentContent 데이터가 있다면 불러온다.
    Object.keys(currentContent).length > 0 && handleUpdateContent();
  }, [currentContent]);

  return (
    <TitledPaper title="이용약관">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {/* ID */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">ID</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{adminNotiId}</Typography>
            </Grid>
            {/* 노출 일자 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">노출 일자</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{formValues.viewDate}</Typography>
            </Grid>
            {/* 제목 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">제목</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{formValues.notiTitle}</Typography>
            </Grid>
            {/* 내용 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">내용</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{formValues.notiContent}</Typography>
            </Grid>
            {/* 노출 여부 */}
            <Grid item xs={4}>
              <Typography variant="subtitle1">사용 여부</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {formValues.useYn == "Y" ? "사용" : "미사용"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px"
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleMovePage}
            >
              수정
            </Button>
            <Button color="error" variant="outlined" onClick={DeleteItem}>
              삭제
            </Button>
          </Box>
        </Grid>
      </Grid>
    </TitledPaper>
  );
}
