import { v4 as uuid } from "uuid";

export default [
  {
    id: 1,
    useid: uuid(),
    title: "접속 중인 이성 - 프로필보기",
    increase: -1,
    total: 20,
    createdAt: 1555016400000
  },
  {
    id: 2,
    useid: uuid(),
    title: "호감도 체크",
    increase: -1,
    total: 20,
    createdAt: 1555016400000
  },
  {
    id: 3,
    useid: uuid(),
    title: "신규 가입",
    increase: -1,
    total: 20,
    createdAt: 1555016400000
  }
];
