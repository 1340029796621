import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

export default function MessageFormDialog(props) {
  const { open, data, onClose, onConfirm } = props;
  const [formData, setFormData] = useState({
    messageManageId: "",
    messageType: "",
    messageTitle: ""
  });
  const { messageManageId, messageType, messageTitle } = formData;

  // Input field handler
  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // 수정일 경우 Form Data 업데이트
  const handleSetFormData = (newFormData) => {
    setFormData(newFormData);
  };

  const handleClose = () => {
    // Form Data 초기화
    handleSetFormData({
      messageManageId: "",
      messageType: "",
      messageTitle: ""
    });

    // Dialog 닫기
    onClose && onClose();
  };

  // 유입되는 데이터 확인
  useEffect(() => {
    data && handleSetFormData(data);
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          onConfirm(formData);
        }
      }}
    >
      <DialogTitle>아바타 상세</DialogTitle>
      <DialogContent>
        {messageManageId != "" && (
          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            id="messageManageId"
            name="messageManageId"
            label="ID"
            value={messageManageId}
            onChange={handleChangeFormData}
            disabled
          />
        )}
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="messageType"
          name="messageType"
          label="메시지 타입"
          value={messageType}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="messageTitle"
          name="messageTitle"
          label="메시지 내용"
          value={messageTitle}
          onChange={handleChangeFormData}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
