import { createSlice } from "@reduxjs/toolkit";

const contentSlice = createSlice({
  name: "content",
  initialState: {
    currentContent: {}
  },
  reducers: {
    setContent(state, { payload }) {
      state.currentContent = payload;
    },
    clearContent(state) {
      state.currentContent = {};
    }
  }
});

export const { setContent, clearContent } = contentSlice.actions;

export const contentSelector = (state) => state.content;

export default contentSlice.reducer;
