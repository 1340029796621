// ** React Imports
import React, { useState } from "react";

// ** Redux Imports
import { useQuery, useMutation } from "react-query";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Redux Imports
import StoreController from "src/controller/StoreController";

// ** Widget Imports
import { Box, Button } from "@mui/material";
import { GridContainer } from "src/components";
import { StoreItemFormDialog, TitledPaper } from "src/widgets";

// Utils
import { API_RESULT, API_RESULT_ACTION } from "src/constant/commonCode";

const columns = [
  { field: "id", headerName: "ID", type: "number", sortable: true },
  { field: "productName", headerName: "상품 명", sortable: true },
  { field: "productPrice", headerName: "가격", sortable: true },
  { field: "productBcnt", headerName: "보너스", sortable: true },
  { field: "productDesc", headerName: "설명", sortable: true },
  { field: "useYn", headerName: "사용 유무", sortable: true }
];

export default function StoreList() {
  // ** Hook
  const toast = useToast();

  // ** State
  // 상세 Form Dialog 오픈 여부
  const [StoreItemFormDialogOpen, setStoreItemFormDialogOpen] = useState(false);
  // Selected Row to delete with API when delete button clicked
  const [selectedRow, setSelectedRow] = useState(null);

  // ** Fetch
  // 아이템 목록 조회
  const {
    isLoading,
    refetch,
    data: rowData
  } = useQuery("store_items", () => StoreController.getStoreItemList());

  // 신규 1개 아이템 추가
  const { mutate: AddItem } = useMutation({
    mutationFn: StoreController.addStoreItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.ADD);
      setStoreItemFormDialogOpen(false);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 정보 수정
  const { mutate: EditItem } = useMutation({
    mutationFn: StoreController.editStoreItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.ADD);
      setStoreItemFormDialogOpen(false);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 삭제
  const { mutate: DeleteItem } = useMutation({
    mutationFn: StoreController.deleteStoreItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.DELETE);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // Grid row 클릭시 선택된 데이터 저장
  const handleRowClick = (params) => {
    // 선택된 로우 저장
    setSelectedRow(params);
  };

  // 신규 아이템 Dialog Open
  const handleClickStoreItemFormDialogHandler = () => {
    setStoreItemFormDialogOpen(!StoreItemFormDialogOpen);
  };

  // 신규 아이템 추가 버튼 클릭
  const handleClickNew = () => {
    // 선택된 로우 비우기
    setSelectedRow(null);
    // Dialog 열기
    handleClickStoreItemFormDialogHandler();
  };

  // 아이템 수정 dialog open
  const handleClickEdit = () => {
    // 선택된 아이템 여부 확인
    if (selectedRow?.id) {
      // Dialog 열기
      handleClickStoreItemFormDialogHandler();
    } else {
      toast.warning(API_RESULT_ACTION.NO_ITEM);
    }
  };

  // 상세 dialog에서 작업 후 저장
  const handleClickSave = (newFormData) => {
    if (newFormData?.avatarInfoId) {
      // 수정
      EditItem(newFormData);
    } else {
      // 신규
      AddItem(newFormData);
    }
  };

  // 아이템 삭제
  const handleClickDelete = () => {
    // 선택된 아이템 여부 확인
    if (selectedRow?.id) {
      DeleteItem(selectedRow?.id);
    } else {
      toast.warning(API_RESULT_ACTION.NO_ITEM);
    }
  };

  return (
    <TitledPaper title="상점">
      <Box display="flex" justifyContent="end" sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <Button color="primary" variant="outlined" onClick={handleClickNew}>
            추가
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleClickEdit}
          >
            수정
          </Button>
          <Button color="error" variant="contained" onClick={handleClickDelete}>
            삭제
          </Button>
          <Button color="primary" variant="contained" onClick={refetch}>
            검색
          </Button>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <GridContainer
          columns={columns}
          rowData={rowData || []}
          onRowClick={handleRowClick}
        />
      </Box>
      <StoreItemFormDialog
        open={StoreItemFormDialogOpen}
        data={selectedRow?.row}
        onClose={handleClickStoreItemFormDialogHandler}
        onConfirm={handleClickSave}
      />
    </TitledPaper>
  );
}
