// ** React Imports
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// ** Widget Imports
import { Box, Drawer, Hidden, List } from "@mui/material";
import NavItem from "./NavItem";

import { adminRoutes } from "src/routes";
import menuList from "src/__mocks__/menuList";

const DashboardSidebar = ({ openMobile, onMobileClose }) => {
  const location = useLocation();

  // 모바일 nav 여부 체크
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "16px"
      }}
    >
      <List component="nav">
        {adminRoutes.map((item, index) => (
          <NavItem
            key={index}
            path={item.path}
            icon={item.icon}
            title={item.title}
            child={item.children}
          />
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)"
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
