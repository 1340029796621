import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import moment from "moment";
import rowData from "src/__mocks__/notices";

export default function NoticeList() {
  return (
    <Paper elevation={0}>
      <Appbar title="공지사항" back />
      <Stack ml={2} mr={2}>
        {rowData.map((item, idx) => (
          <Accordion key={idx}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack>
                <Stack direction="row" spacing={2}>
                  <Chip size="small" label={item.type} />
                  <Typography variant="caption">
                    {moment(item.createdAt).format("YYYY/MM/DD")}
                  </Typography>
                </Stack>
                <Typography variant="subtitle1">{item.title}</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">{item.content}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Paper>
  );
}
