import ContentService from "src/services/ContentService";
import { dateFormat } from "src/utils/dateFormatter";
import { API_RESULT } from "src/constant/commonCode";

class ContentController {
  /*
   * 컨텐츠 목록 조회
   * categoryCd 메인 카테고리
   * categorySubCd 서브 카테고리
   */
  getContent(params) {
    const result = ContentService.selectContent(params);
    return result;
  }

  /*
   * text 게시글 1건 신규 추가
   * categoryCd 메인 카테고리
   * categorySubCd 서브 카테고리
   * notiTitle 제목
   * notiContent 내용
   * upfile null
   * viewDate 2024-04-30, 데이터 없는 경우 null 필수
   * startDate 2024-04-30, 데이터 없는 경우 null 필수
   * endDate 2024-04-30, 데이터 없는 경우 null 필수
   * useYn 사용 여부 Y/N
   */
  addContent(params) {
    const payload = {
      categoryCd: params.categoryCd,
      categorySubCd: params.categorySubCd,
      notiTitle: params.notiTitle,
      notiContent: params.notiContent,
      upfile: null,
      viewDate: dateFormat(params.viewDate),
      startDate: params.startDate ? dateFormat(params.startDate) : null,
      endDate: params.endDate ? dateFormat(params.endDate) : null,
      useYn: params.useYn
    };

    return new Promise((resolve, reject) => {
      ContentService.insertContent(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject("ERROR");
        }
      });
    });
  }

  /*
   * formdata 게시글 1건 신규 추가
   * categoryCd 메인 카테고리
   * categorySubCd 서브 카테고리
   * notiTitle 제목
   * notiContent 내용
   * upfile [File, File]
   * viewDate 2024-04-30
   * startDate 2024-04-30
   * endDate 2024-04-30
   * useYn 사용 여부 Y/N
   */
  addMultiContent(params) {
    // 이미지 형태 변경
    const newFormData = new FormData();
    const files = [];
    files[0] = params.imageName01 ? params.imageName01 : null; // Main image
    files[1] = params.imageName01 ? params.imageName02 : null; // Banner image
    files[2] = params.imageName01 ? params.imageName03 : null; // Not yet
    if (files.length > 0) {
      [].forEach.call(files, (f) => {
        newFormData.append("upfile", f);
      });
    }

    // FormData
    newFormData.append("categoryCd", params.categoryCd);
    newFormData.append("categorySubCd", params.categorySubCd);
    newFormData.append("notiTitle", params.notiTitle);
    newFormData.append("notiContent", params.notiContent);
    newFormData.append("viewDate", dateFormat(params.viewDate));
    newFormData.append(
      "startDate",
      params.startDate ? dateFormat(params.startDate) : null
    );
    newFormData.append(
      "endDate",
      params.endDate ? dateFormat(params.endDate) : null
    );
    newFormData.append("useYn", params.useYn);

    return new Promise((resolve, reject) => {
      ContentService.insertContent(newFormData, true).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject("ERROR");
        }
      });
    });
  }

  /*
   * text 게시글 1건 수정
   * categoryCd 메인 카테고리
   * categorySubCd 서브 카테고리
   * notiTitle 제목
   * notiContent 내용
   * upfile null
   * viewDate 2024-04-30, 데이터 없는 경우 null 필수
   * startDate 2024-04-30, 데이터 없는 경우 null 필수
   * endDate 2024-04-30, 데이터 없는 경우 null 필수
   * useYn 사용 여부 Y/N
   */
  editContent(params) {
    const payload = {
      adminNotiId: params.adminNotiId,
      categoryCd: params.categoryCd,
      categorySubCd: params.categorySubCd,
      notiTitle: params.notiTitle,
      notiContent: params.notiContent,
      upfile: null,
      viewDate: dateFormat(params.viewDate),
      startDate: params.startDate != null ? dateFormat(params.startDate) : null,
      endDate: params.endDate != null ? dateFormat(params.endDate) : null,
      useYn: params.useYn
    };

    return new Promise((resolve, reject) => {
      ContentService.updateContent(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject("ERROR");
        }
      });
    });
  }

  /*
   * formdata 게시글 1건 수정
   * categoryCd 메인 카테고리
   * categorySubCd 서브 카테고리
   * notiTitle 제목
   * notiContent 내용
   * upfile [File, File],
   * viewDate 2024-04-30,
   * startDate 2024-04-30,
   * endDate 2024-04-30,
   * useYn 사용 여부 Y/N
   */
  editMultiContent(params) {
    // 이미지 형태 변경
    const newFormData = new FormData();
    const files = [];
    files[0] = params.imageName01 ? params.imageName01 : null; // Main image
    files[1] = params.imageName01 ? params.imageName02 : null; // Banner image
    files[2] = params.imageName01 ? params.imageName03 : null; // Not yet
    if (files.length > 0) {
      [].forEach.call(files, (f) => {
        newFormData.append("upfile", f);
      });
    }

    // FormData
    newFormData.append("adminNotiId", params.adminNotiId);
    newFormData.append("categoryCd", params.categoryCd);
    newFormData.append("categorySubCd", params.categorySubCd);
    newFormData.append("notiTitle", params.notiTitle);
    newFormData.append("notiContent", params.notiContent);
    newFormData.append("viewDate", dateFormat(params.viewDate));
    newFormData.append(
      "startDate",
      params.startDate ? dateFormat(params.startDate) : null
    );
    newFormData.append(
      "endDate",
      params.endDate ? dateFormat(params.endDate) : null
    );
    newFormData.append("useYn", params.useYn);

    return new Promise((resolve, reject) => {
      ContentService.updateContent(newFormData, true).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject("ERROR");
        }
      });
    });
  }

  /*
   * 게시글 1건 삭제
   * adminNotiId 게시글 id
   */
  deleteContent(adminNotiId) {
    return new Promise((resolve, reject) => {
      ContentService.deleteContent(adminNotiId).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject("ERROR");
        }
      });
    });
  }
}

export default new ContentController();
