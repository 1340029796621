import React, { useState } from "react";
import NormalAccount from "./Account/NormalAccount";
import DormantAccount from "./Account/DormantAccount";
import GracePeriod from "./Account/GracePeriod";
import Blocked from "./Account/Blocked";
import myData from "src/__mocks__/myData";
import { USER_STATUS } from "src/constant/commonCode";

const MyPage = () => {
  const [userInfo, setUserInfo] = useState({ status: myData.userStatusCode });

  switch (userInfo.status) {
    case USER_STATUS.NORMAL:
      return <NormalAccount myData={myData} />;
    case USER_STATUS.DORMANT:
      return <DormantAccount />;
    case USER_STATUS.GRACE_PERIOD:
      return <GracePeriod />;
    case USER_STATUS.BLOCK:
      return <Blocked />;
  }
};

export default MyPage;
