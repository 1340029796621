import React from "react";
import { Box, Button, Link, Stack, Typography } from "@mui/material";

const Onboard = () => {
  return (
    <Stack alignItems="center" m={3} mt={24} spacing={32}>
      <Box width={220}>
        <Typography variant="h4">소소한 일상에 블루밍 한방울</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="subtitle1">
          소셜 가입 후 학생 또는 직장 이메일인증을 진행해야 블루밍을 이용할 수
          있습니다.
        </Typography>
        <Button fullWidth variant="outlined">
          구글 로그인
        </Button>
        <Button fullWidth variant="outlined">
          네이버 로그인
        </Button>
        <Button fullWidth variant="outlined">
          카카오 로그인
        </Button>
        <Typography variant="subtitle2">
          회원 가입 및 로그인 시{" "}
          <Link href="/app/privacy">개인정보 처리방침</Link>, 서비스{" "}
          <Link href="/app/terms">이용약관</Link>에 동의하는것을 의미합니다.
        </Typography>
      </Box>
    </Stack>
  );
};

export default Onboard;
