import React from "react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

const BasicCard = () => {
  return (
    <Card variant="outlined" sx={{ m: 2 }}>
      <CardContent>
        <Stack justifyContent="space-between" height={120}>
          <Typography variant="caption">22시간 후 사라집니다.</Typography>
          <Typography variant="body2">
            안녕하세요. 33남 직장인이에요. 반갑습니다^^ 대화나누고 싶어서 메시지
            드렸습니다.
          </Typography>
          <Typography variant="caption">삼성전자 / 개발자 / 33</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default BasicCard;
