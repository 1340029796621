import React from "react";
import { Button, Paper, Stack, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import InfoSection from "src/components/widgets/InfoSection";

const DormancyGuide = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="계정 설정" back />
      <InfoSection>
        <Typography variant="subtitle1">계정 휴면</Typography>
        <Typography variant="body2">
          휴면 계정을 신청하시면 블루밍 이용을 잠시 중단하고 이성에게 노출되지
          않으며, 언제든지 휴면해제하여 사용하실 수 있습니다
        </Typography>
        <Stack>
          <Button variant="outlined">계정 휴면 하기</Button>
          <Button size="small" href="/app/cancel-guide">
            회원 탈퇴하기
          </Button>
        </Stack>
      </InfoSection>
    </Paper>
  );
};

export default DormancyGuide;
