export default [
  {
    id: 6,
    type: "기타",
    title: "추석 연휴 기간 고객센터 휴무 공지",
    content:
      "추석 연휴 2023.09.24~2023.10.03일 동안 고객센터 운영을 하지 않습니다. 연휴 후  최대한 빠르게 응답드리겠습니다.\n행복한 추석 되세요.\n\n\n@유블루밍팀 드림",
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  },
  {
    id: 5,
    type: "업데이트",
    title: "버전 2.3.1 업데이트 되었습니다.",
    content:
      "안녕하세요. 유블루밍에서 안내드립니다.\n 보다 안정적이고 원활한 서비스 제공을 위해 Mac 최소 지원 버전 상향 조정을 진행할 예정입니다.\n더 나은 서비스 제공을 위한 쉽지 않은 결정임을 이해해 주시길 바라며, 지원중단과 관련한 상세 내용은 아래와 같습니다.\n유블루밍은 앞으로 더 좋은 서비스로 보답할 수 있도록 최선을 다하겠습니다.\n감사합니다.\n\n\n@유블루밍팀 드림",
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  },
  {
    id: 4,
    type: "업데이트",
    title: "버전 2.3.1 업데이트 되었습니다.",
    content:
      "안녕하세요. 유블루밍에서 안내드립니다.\n 보다 안정적이고 원활한 서비스 제공을 위해 Mac 최소 지원 버전 상향 조정을 진행할 예정입니다.\n더 나은 서비스 제공을 위한 쉽지 않은 결정임을 이해해 주시길 바라며, 지원중단과 관련한 상세 내용은 아래와 같습니다.\n유블루밍은 앞으로 더 좋은 서비스로 보답할 수 있도록 최선을 다하겠습니다.\n감사합니다.\n\n\n@유블루밍팀 드림",
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  },
  {
    id: 3,
    type: "업데이트",
    title: "버전 2.3.1 업데이트 되었습니다.",
    content:
      "안녕하세요. 유블루밍에서 안내드립니다.\n 보다 안정적이고 원활한 서비스 제공을 위해 Mac 최소 지원 버전 상향 조정을 진행할 예정입니다.\n더 나은 서비스 제공을 위한 쉽지 않은 결정임을 이해해 주시길 바라며, 지원중단과 관련한 상세 내용은 아래와 같습니다.\n유블루밍은 앞으로 더 좋은 서비스로 보답할 수 있도록 최선을 다하겠습니다.\n감사합니다.\n\n\n@유블루밍팀 드림",
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  },
  {
    id: 2,
    type: "기타",
    title: "베타 기간 당첨자 발표2",
    content:
      "안녕하세요. 유블루밍에서 안내드립니다.\n 보다 안정적이고 원활한 서비스 제공을 위해 Mac 최소 지원 버전 상향 조정을 진행할 예정입니다.\n더 나은 서비스 제공을 위한 쉽지 않은 결정임을 이해해 주시길 바라며, 지원중단과 관련한 상세 내용은 아래와 같습니다.\n유블루밍은 앞으로 더 좋은 서비스로 보답할 수 있도록 최선을 다하겠습니다.\n감사합니다.\n\n\n@유블루밍팀 드림",
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  },
  {
    id: 1,
    type: "기타",
    title: "베타 기간 당첨자 발표",
    content:
      "안녕하세요. 유블루밍에서 안내드립니다.\n 보다 안정적이고 원활한 서비스 제공을 위해 Mac 최소 지원 버전 상향 조정을 진행할 예정입니다.\n더 나은 서비스 제공을 위한 쉽지 않은 결정임을 이해해 주시길 바라며, 지원중단과 관련한 상세 내용은 아래와 같습니다.\n유블루밍은 앞으로 더 좋은 서비스로 보답할 수 있도록 최선을 다하겠습니다.\n감사합니다.\n\n\n@유블루밍팀 드림",
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  }
];
