import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";
import { createDynamicPath } from "src/utils/dataConverter";

class CodeService {
  /*
   * 공통코드 목록 조회
   * groupCode 그룹 코드
   * useYn 사용 여부
   * searchText 검색 텍스트 조건
   */
  codeList(params) {
    // Creating path
    const basePath = "/code";
    const dynamicPath = createDynamicPath(basePath, params);

    return axiosGet(dynamicPath).then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.commonCodeId
        };
      });
    });
  }

  /*
   * 공통코드 1건 신규 추가
   * payload
   * String groupCode 그룹 코드
   * String codeId 코드 ID
   * String codeValue 코드 값
   * String codeDesc 코드 설명
   * Number codeSort 코드 정렬
   * String useYn 사용 여부 Y/N
   */
  insertCode(payload) {
    return axiosPost("/code", payload).then((response) => {
      return response;
    });
  }

  /*
   * 공통코드 1건 수정
   * payload
   * commonCodeId 공통코드 ID
   * groupCode 그룹 코드
   * groupName 그룹 명
   * codeId 코드 ID
   * codeValue 코드 값
   * codeDesc 코드 설명
   * codeSort 코드 정렬
   * useYn 사용 여부 Y/N
   */
  updateCode(payload) {
    return axiosPut("/code", payload).then((response) => {
      return response;
    });
  }

  /*
   * 공통코드 1건 삭제
   * commonCodeId 공통코드 id
   */
  deleteCode(commonCodeId) {
    return axiosDelete(`/code/${commonCodeId}`).then((response) => {
      return response;
    });
  }
}

export default new CodeService();
