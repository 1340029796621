export default [
  {
    id: 6,
    type: "시즌",
    title: "추석 이벤트",
    creater: "U블루밍",
    content:
      "추석 연휴 2023.09.24~2023.10.03일 동안 이벤트를 진행합니다. 연휴 후  최대한 빠르게 응답드리겠습니다.\n행복한 추석 되세요.\n\n\n@유블루밍팀 드림",
    mainImg: "event01.jpg",
    bannerImg: "",
    startedAt: 1555016400000,
    endedAt: 1555016400000,
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  },
  {
    id: 5,
    type: "베타",
    title: "베타 이벤트",
    content:
      "이벤트를 진행합니다. 연휴 후  최대한 빠르게 응답드리겠습니다.\n행복한 추석 되세요.\n\n\n@유블루밍팀 드림",
    mainImg: "",
    bannerImg: "",
    startedAt: 1555016400000,
    endedAt: 1555016400000,
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  },
  {
    id: 4,
    type: "시즌",
    title: "발렌타인 이벤트",
    content:
      "이벤트를 진행합니다. 연휴 후  최대한 빠르게 응답드리겠습니다.\n행복한 추석 되세요.\n\n\n@유블루밍팀 드림",
    mainImg: "",
    bannerImg: "",
    startedAt: 1555016400000,
    endedAt: 1555016400000,
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  },
  {
    id: 3,
    type: "시즌",
    title: "U블루밍 런칭 100일 이벤트",
    content:
      "이벤트를 진행합니다. 연휴 후  최대한 빠르게 응답드리겠습니다.\n행복한 추석 되세요.\n\n\n@유블루밍팀 드림",
    mainImg: "",
    bannerImg: "",
    startedAt: 1555016400000,
    endedAt: 1555016400000,
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  },
  {
    id: 2,
    type: "베타",
    title: "베타 이벤트",
    content:
      "이벤트를 진행합니다. 연휴 후  최대한 빠르게 응답드리겠습니다.\n행복한 추석 되세요.\n\n\n@유블루밍팀 드림",
    mainImg: "",
    bannerImg: "",
    startedAt: 1555016400000,
    endedAt: 1555016400000,
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  },
  {
    id: 1,
    type: "베타",
    title: "베타 기간 당첨자 발표",
    content:
      "이벤트를 진행합니다. 연휴 후  최대한 빠르게 응답드리겠습니다.\n행복한 추석 되세요.\n\n\n@유블루밍팀 드림",
    mainImg: "",
    bannerImg: "",
    startedAt: 1555016400000,
    endedAt: 1555016400000,
    creater: "U블루밍",
    createdAt: 1555016400000,
    mainYn: "N",
    useYn: "Y"
  }
];
