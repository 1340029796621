// ** React Imports
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

// ** Context Imports
import { AuthContext } from "src/context";

const PrivateRoute = (props) => {
  const { layout: Layout } = props;
  const { loggedIn } = useContext(AuthContext);
  return loggedIn ? <Layout /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
