import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";

class StoreService {
  /*
   * 상점 목록 조회
   */
  selectStoreItemList() {
    return axiosGet("store-info").then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.storeInfoId
        };
      });
    });
  }

  /*
   * 상품 1건 신규 추가
   * productName 상품 명
   * productPrice 상품 가격
   * productDesc 상품 설명
   * productBcnt 상품 보너스
   * useYn 사용 유무
   */
  insertStoreItem(payload) {
    return axiosPost("store-info", payload).then((response) => {
      return response;
    });
  }

  /*
   * 상품 1건 수정
   * storeInfoId 상점 ID
   * productId 상품 ID
   * productName 상품 명
   * productPrice 상품 가격
   * productDesc 상품 설명
   * productBcnt 상품 보너스
   * useYn 사용 유무
   */
  updateStoreItem(payload) {
    return axiosPut("store-info", payload).then((response) => {
      return response;
    });
  }

  /*
   * 상품 1건 삭제
   * storeInfoId 상점 ID
   */
  deleteStoreItem(storeInfoId) {
    return axiosDelete(`store-info/${storeInfoId}`).then((response) => {
      return response;
    });
  }
}

export default new StoreService();
