import CodeService from "src/services/CodeService";
import { API_RESULT } from "src/constant/commonCode";

class CodeController {
  /*
   * 공통코드 목록 조회
   * groupCode 그룹 코드
   * useYn 사용 여부
   * searchText 검색 텍스트 조건
   */
  getCodeList(params) {
    const result = CodeService.codeList(params);
    return result;
  }

  /*
   * 공통코드 1건 신규 추가
   * String groupCode 그룹 코드
   * String codeId 코드 ID
   * String codeValue 코드 값
   * String codeDesc 코드 설명
   * Number codeSort 코드 정렬
   * String useYn 사용 여부 Y/N
   */
  addCode(params) {
    const payload = {
      groupCode: params.groupCode,
      codeId: params.codeId,
      codeValue: params.codeValue,
      codeDesc: params.codeDesc,
      codeSort: params.codeSort ? Number(params.codeSort) : null,
      useYn: params.useYn
    };

    return new Promise((resolve, reject) => {
      CodeService.insertCode(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 공통코드 1건 수정
   * commonCodeId 공통코드 ID
   * groupCode 그룹 코드
   * codeId 코드 ID
   * codeValue 코드 값
   * codeDesc 코드 설명
   * codeSort 코드 정렬
   * useYn 사용 여부 Y/N
   */
  editCode(params) {
    const payload = {
      commonCodeId: params.commonCodeId,
      groupCode: params.groupCode,
      codeId: params.codeId,
      codeValue: params.codeValue,
      codeDesc: params.codeDesc,
      codeSort: params.codeSort,
      useYn: params.useYn
    };

    return new Promise((resolve, reject) => {
      CodeService.updateCode(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 공통코드 1건 삭제
   * commonCodeId 공통코드 ID
   */
  deleteCode(commonCodeId) {
    return new Promise((resolve, reject) => {
      CodeService.deleteCode(commonCodeId).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }
}

export default new CodeController();
