import React from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";

const packageOptions = [
  { amount: 1000, bonus: 260, price: 168500 },
  { amount: 500, bonus: 90, price: 88500 },
  { amount: 300, bonus: 45, price: 47500 },
  { amount: 150, bonus: 30, price: 25500 },
  { amount: 70, bonus: 15, price: 16500 },
  { amount: 30, bonus: null, price: 7500 }
];

const renderBonusBadge = ({ title, subTitle }) => {
  return (
    <>
      <Typography
        sx={{ mr: 1 }}
        component="span"
        variant="body1"
        color="text.primary"
      >
        {title}
      </Typography>
      {subTitle && <Chip size="small" label={`+${subTitle}`} />}
    </>
  );
};

const BadgeList = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="인증" back />
      <Stack spacing={2}>
        <Typography variant="h6">인증을 해주세요</Typography>
        <Stack direction="row" spacing={1}>
          <Button fullWidth variant="outlined">
            본인 인증
          </Button>
          <Button fullWidth variant="outlined" href="/app/email">
            직장/학교 인증
          </Button>
        </Stack>
        <List
          dense
          sx={{
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": {
              display: "list-item"
            }
          }}
        >
          <ListItem disablePadding>
            <ListItemText primary="각 인증 뱃지를 클릭시 인증을 진행 할 수 있습니다" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary="타인의 정보를 무단으로 사용할 경우 관련 법령에 따라 처벌받을 수 있습니다." />
          </ListItem>
        </List>
      </Stack>
    </Paper>
  );
};

export default BadgeList;
