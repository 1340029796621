// ** React Imports
import React, { useState } from "react";

// ** Store Imports
import { useQuery, useMutation, useQueryClient } from "react-query";
import MatchManageController from "src/controller/MatchManageController";
import { API_RESULT, API_RESULT_ACTION } from "src/constant/commonCode";

// ** Hook Imports
import { useToast } from "src/hooks";

// ** Widget Imports
import { Box, Button } from "@mui/material";
import { GridContainer } from "src/components";
import { TitledPaper } from "src/widgets";
import MatchInfoDialog from "./MatchInfoDialog";
import UserAgeSetList from "./UserAgeSetList";

function MatchInfoList() {
  // ** Hook
  const toast = useToast();
  const queryClient = useQueryClient();

  // ** State
  // 코드 상세 Form Dialog 오픈 여부
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  // Selected Row to delete with API when delete button clicked
  const [selectedRow, setSelectedRow] = useState(null);

  // ** Fetch
  // 아이템 목록 조회
  const {
    isLoading,
    refetch,
    data: rowData
  } = useQuery("matchConditions", MatchManageController.getMatchConditionList);

  // 신규 아이템 추가
  const { mutate: AddItem } = useMutation({
    mutationFn: MatchManageController.addMatchConditionItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.ADD);
      queryClient.invalidateQueries({ queryKey: "matchConditions" });
      setFormDialogOpen(false);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 수정
  const { mutate: EditItem } = useMutation({
    mutationFn: MatchManageController.editMatchConditionItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.EDIT);
      queryClient.invalidateQueries({ queryKey: "matchConditions" });
      setFormDialogOpen(false);
      setSelectedRow(null);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 1개 아이템 삭제
  const { mutate: DeleteItem } = useMutation({
    mutationFn: MatchManageController.deleteMatchConditionItem,
    onSuccess: (data) => {
      data === API_RESULT.SUCCESS && toast.success(API_RESULT_ACTION.DELETE);
      queryClient.invalidateQueries({ queryKey: "matchConditions" });
      setFormDialogOpen(false);
      setSelectedRow(null);
    },
    onError: (error) => {
      toast.error(API_RESULT_ACTION.FAILURE);
      console.log(error);
    }
  });

  // 신규 아이템 Dialog Open
  const handleClickCodeFormDialogHandler = () => {
    setFormDialogOpen(!formDialogOpen);
  };

  // 신규 아이템 추가 버튼 클릭
  const handleClickNew = () => {
    // 선택된 로우 비우기
    setSelectedRow(null);
    // Dialog 열기
    handleClickCodeFormDialogHandler();
  };

  // 아이템 수정 dialog open
  const handleClickEdit = (row) => {
    // 선택된 로우 저장
    setSelectedRow(row);
    // Dialog 열기
    handleClickCodeFormDialogHandler();
  };

  // 상세 dialog에서 작업 후 저장
  const handleClickSave = (newFormData) => {
    if (newFormData?.avatarInfoId) {
      // 수정
      EditItem(newFormData);
    } else {
      // 신규
      AddItem(newFormData);
    }
  };

  // 아이템 삭제
  const handleClickDelete = (id) => {
    DeleteItem({
      matchConditionId: id
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      sortable: true,
      width: 80
    },
    {
      field: "conditionCd",
      headerName: "조건문",
      sortable: true,
      width: 80
    },
    { field: "conditionText", headerName: "조건", sortable: true, width: 250 },
    {
      field: "useYn",
      headerName: "사용 여부",
      sortable: true,
      width: 80
    },
    {
      field: "mustYn",
      headerName: "필수 여부",
      sortable: true,
      width: 80
    },
    {
      field: "matchGb",
      headerName: "구분(P/L)",
      sortable: true,
      width: 80
    },
    {
      field: "matchOrder",
      type: "number",
      headerName: "비교 순서",
      sortable: true,
      width: 80
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      width: 160,
      getActions: (params) => {
        return [
          <Button
            key="action-confirm"
            variant="contained"
            onClick={() => handleClickEdit(params.row)}
          >
            수정
          </Button>,
          <Button
            key="action-confirm"
            variant="outlined"
            color="error"
            onClick={() => handleClickDelete(params.row.id)}
          >
            삭제
          </Button>
        ];
      }
    }
  ];

  return (
    <>
      <Box display="flex" justifyContent="end" sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px"
          }}
        >
          <Button color="primary" variant="outlined" onClick={handleClickNew}>
            추가
          </Button>
        </Box>
      </Box>
      <span>매칭 조건</span>
      <Box sx={{ pt: 1 }}>
        <GridContainer height={300} columns={columns} rowData={rowData || []} />
      </Box>
      <MatchInfoDialog
        open={formDialogOpen}
        data={selectedRow}
        onClose={handleClickCodeFormDialogHandler}
        onConfirm={handleClickSave}
      />
    </>
  );
}

export default function MatchManageView() {
  return (
    <TitledPaper title="매칭 정보 관리">
      {/* 매칭 조건 관리 */}
      <Box sx={{ pt: 1 }}>
        <MatchInfoList />
      </Box>

      {/* 매칭 추천 시 나이 옵션 관리 */}
      <Box sx={{ pt: 1 }}>
        <UserAgeSetList />
      </Box>
    </TitledPaper>
  );
}
