import MessageManageService from "src/services/MessageManageService";
import { API_RESULT } from "src/constant/commonCode";

class MessageManageController {
  /*
   * 메시지 목록 조회
   * String messageType 메시지 타입
   */
  getMessageList(prarams) {
    const result = MessageManageService.selectMessageList();
    return result;
  }

  /*
   * 메시지 1건 신규 추가
   * String messageType 메시지 타입
   * String messageTitle 메시지 내용
   */
  addMessage(params) {
    const payload = {
      messageType: params.messageType,
      messageTitle: params.messageTitle
    };

    return new Promise((resolve, reject) => {
      MessageManageService.insertMessage(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 메시지 1건 수정
   * messageManageId 메시지 ID
   * String messageType 메시지 타입
   * String messageTitle 메시지 내용
   */
  editMessage(params) {
    const payload = {
      messageManageId: params.messageManageId,
      messageType: params.messageType,
      messageTitle: params.messageTitle
    };

    return new Promise((resolve, reject) => {
      MessageManageService.updateMessage(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 메시지 1건 삭제
   * messageManageId 메시지 id
   */
  deleteMessage(messageManageId) {
    return new Promise((resolve, reject) => {
      MessageManageService.deleteMessage(messageManageId).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }
}

export default new MessageManageController();
