import React from "react";
import { Paper, Stack } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const Terms = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="Terms" back />
      <Stack>Body</Stack>
    </Paper>
  );
};

export default Terms;
