import React from "react";
import { Paper, Stack } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const ChatRoom = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="대전강아지" back chat />
      <Stack spacing={2}>Body</Stack>
    </Paper>
  );
};

export default ChatRoom;
