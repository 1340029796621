import { createSlice } from "@reduxjs/toolkit";

const toastSlice = createSlice({
  name: "toast",
  initialState: {
    toast: { type: null, message: null }
  },
  reducers: {
    SHOW_TOAST(state, { payload }) {
      state.toast = payload;
    },
    CLEAR_TOAST(state) {
      state.toast = { type: null, message: null };
    }
  }
});

export const { SHOW_TOAST, CLEAR_TOAST } = toastSlice.actions;

export const toastSelector = (state) => state.toast;

export default toastSlice.reducer;
