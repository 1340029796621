import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack
} from "@mui/material";

export default function MatchInfoDialog({ open, data, onClose, onConfirm }) {
  const [formData, setFormData] = useState({
    matchConditionId: "",
    conditionCd: "",
    conditionText: "",
    useYn: "",
    mustYn: "",
    matchGb: "",
    matchOrder: ""
  });
  const {
    matchConditionId,
    conditionCd,
    conditionText,
    useYn,
    mustYn,
    matchGb,
    matchOrder
  } = formData;

  // Input field handler
  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // 수정일 경우 Form Data 업데이트
  const handleSetFormData = (newFormData) => {
    setFormData(newFormData);
  };

  const handleClose = () => {
    // Form Data 초기화
    handleSetFormData({
      matchConditionId: "",
      conditionCd: "",
      conditionText: "",
      useYn: "",
      mustYn: "",
      matchGb: "",
      matchOrder: ""
    });

    // Dialog 닫기
    onClose && onClose();
  };

  // 유입되는 데이터 확인
  useEffect(() => {
    data && handleSetFormData(data);
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          onConfirm(formData);
        }
      }}
    >
      <DialogTitle>매칭조건 상세</DialogTitle>
      <DialogContent>
        {matchConditionId != "" && (
          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            id="matchConditionId"
            name="matchConditionId"
            label="ID"
            value={matchConditionId}
            onChange={handleChangeFormData}
            disabled
          />
        )}
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="conditionCd"
          name="conditionCd"
          label="조건 코드"
          value={conditionCd}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="conditionText"
          name="conditionText"
          label="조건문"
          value={conditionText}
          rows={14}
          multiline
          onChange={handleChangeFormData}
          required
        />
        <Stack>
          <FormControl margin="dense">
            <InputLabel id="useYn-select-label">사용 여부</InputLabel>
            <Select
              labelId="useYn-select-label"
              id="useYn"
              name="useYn"
              label="useYn"
              value={useYn}
              onChange={handleChangeFormData}
            >
              <MenuItem value={"Y"}>Y</MenuItem>
              <MenuItem value={"N"}>N</MenuItem>
            </Select>
          </FormControl>
          <FormControl margin="dense">
            <InputLabel id="mustYn-select-label">필수 여부</InputLabel>
            <Select
              labelId="mustYn-select-label"
              id="mustYn"
              name="mustYn"
              label="mustYn"
              value={mustYn}
              onChange={handleChangeFormData}
            >
              <MenuItem value={"Y"}>Y</MenuItem>
              <MenuItem value={"N"}>N</MenuItem>
            </Select>
          </FormControl>
          <FormControl margin="dense">
            <InputLabel id="matchGb-select-label">구분(P/L)</InputLabel>
            <Select
              labelId="matchGb-select-label"
              id="matchGb"
              name="matchGb"
              label="matchGb"
              value={matchGb}
              onChange={handleChangeFormData}
            >
              <MenuItem value={"P"}>P</MenuItem>
              <MenuItem value={"L"}>L</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          type="number"
          id="matchOrder"
          name="matchOrder"
          label="비교 순서"
          value={matchOrder}
          onChange={handleChangeFormData}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
