import { v4 as uuid } from "uuid";

export default {
  id: uuid(),
  socialType: "구글",
  avatar: "02",
  phone: "010-2222-3333",
  email: "yeyeji@devias.io",
  meetingTypeCode: "02",
  meetingTypeName: "주말커피",
  nickName: "지예2",
  gender: "여",
  birthYear: "1990",
  age: "32",
  height: 167,
  location: "서울",
  job: "마케터",
  company: "구글",
  degree: "4년제",
  school: "연세대학교",
  bloodType: "A",
  mbti: "ENTP",
  religion: "무교",
  smoking: "안해요",
  drinking: "안해요",
  bio: "천천히 알아가실분 있으실까요? 커피 한잔 해요",
  characteristic: ["맑은 눈"],
  mysellf: ["차분한", "밝은"],
  hobby: ["산책", "독서", "반려 식물"],
  coinCnt: 50,
  userStatusCode: "01",
  userStatusName: "정상",
  idVerification: 1555016400000,
  companyVerification: 1555016400000,
  schoolVerification: null,
  lastLoginedAt: 1555016400000,
  createdAt: 1555016400000,
  updatedAt: 1555016400000
};
