import React, { useState } from "react";
import { Paper, Stack } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import { ProfileInfoCard } from "src/components/widgets/Card";
import {
  BasicInfo,
  ProfileAction,
  SelfIntro,
  TagGroup
} from "src/components/widgets/Profile";
import customers from "src/__mocks__/customers";

const user = customers[0];

const Profile = (props) => {
  const { myProfile } = props;
  const [edit, setEdit] = useState(false);

  const handleSetEdit = () => {
    setEdit(!edit);
  };

  return (
    <Paper elevation={0}>
      <Appbar
        title="Profile"
        back
        myProfile={myProfile}
        edit={edit}
        onEdit={handleSetEdit}
      />
      <Stack spacing={2}>
        <ProfileInfoCard user={user} />
        {!myProfile && <ProfileAction />}
        <BasicInfo user={user} edit={edit} />
        {user?.bio && <SelfIntro contents={user.bio} edit={edit} />}
        {user.characteristic.length > 0 && (
          <TagGroup
            edit={edit}
            title="이런 특징이 있어요"
            list={user.characteristic}
          />
        )}
        {user.mysellf.length > 0 && (
          <TagGroup
            edit={edit}
            title="저는 이런 사람 이에요"
            list={user.mysellf}
          />
        )}
        {user.hobby.length > 0 && (
          <TagGroup
            edit={edit}
            title="여가 시간에는 이렇게 보내요"
            list={user.hobby}
          />
        )}
      </Stack>
    </Paper>
  );
};

export default Profile;
