import React from "react";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const StepLocation = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="프로필 생성" back />
      <Stack height="92vh" justifyContent="space-between">
        <Stack>
          <Typography variant="h6">지역을 선택해주세요</Typography>
          <TextField
            fullWidth
            variant="standard"
            label="지역"
            placeholder="서울"
          />
        </Stack>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          href="/app/join/location"
        >
          완료
        </Button>
      </Stack>
    </Paper>
  );
};

export default StepLocation;
