import React from "react";
import { Button, Link, Paper, Stack, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";

const StepMettingType = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="프로필 생성" back />
      <Stack spacing={2}>
        <Typography variant="h6">
          처음 오셨군요, 반가워요! 선호 하시는 만남 상태를 선택 해주세요.
        </Typography>
        <Button
          fullWidth
          component={Link}
          variant="outlined"
          href="/app/join/nick-name"
        >
          평일 커피 한잔
        </Button>
        <Button
          fullWidth
          component={Link}
          variant="outlined"
          href="/app/join/nick-name"
        >
          주말 커피 한잔
        </Button>
        <Button
          fullWidth
          component={Link}
          variant="outlined"
          href="/app/join/nick-name"
        >
          평일 맥주 한잔
        </Button>
        <Button
          fullWidth
          component={Link}
          variant="outlined"
          href="/app/join/nick-name"
        >
          주말 맥주 한잔
        </Button>
      </Stack>
    </Paper>
  );
};

export default StepMettingType;
