import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
} from "src/api/interceptors";

class MbtiInfoService {
  /*
   * MBTI 매칭 정보 목록 조회
   */
  mbtiInfoList() {
    return axiosGet("/mbti-info").then((response) => {
      return response.map((item) => {
        return {
          ...item,
          id: item.mbtiInfoId
        };
      });
    });
  }

  /*
   * MBTI 매칭 정보 1건 신규 추가
   * mbtiType 기준 MBTI
   * mbtiTypeMatch 비교 MBTI
   * matchRate MBTI 매칭률
   */
  insertMbtiInfo(payload) {
    return axiosPost("/mbti-info", payload).then((response) => {
      return response;
    });
  }

  /*
   * MBTI 매칭 정보 1건 수정
   * mbtiInfoId MBTI 매칭 정보 ID
   * mbtiType 기준 MBTI
   * mbtiTypeMatch 비교 MBTI
   * matchRate MBTI 매칭률
   */
  updateMbtiInfo(payload) {
    return axiosPut("/mbti-info", payload).then((response) => {
      return response;
    });
  }

  /*
   * MBTI 매칭 정보 1건 삭제
   * mbtiInfoId MBTI 매칭 정보 ID
   */
  deleteMbtiInfo(mbtiInfoId) {
    return axiosDelete(`/mbti-info/${mbtiInfoId}`).then((response) => {
      return response;
    });
  }
}

export default new MbtiInfoService();
