import React from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Switch
} from "@mui/material";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import Appbar from "src/components/widgets/Appbar";

const MySetting = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="Setting" back />
      <Stack spacing={2}>
        <List>
          <ListItem disablePadding>
            <ListItemButton href="/app/dormancy-guide">
              <ListItemText primary="계정 관리" />
            </ListItemButton>
            <IconButton href="/app/cancel-guide">
              <ChevronRightIcon />
            </IconButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary="로그인 정보" secondary="카카오" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary="알림" />
            <Switch
              edge="end"
              inputProps={{
                "aria-labelledby": "switch-list-label-company"
              }}
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton href="/app/terms">
              <ListItemText primary="이용 약관" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton href="/app/privacy">
              <ListItemText primary="개인정보 취급 방침" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton href="/app/business-info">
              <ListItemText primary="사업자정보" />
            </ListItemButton>
          </ListItem>
        </List>
      </Stack>
    </Paper>
  );
};

export default MySetting;
