import React from "react";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Slider,
  Stack,
  Switch,
  Typography
} from "@mui/material";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import Appbar from "src/components/widgets/Appbar";

const minDistanceAge = 5;
const minDistanceHeight = 10;

const IdealTypeSetting = () => {
  // Age
  const [valueAge, setValueAge] = React.useState([30, 37]);
  const handleChangeAge = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValueAge([
        Math.min(newValue[0], valueAge[1] - minDistanceAge),
        valueAge[1]
      ]);
    } else {
      setValueAge([
        valueAge[0],
        Math.max(newValue[1], valueAge[0] + minDistanceAge)
      ]);
    }
  };

  // Height
  const [valueHeight, setValueHeight] = React.useState([160, 170]);
  const handleChangeHeight = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValueHeight([
        Math.min(newValue[0], valueHeight[1] - minDistanceHeight),
        valueHeight[1]
      ]);
    } else {
      setValueHeight([
        valueHeight[0],
        Math.max(newValue[1], valueHeight[0] + minDistanceHeight)
      ]);
    }
  };

  return (
    <Paper elevation={0}>
      <Appbar title="나의 이상형" back />
      <Stack height="92vh" justifyContent="space-between">
        <Stack spacing={2}>
          <Box p={2} bgcolor="lightGray">
            <Typography variant="caption">
              라운지에서 받는 카드가 이상형에 가까운 회원님으로 소개되며,
              소개이성이 부족할 경우 나이와 지역 범위가 넓어집니다.
            </Typography>
          </Box>
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="상태" secondary="평일맥주" />
              </ListItemButton>
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="지역" secondary="서울" />
              </ListItemButton>
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </ListItem>
            <ListItem disablePadding>
              <Stack width="100%" p={2}>
                <ListItemText primary="나이" secondary="30세 ~ 35세" />
                <Slider
                  getAriaLabel={() => "Age range"}
                  value={valueAge}
                  onChange={handleChangeAge}
                  min={20}
                  max={45}
                  valueLabelDisplay="auto"
                />
              </Stack>
            </ListItem>
            <ListItem disablePadding>
              <Stack width="100%" p={2}>
                <ListItemText primary="키" secondary="175cm ~ 195cm" />
                <Slider
                  getAriaLabel={() => "Height range"}
                  value={valueHeight}
                  onChange={handleChangeHeight}
                  min={140}
                  max={220}
                  step={5}
                  valueLabelDisplay="auto"
                  disableSwap
                />
              </Stack>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="종교" secondary="천주교" />
              </ListItemButton>
              <IconButton>
                <ChevronRightIcon />
              </IconButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary="같은 직장 제외" />
              <Switch
                edge="end"
                inputProps={{
                  "aria-labelledby": "switch-list-label-company"
                }}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary="같은 학교 제외" />
              <Switch
                edge="end"
                inputProps={{
                  "aria-labelledby": "switch-list-label-school"
                }}
              />
            </ListItem>
          </List>
        </Stack>
        <Button size="large" variant="outlined">
          저장
        </Button>
      </Stack>
    </Paper>
  );
};

export default IdealTypeSetting;
