import React, { useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import BttmNavbar from "src/components/widgets/BttmNavbar";
import { ChatInfoCard } from "src/components/widgets/Card";
import InfoSection from "src/components/widgets/InfoSection";
import { CHAT_STATUS } from "src/constant/commonCode";
import chatData from "src/__mocks__/chatLoabieData";
import myData from "src/__mocks__/myData";

const ChatRoabie = () => {
  const [date, setDate] = useState(chatData.status);
  return (
    <Paper elevation={0}>
      <Appbar tab my />
      {date ? (
        <Stack height="82vh" justifyContent="space-between">
          <ChatInfoCard
            day={date}
            meetingType={chatData.partner.meetingTypeCode}
            nickName={chatData.partner.nickName}
          />
          {date !== CHAT_STATUS.CLOSE && (
            <Stack alignItems="center">
              <Typography variant="subtitle1">매칭 된지</Typography>
              <Typography variant="h5">{date}일</Typography>
            </Stack>
          )}
          <Stack spacing={2} alignItems="center">
            <Stack direction="row" spacing={3}>
              <IconButton href={`/app/profile/${chatData.partner.id}`}>
                <Avatar
                  src={`/static/user/${
                    chatData.partner.gender == "남" ? "male" : "female"
                  }_${chatData.partner.avatar}.png`}
                  sx={{
                    height: 80,
                    mb: 2,
                    width: 80
                  }}
                />
              </IconButton>
              <IconButton href={`/app/profile/${myData.id}`}>
                <Avatar
                  src={`/static/user/${
                    myData.gender == "남" ? "male" : "female"
                  }_${myData.avatar}.png`}
                  sx={{
                    height: 80,
                    mb: 2,
                    width: 80
                  }}
                />
              </IconButton>
            </Stack>
            <Stack>
              <Button fullWidth variant="outlined" href="/app/chat/room/1">
                대화방 입장하기
                <Chip
                  size="small"
                  color="primary"
                  sx={{ color: "white", ml: 1 }}
                  label="N"
                />
              </Button>
              <Button size="small">나가기</Button>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <InfoSection>
          <Typography variant="subtitle1">
            아직 대화중인 이성이 없습니다
          </Typography>
          <Typography variant="body1">
            매력적인 이성을 찾지 못하셨나요? 라운지를 다시 한번 둘러보러
            가볼까요!
          </Typography>
          <Button variant="outlined">라운지 바로가기</Button>
        </InfoSection>
      )}
      <BttmNavbar />
    </Paper>
  );
};

export default ChatRoabie;
