import React from "react";
import { List, ListItem, ListItemText, Paper, Stack } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import moment from "moment";
import rowData from "src/__mocks__/userNotificationList";

const NotificationList = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="알림" back />
      <Stack ml={2} mr={2}>
        <List>
          {rowData.map((item, idx) => (
            <ListItem key={idx} disablePadding>
              <ListItemText
                primary={item.description}
                secondary={moment(item.createdAt).format("YYYY/MM/DD hh:mm:ss")}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
    </Paper>
  );
};

export default NotificationList;
