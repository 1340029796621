import { v4 as uuid } from "uuid";

export default [
  {
    id: 1,
    useid: uuid(),
    description: "결제 문의 왔었음",
    createdAt: 1555016400000
  },
  {
    id: 2,
    useid: uuid(),
    description: "채팅 관련 문의 왔었음",
    createdAt: 1555016400000
  },
  {
    id: 3,
    useid: uuid(),
    description: "채팅 관련 환불 문의 왔었음",
    createdAt: 1555016400000
  }
];
