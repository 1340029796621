import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography
} from "@mui/material";

const ChatWaitingCard = (props) => {
  const { user } = props;
  const { userData, content } = user;
  const { avatar, meetingTypeName, gender, nickName, company } = userData;

  return (
    <Card
      variant="outlined"
      sx={{
        m: 2,
        border: "none",
        borderBottom: "1px solid black",
        borderRadius: 0
      }}
    >
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Avatar
            src={`/static/user/${
              gender == "남" ? "male" : "female"
            }_${avatar}.png`}
            sx={{
              height: 80,
              mb: 2,
              width: 80
            }}
          />
          <Stack>
            <Stack direction="row" alignItems="center">
              <Chip color="secondary" label="22시간 남음" />
              <Chip color="primary" label={meetingTypeName} sx={{ ml: 1 }} />
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle1">{nickName}</Typography>/
              <Typography variant="subtitle1">{company}</Typography>
            </Stack>
            <Typography variant="body1">{content}</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ChatWaitingCard;
