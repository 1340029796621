// ** React Imports
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

// ** Widget Imports
import { Box, Card, CardContent, TextField, Grid, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { VisuallyHiddenInputButton } from "../widgets/Button";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";

export const EventForm = ({
  // ** Props
  values,
  onChange,
  onChangeMainImage,
  onChangeBannerImage,
  onChangeViewDate,
  onChangeStartDate,
  onChangeEndDate
}) => {
  const {
    categorySubCd,
    notiTitle,
    notiContent,
    imageName01,
    imageName02,
    imageName03,
    viewDate,
    startDate,
    endDate,
    useYn
  } = values;

  // ** State
  const [previewMainImg, setPreviewMainImg] = useState(
    "https://placehold.co/200x200"
  );
  const [previewBannerImg, setPreviewBannerImg] = useState(
    "https://placehold.co/200x80"
  );

  // Input field handler
  const handleChange = (event) => {
    onChange && onChange(event);
  };

  // 메인 사진 업로드
  const handleChangeMainImages = ({ target }) => {
    const newFile = target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(newFile);
    reader.onload = () => {
      setPreviewMainImg(reader.result);
    };

    onChangeMainImage && onChangeMainImage(newFile);
  };

  // 배너 사진 업로드
  const handleChangeBannerImages = ({ target }) => {
    const newFile = target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(newFile);
    reader.onload = () => {
      setPreviewBannerImg(reader.result);
    };

    onChangeBannerImage && onChangeBannerImage(newFile);
  };

  // 노출일자
  const handleChangeViewDate = (newValue) => {
    onChangeViewDate && onChangeViewDate(newValue);
  };

  // 시작일자
  const handleChangeStartDate = (newValue) => {
    onChangeStartDate && onChangeStartDate(newValue);
  };

  // 종료일자
  const handleChangeEndDate = (newValue) => {
    onChangeEndDate && onChangeEndDate(newValue);
  };

  // 메인 이미지가 있는 경우 노출
  useEffect(() => {
    if (imageName01 && Object.keys(imageName01).length > 0) {
      setPreviewMainImg(`https://api.ublooming.co.kr/images/${imageName01}`);
    }
  }, [imageName01]);

  // 배너 이미지가 있는 경우 노출
  useEffect(() => {
    if (imageName02 && Object.keys(imageName02).length > 0) {
      setPreviewBannerImg(`https://api.ublooming.co.kr/images/${imageName02}`);
    }
  }, [imageName02]);

  return (
    <Card>
      <CardContent sx={{ pt: 0 }}>
        <Box sx={{ m: 1.5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                SelectProps={{ native: true }}
                label="카테고리"
                name="categorySubCd"
                value={categorySubCd}
                onChange={handleChange}
                required
              >
                <option value="10">베타</option>
                <option value="20">시즌</option>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="제목"
                name="notiTitle"
                value={notiTitle}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ReactQuill
                style={{
                  whiteSpace: "pre-wrap",
                  height: "300px",
                  marginBottom: "33px"
                }}
                theme="snow"
                name="notiContent"
                value={notiContent}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <img
                src={previewMainImg}
                alt="MainImage"
                width={200}
                height={200}
              />
              <div className="img__box"></div>
              <Button
                component="label"
                name="mainImg"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                href="#file-upload"
              >
                메인 이미지 업로드
                <VisuallyHiddenInputButton
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  onChange={handleChangeMainImages}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <img
                src={previewBannerImg}
                alt="BannerImage"
                width={200}
                height={80}
              />
              <Button
                component="label"
                name="bannerImg"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                href="#file-upload"
              >
                배너 이미지 업로드
                <VisuallyHiddenInputButton
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  onChange={handleChangeBannerImages}
                />
              </Button>
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label="노출일자"
                name="viewDate"
                value={viewDate}
                onChange={handleChangeViewDate}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label="시작일자"
                name="startDate"
                value={startDate}
                onChange={handleChangeStartDate}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label="종료일자"
                name="endDate"
                value={endDate}
                onChange={handleChangeEndDate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                SelectProps={{ native: true }}
                label="사용"
                name="useYn"
                value={useYn}
                onChange={handleChange}
                required
              >
                <option value="Y">Y</option>
                <option value="N">N</option>
              </TextField>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
