import AuthService from "src/services/AuthService";
import ManagerController from "./ManagerController";
import { API_RESULT } from "src/constant/commonCode";

class AuthController {
  /*
   * 로그인 토큰 조회
   * adminAccount 매니저 ID
   * adminPw 매니저 PW
   */
  login(params) {
    return new Promise((resolve, reject) => {
      AuthService.getLoginToken(params, true).then((response) => {
        if (response.status != 500) {
          const {
            accessToken: authToken,
            adminAccountId,
            expiresIn
          } = response;
          // 로그인 토큰이 있는 경우
          if (authToken) {
            // sessionStorage 인증 토큰 저장
            sessionStorage.setItem("authToken", authToken);

            // 추후 필요한 경우 사용
            // 유저 정보 조회
            // return ManagerController.getManager(adminAccountId).then((res2) => {
            //   if (res2 != undefined) {
            //     // 유저 데이터가 있는 경우
            //     const userInfo = JSON.stringify(res2);
            //     // sessionStorage 유저 정보 저장
            //     sessionStorage.setItem("userInfo", userInfo);
            //     // 로그인 유저 정보 조회 성공
            //     return "SUCCESS";
            //   } else {
            //     // 유저 데이터가 없는 경우
            //     sessionStorage.clear("authToken");
            //     sessionStorage.clear("userInfo");
            //   }
            //   return "ERROR";
            // });

            return resolve(API_RESULT.SUCCESS);
          }
        } else {
          return reject(response.data.message);
        }
      });
    });
  }

  /*
   * 로그아웃
   */
  logout() {
    sessionStorage.clear();
  }
}

export default new AuthController();
