import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";

export default function CodeFormDialog({ open, data, onClose, onConfirm }) {
  const [formData, setFormData] = useState({
    commonCodeId: "",
    groupCode: "",
    codeId: "",
    codeValue: "",
    codeDesc: "",
    codeSort: "",
    useYn: ""
  });
  const {
    commonCodeId,
    groupCode,
    codeId,
    codeValue,
    codeDesc,
    codeSort,
    useYn
  } = formData;

  // Input field handler
  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // 수정일 경우 Form Data 업데이트
  const handleSetFormData = (newFormData) => {
    setFormData(newFormData);
  };

  const handleClose = () => {
    // Form Data 초기화
    handleSetFormData({
      commonCodeId: "",
      groupCode: "",
      codeId: "",
      codeValue: "",
      codeDesc: "",
      codeSort: "",
      useYn: ""
    });

    // Dialog 닫기
    onClose && onClose();
  };

  // 유입되는 데이터 확인
  useEffect(() => {
    data && handleSetFormData(data);
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          onConfirm(formData);
        }
      }}
    >
      <DialogTitle>공통코드 상세</DialogTitle>
      <DialogContent>
        {commonCodeId != "" && (
          <TextField
            fullWidth
            margin="dense"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            id="commonCodeId"
            name="commonCodeId"
            label="ID"
            value={commonCodeId}
            onChange={handleChangeFormData}
            disabled
          />
        )}
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="groupCode"
          name="groupCode"
          label="그룹 코드"
          value={groupCode}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="codeId"
          name="codeId"
          label="코드 ID"
          value={codeId}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="codeValue"
          name="codeValue"
          label="코드 값"
          value={codeValue}
          onChange={handleChangeFormData}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          id="codeDesc"
          name="codeDesc"
          label="코드 설명"
          value={codeDesc}
          onChange={handleChangeFormData}
        />
        <TextField
          fullWidth
          margin="dense"
          variant="standard"
          type="number"
          InputLabelProps={{ shrink: true }}
          id="codeSort"
          name="codeSort"
          label="코드 정렬"
          placeholder="Number only"
          value={codeSort}
          onChange={handleChangeFormData}
        />
        <FormControl margin="dense">
          <InputLabel id="use-select-label">사용 여부</InputLabel>
          <Select
            labelId="use-select-label"
            id="useYn"
            name="useYn"
            label="useYn"
            value={useYn}
            onChange={handleChangeFormData}
          >
            <MenuItem value={"Y"}>Y</MenuItem>
            <MenuItem value={"N"}>N</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
