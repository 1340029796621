import React from "react";
import { Button, Paper, Stack, Typography } from "@mui/material";
import Appbar from "src/components/widgets/Appbar";
import InfoSection from "src/components/widgets/InfoSection";

const GracePeriod = () => {
  return (
    <Paper elevation={0}>
      <Appbar title="계정 삭제 대기" />
      <InfoSection>
        <Typography variant="subtitle1">계정 삭제 대기 중입니다.</Typography>
        <Stack alignItems="center">
          <Typography variant="body2">계정 삭제 유예는 7일 입니다.</Typography>
          <Typography variant="body2">
            <b>(삭제 예정 날짜: 2023-08-29- 01:00:00)</b>
          </Typography>
          <Typography variant="body2">
            삭제 신청을 취소하시면 서비스를 다시 이용할 수 있습니다. 삭제 후 재
            가입시 프로필 등록, 사진 등록, 인증 등의 번거로운 과정을 다시
            진행해야만 합니다. 차후 이용 가능성이 있으신 경우, 휴면 진행하면
            편리합니다.
          </Typography>
        </Stack>
        <Button variant="outlined">계정 삭제 취소 하기</Button>
      </InfoSection>
    </Paper>
  );
};

export default GracePeriod;
