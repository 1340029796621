import AvatarService from "src/services/AvatarService";
import { API_RESULT } from "src/constant/commonCode";

class AvatarController {
  /*
   * 아바타 목록 조회
   * avatarGender 아바타 성별(M, F)
   */
  getAvatarList(prarams) {
    const { avatarGender } = prarams;
    const result = AvatarService.avatarList(avatarGender);
    return result;
  }

  /*
   * 아바타 1건 신규 추가
   * avatarId 아바타 코드
   * avatarName 아바타 파일 명
   * avatarGender 아바타 성별(M, F)
   */
  addAvatar(params) {
    const payload = {
      avatarId: params.avatarId,
      avatarName: params.avatarName,
      avatarGender: params.avatarGender
    };

    return new Promise((resolve, reject) => {
      AvatarService.insertAvatar(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 아바타 1건 수정
   * avatarInfoId 아바타 ID
   * avatarId 아바타 코드
   * avatarName 아바타 파일 명
   * avatarGender 아바타 성별(M, F)
   */
  editAvatar(params) {
    const payload = {
      avatarInfoId: params.avatarInfoId,
      avatarId: params.avatarId,
      avatarName: params.avatarName,
      avatarGender: params.avatarGender
    };

    return new Promise((resolve, reject) => {
      AvatarService.updateAvatar(payload, false).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }

  /*
   * 아바타 1건 삭제
   * avatarInfoId 아바타 ID
   */
  deleteAvatar(avatarInfoId) {
    return new Promise((resolve, reject) => {
      AvatarService.deleteAvatar(avatarInfoId).then((response) => {
        if (response.affectedRows > 0) {
          return resolve(API_RESULT.SUCCESS);
        } else {
          return reject(API_RESULT.ERROR);
        }
      });
    });
  }
}

export default new AvatarController();
